// import axios from 'axios';
import React, {useEffect, useState} from 'react'
import Applications from "../../Datas/Applications.json"
import RippleButton from '../../../../components/Buttons/RippleButton'
import next from '../../../assets/icons/back-svgrepo-com.svg'
import userPic from "../../../assets/users/bonnie-green-2x.png";
// import { FaEllipsisH } from 'react-icons/fa'
import HomeCard from "../../components/HomeCard"
import Dropdown from "../../../../components/Dropdown/Dropdown"

const Home = () => {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
      // Fetch data from JSON or API
    //   axios.get('../../datas/Applications.json')
    //     .then(response => {
    //       setTasks(response.data)
    //     })
    //     .catch(error => {
    //       console.error("Error fetching tasks data:", error);
    //     })
    setTasks(Applications)
    }, [])

  return (
    <div className=' min-h-full w-full pr-6 xl:pr-40 pl-6 text-primary-black py-10'>
        <div className="space-y-2 mb-4 text-start">
        <h1 className="text-xl font-semibold">Bonjour, John Doe</h1>
        <p className='text-sm text-primary-text'>Voici un résumé de votre activité.</p>
        </div>
        

        {/* <div className="grid grid-cols-1 md:grid-cols-3  gap-4 mb-6">
        
        {[1,2,3].map((item)=>{
          return(
          <div class="border-2 h-40 border-neutral-200 p-5 bg-white rounded-xl drop-shadow-md shadow-neutral-400 flex flex-col justify-between">
              <div class="icon mb-4">
                  <div class="icon-square"></div>
                  <div class="text-neutral-600 text-lg font-semibold">Team projects</div>
              </div>
              <div className="flex justify-between items-center">
                  <div class="text-[#1f1f1f] text-3xl font-semibold">12</div>
                  <div class="text-neutral-400 text-sm ">Apr 2, 2023</div>
              </div>
          </div>  
          )
        })}

        </div> */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
          <HomeCard baseColor="bg-pink-400"/>
          <HomeCard baseColor='bg-purple-400'/>
          <HomeCard baseColor='bg-lime-400'/>     
        </div>
        <div className="bg-primary-beige w-full rounded-md py-4 px-6  flex justify-between items-center" >
            <div className="flex gap-4">
              <img src={userPic} alt="" className='bg-white border-2 border-white rounded-full w-14 h-14' />
            <div className="flex flex-col justify-between text-start">
                <p className='text-lg font-bold'>Votre profil est incomplète</p>
                <p className='text-sm'>Editer votre profil et créer votre CV.</p>
            </div>
            </div>
            <button className="bg-white font-semibold flex items-center gap-x-2 rounded-md shadow py-2 px-6 text-sm">
                Editer
                <img src={next} alt="" className='w-4 rotate-180' />
            </button>
            
        </div>

        <div className=" w-full">
      <div className="  py-4 ">
        <div className="flex items-center justify-between">
          <p className="text-base sm:text-lg md:text-xl lg:text-lg font-bold text-gray-800">Historique des candidatures</p>
          <button className=" font-semibold flex items-center gap-x-2 rounded-md  py-2 text-sm">
                Voir tout
                <img src={next} alt="" className='w-4 rotate-180' />
            </button>
        </div>
      </div>
      
      <div className="bg-white py-4  text-sm">
        <div className=" overflow-x-auto  pb-28">
          <table className="w-full ">
            <thead>
              <tr className="text-left bg-neutral-100 py-4 rounded-sm">
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Title</th>
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Date de candidature</th>
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Status</th>
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm ">Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(task => (
                <tr key={task.id} className=" border-b border-gray-100 rounded ">
                  <td className="pl-5 py-3">
                    <div className="flex items-center gap-2">
                        <div className="flex flex-col justify-between">
                            
                      <p className="text-base font-medium mr-2">{task.title}</p>
                      <div className="flex gap-2 text-xs">
                        Aprivo France
                      </div>
                        </div>
                    </div>
                  </td>
                  <td className="pl-5  justify-start">
                    <button className="py-3 px-3 text-sm text-[#0BA02C]  flex items-center gap-1"> 
                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0BA02C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125" stroke="#0BA02C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                        {task.status}</button>
                  </td>
                  <td className="pl-5">
                    <p className="text-sm leading-none  text-start flex gap-1 items-center text-primary-text">
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z" stroke="#5E6670" stroke-width="1.5" stroke-miterlimit="10"/>
                      <path d="M14.5703 5.43076C15.2408 5.24184 15.9441 5.1988 16.6326 5.30454C17.3212 5.41029 17.9791 5.66236 18.562 6.04377C19.1449 6.42519 19.6393 6.92709 20.012 7.51568C20.3846 8.10427 20.6268 8.76588 20.7221 9.45594C20.8175 10.146 20.764 10.8485 20.565 11.5161C20.366 12.1837 20.0263 12.8009 19.5687 13.3262C19.1111 13.8514 18.5463 14.2726 17.9123 14.5611C17.2782 14.8497 16.5897 14.9991 15.8931 14.9992" stroke="#5E6670" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054" stroke="#5E6670" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.8926 15C17.2166 14.999 18.5213 15.3171 19.6962 15.9273C20.8712 16.5375 21.8819 17.4218 22.6426 18.5054" stroke="#5E6670" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    12 Candidats</p>
                  </td>
                  <td className="pl-5 flex items-center">
                    <RippleButton className='whitespace-nowrap w-40 bg-primary-green text-green-900 font-semibold hover:bg-primary-green/80' path={"candidature/"+task?.id} >Voir candidature</RippleButton>
                    <Dropdown/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
        </div>

 
    </div>
  )
}

export default Home