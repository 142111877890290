const Pagination =() =>{

    return(
        <>
        <div class="inline-flex items-center justify-center gap-3 my-6">
  <button
    class="inline-flex size-10 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180 hover:bg-slate-50 focus:border-neutral-300"
  >
    <span class="sr-only">Prev Page</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clip-rule="evenodd"
      />
    </svg>
  </button>

  <p class="text-sm text-gray-900">
    3
    <span class="mx-0.25">/</span>
    12
  </p>

  <button
    class="inline-flex size-10 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180 hover:bg-slate-50 focus:border-neutral-300"
  >
    <span class="sr-only">Next Page</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="size-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
</div>
        </>
    )
}

export default Pagination