import React from 'react'

const Heading = ({title, subtitle, ...props}) => {
  return (
    <div {...props}>
        <h5 className=' font-bold text-xl text-center'>{title}</h5>
        <p className='text-gray-900 text-center'>{subtitle}</p>
    </div>
  )
}

export default Heading