import React, {useState} from 'react'
import { BiPlus } from 'react-icons/bi'
import { CgClose } from 'react-icons/cg'
import Select from '../../../../../components/Form/Select'
// import Input from '../../../../../components/Form/Input'
import Modal from '../../../../../components/Modal'

const Language = ({data}) => {
    const [showModal, setShowModal] = useState(false)

  return (
                    <div className="flex-col text-start border p-4 rounded-md w-full">
                    <div className="flex justify-between items-center  mb-4">
                        <div className=' font-semibold'>Langues</div>
                        
                        <button  onClick={()=>setShowModal(true)}  className="bg-neutral-50 border rounded-sm p-1">
                            <BiPlus className='w-5 h-5 text-neutral-500'></BiPlus>

                        </button>
                    </div>
                    <div className=" flex items-center gap-2 flex-wrap">

                        <div className="py-2 pl-4 pr-3.5 text-primary-text flex gap-4 items-center border rounded-sm">
                            <div className="w-8 h-8 bg-primary-black flex items-center justify-center text-white rounded-full">
                                AN
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Anglais</p>
                                <p>Intérmédiaire</p>
                            </div>
                            <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'>
                                <CgClose/>
                            </button> 
                        </div>
                        
                        <div className="py-2 pl-4 pr-3.5 text-primary-text flex gap-4 items-center border rounded-sm">
                            <div className="w-8 h-8 bg-primary-black flex items-center justify-center text-white rounded-full">
                                FR
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Français</p>
                                <p>Intérmédiaire</p>
                            </div>
                            <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'>
                                <CgClose/>
                            </button> 
                        </div>
                    </div>

            {/* Modal ajout de langue */}
            <Modal  isOpen={showModal} onClose={()=>setShowModal(false)}>
                <div className="flex items-center justify-between py-2 px-4">
                    <p>Ajouter une langue</p>
                    <button  onClick={()=>setShowModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
                    <CgClose/>
                    </button>
                </div>
                <hr className='mb-6'/>
                <div className="px-6 space-y-4 mb-4">
                    <Select label='Langue' placeholder="Veuillez sélectionner" options={["Anglais", "Français", "Chinois", "Avancé", "Billingue", "Natale"]}/>
                    <Select label="Niveau" placeholder="Veuillez sélectionner" options={["Notion", "Débutant", "Intermédiaire", "Avancé", "Billingue", "Natale"]}></Select>
                </div>
                <div className="flex items-center justify-end p-6">
                    <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
                    Enregistrer
                    </button>
                </div>
            </Modal>  
                </div>
  )
}

export default Language