import React, { useState } from 'react'
import ButtonBlack from '../../../../components/Buttons/ButtonBlack'
import { BiDownload } from 'react-icons/bi'
import ConfirmModal from "../../../../components/Modal/ConfirmModal"
import Modal from '../../../../components/Modal'
import TextAreaEditor from '../../../../components/Form/TextAreaEditor'
import { CgClose } from 'react-icons/cg'
import Input from '../../../../components/Form/Input'
import Select from '../../../../components/Form/Select'
import ImagePicker from "../../../../components/ImagePicker"
import image1 from "../../../assets/images/gabrielle-henderson-DgCPTkDqhHg-unsplash.jpg"
import imagefile from "../../../../plateform/assets/icons/paperclip-svgrepo-com.svg"
import FilePicker from "../../../../components/FilePicker"

const Abonnement = () => {
  const [showModal, setShowModal] = useState(false)
  const features= [
    "Accès aux offres d'emploi basiques",
    "Création d'un CV simple",
    "Inscription aux formations gratuites",
    "Alertes personnalisées pour les nouvelles offres correspondant à votre profil"
  ]

  const [showAddModal, setshowAddModal] = useState()

  const PlansDatas = [
    {
      "id":"100",
      "plan": "Premium",
      "activate_at": "01-01-2015",
      "expire_at": "01-01-2015",
      "price": 20500
    },
    {
      "id":"244",
      "plan": "Premium",
      "activate_at": "01-01-2015",
      "expire_at": "01-01-2015",
      "price": 20500
    },
    {
      "id":"5041",
      "plan": "Premium",
      "activate_at": "01-01-2015",
      "expire_at": "01-01-2015",
      "price": 20500
    }
  ]

  return (
    <div className='layout-1 text-sm'>
      <div className="grid grid-cols-2 gap-4">
        <div className='gap-y-4 space-y-4'>
         <div className='line-border space-y-2'>
          <h5 className='font-semibold'>Abonnement actuelle</h5>
          <h5 className='font-semibold text-lg'>Standard</h5>
          <div>
            Date d'expiration: 20 Septempbre 2024
          </div>
          <p className='text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident reprehender</p>
          <div className='grid grid-cols-2 gap-4'>
            <ButtonBlack  onClick={()=>setshowAddModal(true)}>Changer</ButtonBlack>
            <button className='px-6 py-2 rounded-md  hover:bg-neutral-50'
            onClick={()=>setShowModal(true)}
            >Annuler</button>  
          </div>
          
        </div> 
        <div className='line-border space-y-2'>
          <h5 className='font-semibold'>Prochain abonnement</h5>
          <h5 className='font-semibold text-lg'>40.000 Ariary</h5>
          <div>
            <p>Date prévue d'activation: 21 Septembre 2024</p>
          </div>
          <p className='text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident reprehender</p>
          <ButtonBlack onClick={()=>setshowAddModal(true)}>Payer maintenant</ButtonBlack>
        </div>
      
        </div>
        <div className='line-border space-y-2'>
          <h5 className='font-semibold'>Fonctionnalités</h5>
          <h5 className='font-semibold text-lg'>2.000 Ariary</h5>
          <div>
            <p>Date d'activation: 21 Septembre 2024</p>
          </div>
          <p className='text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident reprehender</p>
          <hr />
          <ul className="mt-4 space-y-3">
                    {features.map((feature, index) => (
                        <li key={index} className="flex space-x-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-neutral-900" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l5 5l10 -10"></path>
                            </svg>
                            <span className=" text-start">{feature}</span>
                        </li>
                    ))}
                </ul>
        </div>
        
      </div>

      <div className="    overflow-auto  py-4  w-full">
        <div className=" ">
          {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati facere error eligendi modi, corrupti autem in minima explicabo neque libero nulla maxime voluptatum assumenda exercitationem asperiores rem, quo inventore repudiandae? */}
          <table className="w-full">
            <thead className='text-sm'>
              <tr className="text-left bg-neutral-100 py-3 rounded-sm">
                <th className="pl-5 bg-neutral-100 py-3 rounded-sm ">ID</th>
            
                <th className="pl-5 bg-neutral-100 py-3 rounded-sm">Date d'activation</th>
               
                <th className="pl-5 bg-neutral-100 py-3 rounded-sm">Plan</th>
                <th className="pl-5 bg-neutral-100 py-3 rounded-sm">Prix</th>
                <th className="pl-5 bg-neutral-100 py-3 rounded-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {PlansDatas.map((item, index) => (
                <tr key={item.id} className=" border-b border-gray-100 rounded ">
                  <td className="pl-5 py-3">
                    <p className="text-sm leading-none  text-start font-semibold">#{item.id}</p>
                  </td>
                  <td className="pl-5">
                    <p className="text-sm leading-none  text-start">{item.activate_at}</p>
                  </td>
                  <td className="pl-5">
                    <p className="text-sm leading-none  text-start">{item.plan}</p>
                  </td>
                  <td className="pl-5">
                    <p className="text-sm leading-none  text-start">{item.price}</p>
                  </td>
                  {/* <td className="pl-5  justify-start">
                    <button className="py-3 px-3 text-sm text-[#0BA02C]  flex items-center gap-1"> 
                        <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-4 w-4 text-[#0BA02C]" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M5 12l5 5l10 -10"></path>
                        </svg>
                        {item.status}</button>
                  </td> */}
                  <td className="pl-5 whitespace-nowrap">
                  <BiDownload></BiDownload>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ConfirmModal setIsOpen={()=>setShowModal(false)} isOpen={showModal}></ConfirmModal>

      <Modal  isOpen={showAddModal} onClose={()=>setshowAddModal(false)}>
        <div className="flex items-center justify-between py-2 px-4">
            <p>Payer un abonnement</p>
            <button  onClick={()=>setshowAddModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
            <CgClose></CgClose>
            </button>
        </div>

        <hr className='mb-6'/>
       
        <div className="px-6 space-y-4 mb-4">
            <Input label="Objet"/>
            {/* <Input label="Intitulé du poste"/> */}
            <Select label="Type d'abonnement" options={["Standard", "Professionnel", "Prémium"]}/>
            {/* <div className="grid grid-cols-4 gap-4 items-end">
                <Input classContainer='col-span-2' label="Adresse"/>
                <Select label="District"/>
                <Select label="Région"/>
            </div>
            <div className="grid grid-cols-4 gap-4 items-end">
                <Select label="Mois de début"/>
                <Select label="Anné de début"/>
                <Select label="Mois fin"/>
                <Select label="Année fin"/>
            </div> */}
            <TextAreaEditor label='Message'/>
            <FilePicker />
        </div>
        <div className="flex items-center justify-end p-6">
            <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
                Enregistrer
            </button>
        </div>
      </Modal>    

    </div>
  )
}

export default Abonnement