import React from 'react'
import FAQDropdown from '../../../components/Dropdown/FAQDropdown'
import back11 from '../../assets/Illustrations/haveaquestion.png'

const Help = () => {
    const FAQDatas = [
        {
          title: "Comment puis-je m'inscrire à un cours ?",
          message:
            "Pour vous inscrire à un cours, rendez-vous sur la page des cours, choisissez celui qui vous intéresse, puis cliquez sur le bouton 'S'inscrire'. Vous serez guidé à travers le processus d'inscription.",
        },
        {
          title: "Comment puis-je postuler à une offre d'emploi ?",
          message:
            "Pour postuler à une offre d'emploi, créez un compte et complétez votre profil. Ensuite, trouvez l'offre qui vous intéresse et cliquez sur 'Postuler'. Suivez les instructions pour soumettre votre candidature.",
        },
        {
          title: "Y a-t-il des frais pour utiliser la plateforme ?",
          message:
            "L'inscription et la recherche de cours ou d'offres d'emploi sont gratuites. Cependant, certains cours peuvent être payants ou offrir des certifications moyennant des frais. Les détails sont indiqués sur la page du cours.",
        },
        {
          title: "Comment puis-je annuler mon inscription à un cours ?",
          message:
            "Pour annuler votre inscription, accédez à votre tableau de bord, sélectionnez le cours concerné et choisissez l'option 'Annuler l'inscription'. Notez que des frais d'annulation peuvent s'appliquer selon la politique du cours.",
        },
        {
          title: "Comment puis-je contacter le support client ?",
          message:
            "Si vous avez des questions ou besoin d'assistance, vous pouvez contacter notre support client via l'onglet 'Contactez-nous' sur notre site ou par email à support@votresite.com. Nous sommes là pour vous aider.",
        },
        {
          title: "Quels types de cours proposez-vous ?",
          message:
            "Nous proposons une large gamme de cours allant des compétences techniques (programmation, data science) aux soft skills (leadership, communication). Consultez notre catalogue pour découvrir toutes nos offres.",
        },
        {
          title: "Puis-je obtenir un certificat après avoir terminé un cours ?",
          message:
            "Oui, la plupart de nos cours offrent un certificat de réussite que vous pouvez ajouter à votre CV ou profil LinkedIn. Les détails sont disponibles sur la page de chaque cours.",
        },
      ]

  return (
    <div className='space-y-10 my-10'>
      <img src={back11} alt="" className='fixed bottom-6 left-0 w-56 h-56' />
        <div className="space-y-4 xl:px-80 lg:px-20 px-6">
            <h3 className='text-xl font-semibold text-start'>
              Offre  
            </h3>
            {FAQDatas.map((item, index)=>{
            return(
            <FAQDropdown key={index} className="mb-4 " question={item.title}>
                {item.message}
            </FAQDropdown>
                )
            })}
        </div>

        <div className="space-y-4 xl:px-80 lg:px-20 px-6">
            <h3 className='text-xl font-semibold text-start'>
              Recruteur
            </h3>
            {FAQDatas.map((item, index)=>{
            return(
            <FAQDropdown key={index} className="mb-4 " question={item.title}>
                {item.message}
            </FAQDropdown>
                )
            })}
        </div>

        <div className="space-y-4 xl:px-80 lg:px-20 px-6">
            <h3 className='text-xl font-semibold text-start'>
              Formation  
            </h3>
            {FAQDatas.map((item, index)=>{
            return(
            <FAQDropdown key={index} className="mb-4 " question={item.title}>
                {item.message}
            </FAQDropdown>
                )
            })}
        </div>
       
        <div className="space-y-4 xl:px-80 lg:px-20 px-6">
            <h3 className='text-xl font-semibold text-start'>
              Recruteur  
            </h3>
            {FAQDatas.map((item, index)=>{
            return(
            <FAQDropdown key={index} className="mb-4 " question={item.title}>
                {item.message}
            </FAQDropdown>
                )
            })}
        </div>

    </div>
  )
}

export default Help