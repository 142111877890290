import React from 'react'
import img from '../../../assets/users/helene-engels.png'
import bookmaker from '../../../assets/icons/bookmaker-svgrepo-com.svg'
import Input from '../../../../components/Form/Input'
import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated'

const Parameter = () => {
  return (
    <div className='min-h-full w-full pr-6 lg:pr-20 xl:pr-40 pl-6 text-primary-black py-6 '>
<div className="flex justify-between items-center mb-4">
    <h1 className='font-semibold'>Paramètres du compte</h1>
    {/* <button>Menu</button> */}
</div>
<div className="grid grid-cols-1 gap-4 text-sm">
    <div className="col-span-4">
        <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
            <button className="border-b-gray-950 px-6 py-2 border-b-2 font-bold text-primary-black">
                Mon compte
            </button>
            <button className="px-6 py-2 text-primary-text hover:text-primary-black">
                Notifications
            </button>
        </div>
    </div>
</div>

<div className="divide-y">
    <div className="text-sm text-start space-y-2 my-4">
        <h2 className="font-semibold text-sm">Informations du compte</h2>
        <p className='text-sm text-primary-text'>
            Gérez et modifiez les informations associées à votre compte.
        </p>
    </div> 
    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Adresse email</h2>
            <p className='text-sm text-primary-text'>
                Consultez ou mettez à jour votre adresse email associée à votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4">
            <div className="text-sm text-start space-y-2">
                <h2 className="font-semibold text-sm">johndoe@gmail.com</h2>
                <p className='text-sm text-primary-text'>
                    Votre adresse email a été vérifiée.
                </p>
            </div>
            <Input label="Email" />
            <div className="flex justify-end my-4">
                <button className='text-xs px-6 py-2.5 rounded-md bg-primary-black text-white'>
                    Modifier
                </button>
            </div>
        </div>
    </div>

    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Sécurité du compte</h2>
            <p className='text-sm text-primary-text'>
                Mettez à jour votre mot de passe pour renforcer la sécurité de votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4">
            <Input type='password' label="Ancien mot de passe" />
            <Input type='password' label="Nouveau mot de passe" />
            <div className="flex justify-end my-4">
                <button className='text-xs px-6 py-2.5 rounded-md bg-primary-black text-white'>
                    Modifier
                </button>
            </div>
        </div>
    </div>
{/* </div> */}


            <div className="flex justify-end py-6">
                <button className="text-red-500 text-sm flex items-center gap-1">
                    Désactiver un compte
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_98_23157)">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#FF6550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 8H12.01" stroke="#FF6550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 12H12V16H13" stroke="#FF6550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_98_23157">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                </button>
            </div>
            
        </div>
        
       
    </div>
    
  )
}

export default Parameter