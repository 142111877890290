import React from 'react'
// import logotransparent from '../../assets/logo/logotransparent.png'
// import angleRight from '../../assets/icons/angle-right.svg'
import { Link, useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()
  const footer = [
    {
      title: "MENU",
      text: [
        { list: "Page d'accueil", url: "/" },
        { list: "Service", url: "/service" },
        { list: "Professionnel", url: "/prestataire" },
        { list: "Reservation", url: "/reservation" },
        // { list: "Contact", url: "/contact" },
        // { list: "Blog", url: "/blog" },
      ],
    },
    // {
    //   title: "SECTIONS",
    //   text: [
    //     { list: "En-têtes", url: "/headers" },
    //     { list: "Fonctionnalités", url: "/features" },
    //     { list: "Attrayant", url: "/attractive" },
    //     { list: "Témoignages", url: "/testimonials" },

    {
      title: "ENTREPRISE",
      text: [
        { list: "Nous contacter", url: "/contact" },
        { list: "Terme et condition", url: "/termecondition" },
      ],
    },
  ];
  

  return (
    <>


      <footer className='bg-[#171717] py-12 text-white px-6 mt-10 '>
        <div className="flex justify-center -mt-[75px] pb-4">
          <div className="w-14 h-14 bg-white border-4 border-[#171717] rounded-full"></div>
        </div>
        <div className='container mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 selection:bg-orange-300/80 selection:text-white'>
          <div className='flex flex-col items-start'>
            {/* <img src={logotransparent} alt='Logo' className='w-36' /> */}
            <h2 className='font-medium mt-4 text-start'>Besoin d'aide avec quelque chose ?</h2>
            <p className='text-gray-400 mt-4 text-start'>Recevez des mises à jour, des offres spéciales, des tutoriels et des réductions directement dans votre boîte de réception chaque mois.</p>

            <div className='flex mt-6 gap-x-4'>
             {["Instagram", "Facebook","Twiter"].map((item, index)=>{
              return(
                <div className="w-10 h-10 bg-gray-50 rounded-full border" key={index}>

                </div>
              )
             })}
             
            </div>
          </div>
          <div></div>

          {footer.map((val) => (
            <div key={val.title} className='flex flex-col text-start selection:bg-orange-300/80 selection:text-white'>
              <h3 className='font-medium mb-8'>{val.title}</h3>
              <ul>
                {val.text.map((items, index) => (
                  <Link to={items.url} key={index} className='hover:text-white items-center text-gray-400 mb-5 cursor-pointer flex gap-1'>
                    {/* <img src={angleRight} className='w-6 h-6' alt="" /> */}
                    {items.list}</Link>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <div className='text-center py-5 bg-[#171717] text-gray-500 border-t border-gray-700'>
        <span>© 2024 MIRA. HAISOA</span>
      </div>
    </>
  )
}

export default Index