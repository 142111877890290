import React, { useState, useEffect } from "react";
import defaultUserImage from "../../assets/DefaultImage/user1.jpeg"; // Par défaut pour les utilisateurs
import defaultProductImage from "../../assets/DefaultImage/4 fun bulletin board ideas for schools.jpeg"; // Par défaut pour les produits
import defaultOtherImage from "../../assets/DefaultImage/Online education Illustration.jpeg"; // Par défaut générique
import defaultRequestImage from "../../assets/DefaultImage/Search Projects __ Photos, videos, logos, illustrations and branding.gif"

const ImageWithFallback = ({ src, alt = "", className, defaultType = "other" }) => {
  // Logique pour sélectionner l'image par défaut selon le type
  const getDefaultImage = () => {
    switch (defaultType) {
      case "user":
        return defaultUserImage;
      case "request":
        return defaultRequestImage;
      default:
        return defaultOtherImage;
    }
  };

  const [imgSrc, setImgSrc] = useState(src || getDefaultImage());

  const handleError = () => {
    setImgSrc(getDefaultImage());
  };

  useEffect(() => {
    // Si src change, mettre à jour l'image
    setImgSrc(src || getDefaultImage());
  }, [src, defaultType]);

  return (
    <img
      src={imgSrc}
      alt={alt || "default image"}
      onError={handleError}
      className={className}
    />
  );
};

export default ImageWithFallback;
