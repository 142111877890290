import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header/Header'
import Footer from "../../components/Footer"

const WebsiteLayout = () => {
const [showScroll, setshowScroll] = useState(true)

  return (
    <>
      {/* <div className="min-h-screen bg-[#f7fce3] "> */}
      <div className={
        showScroll ? 
        "min-h-screen selection:bg-primary-green selection:text-black "
        :
        "min-h-screen selection:bg-primary-green selection:text-black max-h-screen overflow-hidden "}>
        <Header />
        <div className="max-w-[1920px] mx-auto pt-14 pb-14  md:pt-14 md:pb-4 ">
          <Outlet context={{showScroll, setshowScroll}}/>
        </div>
        <Footer/>
      </div>
    </>
  )
}

export default WebsiteLayout