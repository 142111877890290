import React, {useState} from 'react'
import { BsArrow90DegRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import Modal from '../../../components/Modal'
import { CgArrowRight, CgClose } from 'react-icons/cg'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 
import defaultImage from "../../assets/images/f848bf27-0697-4745-95ab-fed6b141ccdc.jpg"
import ImageWithFallback from '../ImageWithFallback'


const RequestCard = ({item}) => {

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const animateSkeleton =()=>{
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }

  return (
    <div className="group">
      <div
        onClick={() => {
          setShowModal(true);
          animateSkeleton();
        }}
        className="relative bg-white h-80 overflow-hidden transition-all ease-in cursor-pointer flex flex-col"
      >
        {/* <img
          src={item.user?.candidate_profile?.photo || defaultImage} // Image de l'utilisateur ou un placeholder par défaut
          alt={item.user.candidate_profile?.name}
          className="absolute inset-0 h-full w-full object-cover"
        /> */}

<ImageWithFallback src={item.image} className="absolute inset-0 h-full w-full object-cover" defaultType="request"/>
        <div className="flex relative mt-0 justify-end">
          <div className="triangle-square relative mt-0 opacity-0 group-hover:opacity-100 duration-200 ease-in"></div>
        </div>

        <div className="relative border-t border-t-white/30 ease-in duration-200 mt-auto z-1 h-24 text-start px-6 py-4 text-white bg-black/10 backdrop-blur-lg backdrop-filter">
          <div className="flex justify-between items-start">
            <div className="space-y-4 flex-col">
              <p>{item.user.candidate_profile?.name}</p> {/* Nom de l'utilisateur */}
              <p>{new Date(item.created_at).toLocaleDateString()}</p> {/* Date de création formatée */}
            </div>
            <p>{item.category_id}</p> {/* Catégorie (tu pourrais transformer l'ID en nom si tu as les catégories dans une autre table) */}
          </div>
        </div>
      </div>
      <div className="flex-col text-start space-y-2 py-2">
        <h4 className="text-lg font-semibold group-hover:underline">{item.title}</h4>
        <p className="text-primary-text">{item.description}</p>
        <Link className="flex gap-2 items-center">
          <span className="text-sm font-semibold">Lire plus</span>
          <CgArrowRight/>
        </Link>
      </div>
      <Modal
        defaultClassName="relative h-fit bg-white rounded-sm max-md:w-[60vw] max-lg:w-[80vw] z-40 lg:w-[50vw]"
        width={"40vw"}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="p-6">
          <div className="flex justify-end">
            <button
              onClick={() => setShowModal(false)}
              className="px-2 py-2 rounded-md border text-primary-black bg-[#fbfbfb]"
            >
              <CgClose></CgClose>
            </button>
          </div>
          <div className="flex gap-4 items-center">
            {loading ? (
              <Skeleton
                circle={true}
                height={50}
                width={50}
                highlightColor="#fbfbfb"
                baseColor="#e9e9e9"
              />
            ) : (
              <ImageWithFallback src={item.user.candidate_profile?.photo} className="w-10 h-10 rounded-full object-cover" defaultType="user"/>
              // <img
              //   src={item.user.candidate_profile?.photo || 'default-image-path.jpg'}
              //   alt={item.user.candidate_profile?.name}
              //   className="w-10 h-10 rounded-full"
              // />
            )}

            <div className="flex flex-col justify-between items-start ">
              {loading ? (
                <Skeleton
                  width={100}
                  highlightColor="#fbfbfb"
                  baseColor="#e9e9e9"
                />
              ) : (
                <h1 className="text-lg font-semibold text-start">
                  {item.user.candidate_profile?.name}
                </h1>
              )}

              {loading ? (
                <Skeleton
                  width={150}
                  highlightColor="#fbfbfb"
                  baseColor="#e9e9e9"
                />
              ) : (
                <p className="text-sm text-start">
                  {item.user.candidate_profile?.address || 'Adresse non renseignée'}
                </p>
              )}
            </div>
          </div>
          <hr className="my-4" />
          {loading ? (
            <Skeleton
              width={"100%"}
              highlightColor="#fbfbfb"
              baseColor="#e9e9e9"
              count={3}
            />
          ) : (
            <p className="text-sm text-primary-text text-start mb-4">
              {item.description}
            </p>
          )}

          {loading ? (
            <Skeleton
              width={"100%"}
              highlightColor="#fbfbfb"
              baseColor="#e9e9e9"
              height={220}
              className="mt-6"
            />
          ) : (
            <img
              src={item.user.candidate_profile?.photo || defaultImage}
              alt={item.user.candidate_profile?.name}
              className="w-full h-60 rounded-md object-cover"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};



export default RequestCard