import React from 'react'
import user from "../../../assets/users/bonnie-green-2x.png"
import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated';

export const testimonialsData = [
    {
      photo: user, // Remplacer 'user' par le chemin de la vraie image
      name: "Marie Dupont",
      job: "Responsable Marketing",
      message:
        "Grâce à cette plateforme, j'ai pu trouver des collaborateurs talentueux en un temps record. L'interface est intuitive et les fonctionnalités sont parfaitement adaptées à nos besoins.",
    },
    {
      photo: user, // Remplacer 'user' par le chemin de la vraie image
      name: "Jean Martin",
      job: "Développeur Full-Stack",
      message:
        "J'ai décroché mon poste actuel grâce à ce site. Les offres sont variées et le processus de candidature est simple et rapide. Je recommande vivement pour tous les chercheurs d'emploi dans le domaine tech.",
    },
    {
      photo: user, // Remplacer 'user' par le chemin de la vraie image
      name: "Sophie Leclerc",
      job: "Consultante en Ressources Humaines",
      message:
        "L'utilisation de cette plateforme a révolutionné notre manière de recruter. Les profils des candidats sont clairs et détaillés, ce qui facilite grandement notre sélection.",
    },
    {
      photo: user, // Remplacer 'user' par le chemin de la vraie image
      name: "Lucas Bernard",
      job: "Data Analyst",
      message:
        "La recherche d'emploi peut être stressante, mais ce site m'a permis de trouver des opportunités correspondant exactement à mes compétences et à mes aspirations professionnelles.",
    },
    {
      photo: user, // Remplacer 'user' par le chemin de la vraie image
      name: "Emma Moreau",
      job: "Graphiste",
      message:
        "J'adore la diversité des offres proposées ici. En tant que freelance, je trouve toujours des projets intéressants et bien rémunérés. C'est un excellent outil pour booster sa carrière.",
    },
  ];
  
const Testimonials = () => {
   
  return (
    <div className='w-full pl-6 lg:pl-20 py-10 lg:py-20 xl:pl-40 '>
      <div className="flex justify-between items-center mb-10 pr-6 lg:pr-20 xl:pr-40">
        <h4 className='text-2xl lg:text-4xl font-bold text-start'>Ce que disent Les Clients</h4>
        <ButtonHoverAnimated className='flex px-3 md:px-4 py-1.5 md:py-2 border-2 border-primary-black font-semibold text-primary-black rounded-full text-sm md:text-base'>
          Voir tout
        </ButtonHoverAnimated>
      </div>
      <div className='flex overflow-hidden gap-x-6 py-1 pl-1'>
        {testimonialsData.map((item, index)=>{
          return (
            <div key={item.id} className="p-6 bg-white shadow rounded-md min-w-96 text-start space-y-4">
              <p>{item.message}</p>
              <div className="flex gap-2">
                  <img src={item.photo} alt={item.name} className='w-12 h-12 rounded-full' />
                  <div className="flex justify-between flex-col">
                      <h5 className='text-lg font-bold'>{item.name}</h5>
                      <p>{item.job}</p>
                </div>
              </div>
            </div>
          )
        })}    
      </div>
    </div>
  )
}

export default Testimonials