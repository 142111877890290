import React from 'react'

export const About = ({item}) => {

  return (
    <div className=' space-y-6'>
              <div>
        <h4 className="text-xl font-bold mb-2">
          A propos de l'entreprise
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
          Labore ut similique quas in officia, sequi neque!
        </p>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-2">
          Description
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
          Labore ut similique quas in officia, sequi neque!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto est commodi pariatur aspernatur nihil? Nesciunt maiores deserunt porro? Eveniet ipsum animi a velit distinctio, rerum illum. Deleniti dolor sed porro!
          Quae laborum, quo, nisi tempore delectus vel placeat laudantium laboriosam odit rem quia repellat omnis non sed sint sequi? Explicabo quisquam adipisci officia quis sequi repellendus inventore provident quod libero.
          Asperiores, harum incidunt nesciunt ab eveniet enim nobis eligendi. Exercitationem, reprehenderit voluptatum ipsam culpa nihil voluptate quas, accusantium fugit possimus suscipit modi ullam inventore magni aspernatur dicta laboriosam amet. Placeat.
          Excepturi nobis consectetur, repudiandae cumque aliquam totam deleniti tempore ex officia, suscipit id saepe rerum veritatis amet adipisci odit? Quisquam, quo velit reiciendis voluptatibus libero modi totam itaque recusandae nobis!
          Ipsa et quas possimus porro facere nobis non ducimus nihil neque eos, aspernatur accusantium nesciunt fuga, similique quia odit ipsum accusamus quam voluptates beatae quod, aperiam quae. Exercitationem, soluta. Ducimus.
        </p>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-2">
          Missions et Responsabilités
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
         <ul className='list-item decoration-solid'>
          <li className='list-item'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
         </ul>
         </p>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-2">
          Avantages
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
          Labore ut similique quas in officia, sequi neque!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto est commodi pariatur aspernatur nihil? Nesciunt maiores deserunt porro? Eveniet ipsum animi a velit distinctio, rerum illum. Deleniti dolor sed porro!
          Quae laborum, quo, nisi tempore delectus vel placeat laudantium laboriosam odit rem quia repellat omnis non sed sint sequi? Explicabo quisquam adipisci officia quis sequi repellendus inventore provident quod libero.
          Asperiores, harum incidunt nesciunt ab eveniet enim nobis eligendi. Exercitationem, reprehenderit voluptatum ipsam culpa nihil voluptate quas, accusantium fugit possimus suscipit modi ullam inventore magni aspernatur dicta laboriosam amet. Placeat.
          Excepturi nobis consectetur, repudiandae cumque aliquam totam deleniti tempore ex officia, suscipit id saepe rerum veritatis amet adipisci odit? Quisquam, quo velit reiciendis voluptatibus libero modi totam itaque recusandae nobis!
          Ipsa et quas possimus porro facere nobis non ducimus nihil neque eos, aspernatur accusantium nesciunt fuga, similique quia odit ipsum accusamus quam voluptates beatae quod, aperiam quae. Exercitationem, soluta. Ducimus.
        </p>
      </div>
    </div>
  )
}
