import React from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // N'oublie pas de charger les styles de Skeleton
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'; // Icone ou logo par défaut

const OrganisationCardSkeleton = () => (
  <div className='bg-white border rounded-lg overflow-hidden py-6 px-6 transition-all ease-in cursor-pointer'>
    <div className="flex justify-between items-start">
      <div className='flex flex-row gap-3 mb-3'>
          <Skeleton circle={true} height={50} width={50} />
        <div>
          <div className='text-lg font-semibold'>
            <Skeleton width={150} />
          </div>
        </div>
      </div>
        <Skeleton width={90} height={40} />
    </div>

    <div className='text-level-1 flex-col justify-between text-start'>
      <p className='text-primary-text mb-4'>
        <Skeleton count={3} />
      </p>
    </div>

    <div className="flex gap-2 text-sm text-primary-text">
      <div className="border text-semibold px-4 py-2 rounded-full">
        <Skeleton width={100} />
      </div>
    </div>
  </div>
);

export default OrganisationCardSkeleton