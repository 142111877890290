import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import next from '../../assets/icons/back-svgrepo-com.svg'
import ImageWithFallback from '../ImageWithFallback'

const FormationCard = ({item}) => {
    const navigate = useNavigate()
  return (
    <div onClick={()=>navigate("/formation/"+item?.id)}  className='bg-white rounded-lg overflow-hidden border transition-all ease-in hover:scale-[1.01] hover:shadow-md cursor-pointer'>
        <div className=' flex flex-col sm:flex-row gap-4 text-start p-4'>
            <div className='border bg-slate-50 rounded-sm flex items-center justify-center min-w-[350px] max-h-56 '>
              <ImageWithFallback src={item?.image} className=" bg-fit bg-top w-[350px] "/>
            </div>

            <div className='flex flex-col justify-between gap-y-2'>
              <p className='text-level-1 flex-col justify-between col'>
                <div className='font-semibold text-lg'>{item?.title}</div>
                <span>{item?.organisation?.name}</span>
              </p>

              <div className="flex gap-x-2 text-primary-text">
                <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">
                  {item?.type}
                </div>
                <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">
                  {item?.method}
                </div>
              </div>
              <p className='mb-2 hidden sm:block'>{item?.description}</p>
              <div className="flex justify-between items-center">
                  <p>14 Juin 2024</p>
                  <button className='bg-[#4dff4d] border-none rounded-sm text-[#145926] font-semibold text-sm px-4 py-2 flex gap-2 items-center'>Je m'informe <img src={next} alt="" className='w-4 rotate-180' /></button>
              </div>
            </div>
        </div>

    </div >
  )
}

export default FormationCard