import React, {useState} from 'react'
import { BiPlus } from 'react-icons/bi'
import { CgClose } from 'react-icons/cg'
import Modal from '../../../../../components/Modal'
import Input from '../../../../../components/Form/Input'

const Skills = () => {

    const [showModal, setShowModal] = useState(false)

  return (
    <div className="flex-col text-start border p-4 rounded-md">
        <div className="flex justify-between items-center  mb-4">
            <div className=' font-semibold'>Compétence</div>
            
            <button onClick={()=>setShowModal(true)} className="bg-neutral-50 border rounded-sm p-1">
                <BiPlus className='w-5 h-5 text-neutral-500'></BiPlus>
            </button>
        </div>
        <div className=" flex gap-2 flex-wrap">
            <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
            <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
            <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
            <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
        </div>
        
        {/* Modal modification de la biographie */}
        <Modal  isOpen={showModal} onClose={()=>setShowModal(false)}>
            <div className="flex items-center justify-between py-2 px-4">
                <p>Ajouter une compétence</p>
                <button  onClick={()=>setShowModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
                <CgClose></CgClose>
                </button>
            </div>
            <hr className='mb-6'/>
            <div className="px-6 space-y-4 mb-4">
                <Input label='Compétence'/>
            </div>
            <div className="flex items-center justify-end p-6">
                <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
                Enregistrer
                </button>
            </div>
        </Modal>    
    </div>
  )
}

export default Skills