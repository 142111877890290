import React, { useState, useMemo } from 'react'; 
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; // Include Quill styles
import "./TextAreaEditor.css";

const TextAreaEditor = ({ label = "", placeholder = "Écrivez ici...", setValue, height = "120px", value = '' }) => {
  const [content, setContent] = useState('');
  
  // Generate unique ID for each instance
  const toolbarId = useMemo(() => `toolbar-${Math.random().toString(36).substr(2, 9)}`, []);

  const handleContentChange = (value) => {
    setContent(value);
    if (setValue) setValue(value); // Set value if a setValue prop is provided
  };

  return (
    <div style={styles.editorContainer}>
      <p className="text-start text-sm mb-1">{label}</p>
      <ReactQuill
        value={value || content}
        onChange={handleContentChange}
        modules={{ toolbar: `#${toolbarId}` }} // Bind to unique toolbar ID
        formats={TextAreaEditor.formats}
        placeholder={placeholder}
        style={styles.editor}
      />

      {/* Toolbar */}
      <div id={toolbarId} style={styles.toolbar}>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
        <button className="ql-list" value="bullet"></button>
        <button className="ql-list" value="ordered"></button>
      </div>
    </div>
  );
};

// Configure modules and formats for the editor
TextAreaEditor.formats = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link', 'list'];

const styles = {
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
    borderRadius: '0 0 8px 8px',
  }
};

export default TextAreaEditor;
