import React, {useState, useEffect} from 'react'
import datas from '../../datas/Datas.json'
import { Link } from 'react-router-dom'
import InputIcon from '../../../components/Form/InputIcon'
import Select from '../../../components/Form/Select'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import FindTalentCard from '../../components/Cards/FindTalentCard'
import Checkbox from '../../../components/Form/Checkbox'
import InputRadio from '../../../components/Form/InputRadio'
import location from "../../assets/searchIcon/location.svg"
import category from "../../assets/searchIcon/category.svg"
import back11 from '../../assets/images/49eddec5-17f2-4a0c-b7ca-5ece838b0848.jpg'
import filter from "../../assets/searchIcon/filter.svg"
// import TrainerCard from '../../components/Cards/TrainerCard'
import TrainerCard1 from '../../components/Cards/TrainerCard1'
import LayoutSwitcher from '../../components/LayoutSwitcher'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCandidates, setFilters  } from '../../../redux/CandidateSlice'

const FindTalent = () => {
    // const [jobs, setJobs] = useState(datas)
    // const [candidates, setCandidates] = useState(datas)
    // const [showModal, setshowModal] = useState(false)
    // const [listStyle, setListStyle] = useState("md:grid-cols-1")
    const dispatch = useDispatch();
    const { candidates, filters, loading } = useSelector((state) => state.candidate);
    const [listStyle, setListStyle] = useState('md:grid-cols-1');
  
    // Déclenche la récupération des candidats avec les filtres actuels
    useEffect(() => {
      dispatch(fetchCandidates(filters))
    }, [filters, dispatch])
  
    const handleFilterChange = (e) => {
      const { name, value, type, checked } = e.target
      if (type === 'checkbox') {
        dispatch(setFilters({ [name]: checked ? value : '' }))
      } else {
        dispatch(setFilters({ [name]: value }))
      }
    };

    return (
        <div className=''>
          <div className="bg-purple-300 h-56 w-full p-responsive py-8" style={{ backgroundImage: `url(${back11})`, objectFit: "cover" }}>
            <div className="flex justify-between mb-10">
            <h5 className='font-semibold text-lg'>Candidat</h5>
            <li className="flex gap-x-2 text-sm items-center">
              <ul className="text-gray-600"> <Link to="/home">Accueil</Link>  / </ul>
              <ul className="font-medium">Candidat</ul>
            </li>
            </div>

            {/* Search bar */}
            <div className="bg-white rounded-lg shadow h-auto p-2">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
            <InputIcon
              name="keyword"
              placeholder="Offre, poste, mot clé ..."
              noBorder={true}
              onChange={handleFilterChange}
            />
            <InputIcon
              className="hidden xl:block"
              name="location"
              placeholder="Localisation"
              noBorder={true}
              onChange={handleFilterChange}
            />
            <InputIcon
              className="hidden md:block"
              name="sector"
              placeholder="Sélectionner un secteur"
              noBorder={true}
              onChange={handleFilterChange}
            />
            <div className="flex items-center gap-x-2 gap-y-2">
              <button 
                // onClick={() => setshowModal(true)} 
                className=" h-full w-[58px] justify-center flex items-center border border-[#E8E8E8] bg-[#FBFBFB] rounded-md flex gap-2 items-center">
                <img src={filter} alt="" />
              </button>
              <button className="p-4 py-3 bg-black rounded-md text-white font-medium w-full">
                Rechercher
              </button>
            </div>
          </div>
        </div>

          </div>
                  {/* customise list display */}
        <div className="bg-white  px-6 lg:px-20 xl:px-40 h-16 shadow-sm  mb-6 py-3">
            <div className="flex justify-between items-center ">
              <Select name="period" options={["Récent","Populaire"]} mb='0'/>
              <LayoutSwitcher setListStyle={setListStyle} listStyle={listStyle} />
            </div>
        </div>  

          <div className="flex-1 p-responsive">
          <div className="flex gap-x-6">
     <div className="hidden md:block w-64">
      <div className="bg-white rounded-lg overflow-hidden border py-3 ">
      <p className='flex justify-between mx-6 pb-3 items-center '> 
        <span className='text-sm font-bold text-black'>Filtre</span> 
        <button className='btn-green px-4 py-2 text-xs'>Appliquer</button>
      </p>
      <hr />

<div className='  text-level-2  filter-job'>

{/* Level */}
  {/* <div className="border-b py-4 px-6">
    <h3 className='text-start text-sm font-bold mb-2' >Niveau</h3>
    <div className='flex-col space-y-2'>
    <InputRadio name="level" id="level1" label="Confirmé" value="Confirmé" onChange={handleFilterChange} />
                    <InputRadio name="level" id="level2" label="Intermédiaire" value="Intermédiaire" onChange={handleFilterChange} />
                    <InputRadio name="level" id="level3" label="Junior" value="Junior" onChange={handleFilterChange} />
    </div>
  </div> */}

{/* Type de contrat */}
  <div className="border-b py-4 px-6">
    <div  className='text-start text-sm font-bold mb-2'>Type de contrat</div>
    <div className='flex-col space-y-2'>
        <Checkbox name="working-method" value="Temps plein" label="Temps plein" onChange={handleFilterChange}/>
        <Checkbox name="working-method" value="En remote" label="En remote" onChange={handleFilterChange}/>
        <Checkbox name="working-method" value="Temps plein" label="Temps plein" onChange={handleFilterChange}/>
    </div>  
  </div> 

  {/* Expérience */}
  <div className="border-b py-4 px-6">
    <h3 className='text-start text-sm font-bold mb-2' >Expérience</h3>
    <div className='flex-col space-y-2'>
      {['Tous', '1 - 2 Années', '3 - 4 Années', '5 - 6 Années', '6 - 7 Années', '8 - 9 Années', '10 Années et plus'].map((value, index) => (
        <InputRadio
          key={index}
          name="experience"
          id={`experience_${index}`}
          label={value}
          value={value}
          onChange={handleFilterChange}
          checked={filters?.experience === value}
        />
      ))}
    </div>
  </div>

  {/* Education */}
  <div className="border-b py-4 px-6">
    <div  className='text-start text-sm font-bold mb-2'>Education</div>
    <div className='flex-col space-y-2'>
      {['Tous', 'BAC', 'BAC+2', 'BAC+3', 'Master', 'Doctorat'].map((value, index) => (
        <InputRadio
          key={index}
          name="education"
          id={`level_${index}`}
          value={value}
          label={value}
          onChange={handleFilterChange}
          checked={filters?.education === value}
        />
      ))}
    </div>  
  </div> 

  {/* Sexe */}
  <div className="border-b py-4 px-6">
    <h3 className='text-start text-sm font-bold mb-2' >Sexe</h3>
    <div className='flex-col space-y-2'>
      <InputRadio name="gender" id="Homme" label="Homme" value="Homme" onChange={handleFilterChange}/>
      <InputRadio name="gender" id="Femme" label="Femme" value="Femme" onChange={handleFilterChange}/>
    </div>
  </div> 

</div>
      
      </div>
    </div>
    <div className="flex-1 ">

      <div className={'grid grid-cols-1  gap-6 '+listStyle}>
        {candidates?.map((item)=>{
            return( 
                listStyle === "md:grid-cols-1" ? 
                <FindTalentCard key={item.id} item={item} />   
                :
                <TrainerCard1 item={item}/>
            )
        })}
      </div>
     
    {/* </div> */}
</div>

</div>
          </div>
        </div>
      )
}

export default FindTalent