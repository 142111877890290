import React, {useEffect, useState} from 'react'
import ApplicationsData from "../../datas/Applications.json"
import RippleButton from '../../../../components/Buttons/RippleButton'
import next from '../../../assets/icons/back-svgrepo-com.svg'
import { BiCalendar } from 'react-icons/bi'
import { IoClose, IoCloseCircle } from 'react-icons/io5'

const Enrollment = () => {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {

      // Fetch data from JSON or API
    //   axios.get('../../datas/Applications.json')
    //     .then(response => {
    //       setTasks(response.data)
    //     })
    //     .catch(error => {
    //       console.error("Error fetching tasks data:", error);
    //     })

    setTasks(ApplicationsData)
    }, [])

  return (
    <div className=' min-h-full w-full pr-6 xl:pr-40 pl-6 text-primary-black py-10'>
        <div className="bg-white py-4  text-sm">
        <div className=" overflow-x-auto">
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr className="text-left bg-neutral-100 py-4 rounded-sm">
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Title</th>
                {/* <th className="pl-5">Priority</th> */}
                {/* <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Date de candidature</th> */}
                {/* <th className="pl-5">View Count</th> */}
                {/* <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Status</th> */}
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm w-48">Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(task => (
                <tr key={task.id} className=" border-b border-gray-100 rounded ">
                  <td className="pl-5 py-2">
                    <div className="flex items-center gap-2">
                        <div className="min-w-14 h-14 bg-neutral-50 border border-slate-100 rounded-md flex items-center justify-center">
                        <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.93359V26.7116L15.2701 35.8927L15.6245 35.322L15.2701 18.0677L0.525037 8.94415L0 8.93359Z" fill="#449B82"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3751 8.85156V26.8801L15.2702 35.8943V18.0692L29.8218 8.86512L30.3751 8.85156Z" fill="#9BDB9C"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1875 0.00390625L30.375 8.8512L15.2701 18.3663L0 8.93471L15.1875 0.00390625Z" fill="#56CDAD"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8159 7.09375L17.6792 10.1342V16.271L12.5325 13.1806L7.58368 16.11V28.9367L12.7204 25.7513V18.847L18.2067 22.3492L22.8159 19.491V7.09375Z" fill="white"/>
                        </svg>
                        </div>
                        <div className="flex flex-col justify-between">
                        <div className="flex gap-2 items-center">
                            <p className="text-base font-medium mr-2">{task.title}</p>
                            <div className="rounded-full border-2 border-primary-green bg-primary-green/10 text-[#85ec55] px-3 py-1 text-xs">En remote</div>
                        </div>
                      
                      <div className="flex gap-2 text-sm text-primary-text">
                        <div className="flex gap-1 items-center">
                            <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.25 9.16666C19.25 15.5833 11 21.0833 11 21.0833C11 21.0833 2.75 15.5833 2.75 9.16666C2.75 6.97862 3.61919 4.8802 5.16637 3.33303C6.71354 1.78585 8.81196 0.916656 11 0.916656C13.188 0.916656 15.2865 1.78585 16.8336 3.33303C18.3808 4.8802 19.25 6.97862 19.25 9.16666Z" stroke="#5e6670 " stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11 11.9167C12.5188 11.9167 13.75 10.6854 13.75 9.16666C13.75 7.64787 12.5188 6.41666 11 6.41666C9.48122 6.41666 8.25 7.64787 8.25 9.16666C8.25 10.6854 9.48122 11.9167 11 11.9167Z" stroke="#5e6670 " stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Aprivo France
                        </div>

                        <div className="flex gap-1 items-center">
                            <BiCalendar></BiCalendar>
                            2 Jours Restantes
                        </div>

                      </div>
                        </div>
                    </div>
                  </td>
                  <td className="pl-5 flex items-center">
                  <RippleButton className='w-40 bg-primary-green text-green-900 font-semibold hover:bg-primary-green/80' path={"candidature/"+task?.id}>Voir Détail</RippleButton>
                    <button className="bg-gray-50 border rounded-md p-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke="#555555" stroke-width="1.5" stroke-miterlimit="10"/>
                            <path d="M13 7L7 13" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13 13L7 7" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Enrollment