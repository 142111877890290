import React, { useEffect, useState } from 'react'
import VerificationEmail from './steps/EmailVerification'
import VerificationCode from './steps/CodeVerification'
import ChangePassword from './steps/ChangePassword'
import { BsBack } from 'react-icons/bs'
import { BiArrowBack } from 'react-icons/bi'
import logo from "../../plateform/assets/logo/Fichier 1@2x.png"
import { useNavigate } from 'react-router-dom'

// Input
export default function ForgotPassword() {
    const navigate = useNavigate()
    const [data, setData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })
    const [page, setPage] = useState(1)
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className="w-screen max-w-screen h-screen flex flex-row justify-center items-center  bg-primary-beige">
            <div className="flex-col justify-center flex gap-y-10 max-lg:w-11/12 lg:w-5/12">
            <div className="flex justify-center  "> 
                <img src={logo} alt="logo"  className='w-32 h-16 '/>
            </div>
               
            <div className=' p-6 bg-white shadow-md  border rounded-lg'>
                {/* <form > */}
                <div className='grid grid-cols-12'>
                    <div className="col-span-1">
                        <button 
                        className='text-sm text-neutral-400 border-neutral-400 flex gap-1 items-center p-1 rounded-full border' 
                        onClick={()=>{
                            if(page===1){
                                navigate("/login")
                            }else{
                              setPage(page-1)  
                            }
                            }}><BiArrowBack/></button>
                    </div>
                    
                </div>

                <div className="col-span-11  justify-center mb-4">
                    <h4 className='text-xl font-semibold mb-2'>Mot de passe oublié</h4>
                    <p className='text-primary-text text-sm'>Changer votre mot de passe nécessite une vérification de votre identité</p>
                </div>
                <p>
                </p>
                {
                    page === 1 && (
                        <VerificationEmail setPage={setPage} data={data} setData={setData} />
                    )
                }
                {
                    page === 2 && (
                        <VerificationCode setPage={setPage} data={data} setData={setData} />
                    )
                }
                {
                    page === 3 && (
                        <ChangePassword setPage={setPage} data={data} setData={setData} />
                    )
                }
            </div> 
            </div>
           
        </div>
    )
}
