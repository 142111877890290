import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import {REQUEST_API_END_POINT} from "../utils/constant"

export const fetchRequests = createAsyncThunk('requests/fetchRequests', async () => {
    const response = await axios.get(`${REQUEST_API_END_POINT}/all`);
    return response.data;
});

// Action pour récupérer une seule request par ID
export const fetchRequestById = createAsyncThunk('requests/fetchRequestById', async (id) => {
    const response = await axios.get(`${REQUEST_API_END_POINT}/${id}`);
    return response.data;
});

// Add request action
export const addRequest = createAsyncThunk('requests/addRequest', async (newRequest) => {
    const response = await axios.post('/api/requests', newRequest);
    return response.data;
  });
  

const requestSlice = createSlice({
    name: 'requests',
    initialState: {
        requests: [],
        singleRequest: null,
        loading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Gestion de la récupération de toutes les requests
            .addCase(fetchRequests.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchRequests.fulfilled, (state, action) => {
                state.loading = false;
                state.requests = action.payload;
            })
            .addCase(fetchRequests.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Gestion de la récupération d'une seule request par ID
            .addCase(fetchRequestById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchRequestById.fulfilled, (state, action) => {
                state.loading = false;
                state.singleRequest = action.payload;
            })
            .addCase(fetchRequestById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            //Gestion de publication de requête
            .addCase(addRequest.fulfilled, (state, action) => {
                state.requests.push(action.payload);
              })
            .addCase(addRequest.pending, (state) => {
                state.loading = true;
            })
            .addCase(addRequest.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

export default requestSlice.reducer;
