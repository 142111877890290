import React,{useState, useEffect} from 'react'
import ApplicationsData from "../../../../Datas/Applications.json"
import { CgArrowLeft } from 'react-icons/cg'
// import RippleButton from '../../../../components/Buttons/RippleButton'
import { BiFilter } from 'react-icons/bi'
import ico from "../../../../../assets/searchIcon/search.svg"
import user1 from "../../../../../assets/users/leslie-livingston.png"
import Dropdown from "../../../../../../components/Dropdown/Dropdown"
import DropdownStatus from '../../../../../../components/Dropdown/DropdownStatus'
import { Link } from 'react-router-dom'
import { GoLocation } from 'react-icons/go'


const Enrollement = () => {
    const [tasks, setTasks] = useState([])

    useEffect(() => {
        // Fetch data from JSON or API
      //   axios.get('../../datas/Applications.json')
      //     .then(response => {
      //       setTasks(response.data)
      //     })
      //     .catch(error => {
      //       console.error("Error fetching tasks data:", error);
      //     })
      setTasks(ApplicationsData)
      }, [])

  return (
    <div className=''>


        <div className=" flex justify-between items-center mb-4">
            <h4 className='font-semibold whitespace-nowrap'>Historique des candidatures</h4>
            <div className="flex gap-2 ">
            {/* Search bar */}
            <div className="relative flex items-center w- h-10 rounded-lg   overflow-hidden border ">
            <div className="grid place-items-center h-full w-12 text-gray-300">
                <img src={ico} alt="serachbtn" />
            </div>
            <input
            className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
            type="text"
            id="search"
            placeholder="Mot clé..." 
            // onChange={(e)=>setFormData({...formData, keyword: e.target.value})}
            /> 
            </div>

            {/* Filter button */}
            <button className="px-4 py-2 font-medium border text-[#24292F] hover:bg-primary-black hover:text-white rounded-md focus:outline-none text-sm flex gap-1 items-center justify-between">
            <BiFilter className='w-5 h-5'/>
            Filtrer
            </button>
            </div>
        </div>

      {/* Tableau */}
      <div className="w-full pb-28 lg:w-[100%] xl:w-[900px] min-[1300px]:w-full overflow-x-auto">
        <table className="  text-sm whitespace-pre w-full ">
          <thead>
            <tr className="text-left text-sm bg-neutral-100  rounded-sm">
              <th className="pl-5 bg-neutral-100 py-2 rounded-sm">Title</th>
              <th className="pl-5 bg-neutral-100 py-2 rounded-sm">Rôle</th>
              {/* <th className="pl-5 bg-neutral-100 py-2 rounded-sm">Téléphone</th> */}
              <th className="pl-5 bg-neutral-100 py-2 rounded-sm">Etat</th>
              <th className="pl-5 bg-neutral-100 py-2 rounded-sm">Date de candidature</th>
              <th className="pl-5 bg-neutral-100 py-2 rounded-sm ">Action</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={task.id} className=" border-b border-gray-100 rounded ">
                <td className=" py-3">
                  <div className="flex  ml-5 items-center gap-2 text-start ">
                    <input type="checkbox" name="g" id="" className='w-4 h-4 rounded-lg' />
                     <div className="flex items-center gap-2 p-1.5 w-fit hover:bg-neutral-100 rounded-lg cursor-pointer">
                        <img src={user1} alt="user1" className='w-8 h-8 rounded-full' /> 
                        <div className="flex flex-col justify-between  w-fit mr-5 px-4">
                            <p className="text-base font-medium mr-2">{task.name}</p>
                            <div className="flex gap-2 text-xs">
                            {task.email}
                            </div>
                      </div>

                     </div>
                    
                  </div>
                </td>
                <td className="pl-5">{task.role}</td>
                <td className="pl-5  justify-start">
                  <button className="py-3 px-3 text-sm text-[#0BA02C]  flex items-center gap-1"> 
                  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0BA02C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125" stroke="#0BA02C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                      {task.status}</button>
                </td>
                <td className="pl-5">
                  <p className="text-sm leading-none  text-start flex gap-1 items-center text-primary-text">
                  12/09/2024</p>
                </td>
                <td className="pl-5">
                    <div className="flex  items-center gap-2 text-start ">
                    {/* <RippleButton path={""+index} className='whitespace-nowrap w-40 bg-primary-green text-green-900 font-semibold hover:bg-primary-green/80' >Voir candidature</RippleButton> */}
                    <DropdownStatus></DropdownStatus>
                    <Dropdown/>
                    </div>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Enrollement