import React from 'react'

const ButtonBlack  = ({ children, onClick, type = 'button', DefaultClassName = "px-6 py-2 rounded-md", disabled=false }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={!disabled?`bg-primary-black hover:bg-primary-black/90 text-white   focus:outline-none focus:ring-1 focus:ring-neutral-200 text-sm ${DefaultClassName} `:`bg-neutral-300 text-white px-6 py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-orange-300 text-sm cursor-not-allowed ${DefaultClassName} `+` text-white   focus:outline-none focus:ring-1 focus:ring-neutral-200 text-sm ${DefaultClassName}`}
    >
      {children}
    </button>
  );
};

export default ButtonBlack