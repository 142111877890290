import React, { useState } from 'react';

const LayoutSwitcher = ({ setListStyle, listStyle }) => {
  return (
    <div className="border-gray-200 border flex items-center gap-x-1 p-1 rounded-md">
      <button
        onClick={() => setListStyle('md:grid-cols-2')}
        className={
          listStyle === 'md:grid-cols-2'
            ? 'bg-gray-100 flex justify-center items-center w-8 h-8 rounded-sm text-primary-black'
            : 'bg-none flex justify-center items-center w-8 h-8 rounded-sm text-primary-black'
        }
      >
        <svg
          className="text-primary-black"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="currentColor"
        >
          <g opacity="0.5" clipPath="url(#clip0_98_22837)">
            <path
              d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z"
              stroke="#161616"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z"
              stroke="#161616"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z"
              stroke="#161616"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z"
              stroke="#161616"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_98_22837">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <button
        onClick={() => setListStyle('md:grid-cols-1')}
        className={
          listStyle === 'md:grid-cols-1'
            ? 'bg-gray-100 flex justify-center items-center w-8 h-8 rounded-sm text-primary-black'
            : 'bg-none flex justify-center items-center w-8 h-8 rounded-sm text-primary-black'
        }
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5" clipPath="url(#clip0_98_37017)">
            <path
              d="M18 4.5H6C4.89543 4.5 4 5.39543 4 6.5V8.5C4 9.60457 4.89543 10.5 6 10.5H18C19.1046 10.5 20 9.60457 20 8.5V6.5C20 5.39543 19.1046 4.5 18 4.5Z"
              stroke="#515B6F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 14.5H6C4.89543 14.5 4 15.3954 4 16.5V18.5C4 19.6046 4.89543 20.5 6 20.5H18C19.1046 20.5 20 19.6046 20 18.5V16.5C20 15.3954 19.1046 14.5 18 14.5Z"
              stroke="#515B6F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_98_37017">
              <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  );
};

export default LayoutSwitcher;
