import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CANDIDATE_API_END_POINT } from '../utils/constant';

const candidateSlice = createSlice({
  name: "candidates",
  initialState: {
    candidates: [],
    loading: false,
    error: null,
    message: null,
    singleCandidate: {},
    filters: {
        keyword: '',
        location: '',
        sector: '',
        gender: '',
        experience: '',
        education: '',
        level: '',
        limit: 10
    },
    myCandidates: [],
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = {};
    },
    requestForAllCandidates(state, action) {
      state.loading = true;
      state.error = null;
    },
    successForAllCandidates(state, action) {
      state.loading = false;
      state.candidates = action.payload;
      state.error = null;
    },
    failureForAllCandidates(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestForSingleCandidate(state, action) {
      state.message = null;
      state.error = null;
      state.loading = true;
    },
    successForSingleCandidate(state, action) {
      state.loading = false;
      state.error = null;
      state.singleCandidate = action.payload;
    },
    failureForSingleCandidate(state, action) {
      state.singleCandidate = state.singleCandidate;
      state.error = action.payload;
      state.loading = false;
    },
    requestForPostCandidate(state, action) {
      state.message = null;
      state.error = null;
      state.loading = true;
    },
    successForPostCandidate(state, action) {
      state.message = action.payload;
      state.error = null;
      state.loading = false;
    },
    failureForPostCandidate(state, action) {
      state.message = null;
      state.error = action.payload;
      state.loading = false;
    },

    requestForDeleteCandidate(state, action) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    successForDeleteCandidate(state, action) {
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    failureForDeleteCandidate(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },

    requestForMyCandidates(state, action) {
      state.loading = true;
      state.myCandidates = [];
      state.error = null;
    },
    successForMyCandidates(state, action) {
      state.loading = false;
      state.myCandidates = action.payload;
      state.error = null;
    },
    failureForMyCandidates(state, action) {
      state.loading = false;
      state.myCandidates = state.myCandidates;
      state.error = action.payload;
    },

    clearAllErrors(state, action) {
      state.error = null;
      state.candidates = state.candidates;
    },
    resetCandidateSlice(state, action) {
      state.error = null;
      state.candidates = state.candidates;
      state.loading = false;
      state.message = null;
      state.myCandidates = state.myCandidates;
      state.singleCandidate = {};
    },
  },
});

export const fetchCandidates = (filterKey) => async (dispatch) => {
  try {
    dispatch(candidateSlice.actions.requestForAllCandidates());
    let link = CANDIDATE_API_END_POINT + "/all?";
    let queryParams = [];

    if (filterKey?.keyword) {
      queryParams.push(`keyword=${filterKey?.keyword}`);
    }
    if (filterKey?.location) {
      queryParams.push(`location=${filterKey?.location}`);
    }
    if (filterKey?.sector) {
      queryParams.push(`sector=${filterKey?.sector}`);
    }
    if (filterKey?.limit) {
      queryParams.push(`page=${filterKey?.limit}`);
    }

    link += queryParams.join("&");
    const response = await axios.get(link);
    console.log("candidate", response?.data?.data)
    dispatch(candidateSlice.actions.successForAllCandidates(response?.data?.data));
    dispatch(candidateSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(candidateSlice.actions.failureForAllCandidates(error.response?.data?.message));
  }
};

export const fetchSingleCandidate = (candidateId) => async (dispatch) => {
  dispatch(candidateSlice.actions.requestForSingleCandidate());
  try {
    const response = await axios.get(`${CANDIDATE_API_END_POINT}/${candidateId}`, { withCredentials: true });
    dispatch(candidateSlice.actions.successForSingleCandidate(response.data.candidate));
    dispatch(candidateSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(candidateSlice.actions.failureForSingleCandidate(error.response.data.message));
  }
};

export const { 
  requestForAllCandidates, 
  successForAllCandidates, 
  failureForAllCandidates, 
  requestForSingleCandidate, 
  successForSingleCandidate, 
  failureForSingleCandidate, 
  clearAllErrors, 
  resetCandidateSlice 
} = candidateSlice.actions;

export const { setFilters, resetFilters } = candidateSlice.actions;
export default candidateSlice.reducer;
