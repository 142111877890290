import React, { useState, useRef } from 'react'
import ButtonBlack from "../../../components/Buttons/ButtonBlack"
// import Input from '../../../components/Form/Input'

const CodeVerification = ({ data, setData, setPage }) => {
  const inp1 = useRef(null);
  const inp2 = useRef(null);
  const inp3 = useRef(null);
  const inp4 = useRef(null);
  const [notMatch, setNotMatch] = useState(false)
  const [code, setCode] = useState([undefined, undefined, undefined, undefined]);

  const handleChange = (index, prevRef, currentRef, nextRef) => {
      code[index] = currentRef.current.value
      console.log(code[index])
      if (currentRef.current.value !== '') {
          if (nextRef !== undefined) {
              nextRef.current.focus()
          } 
          else {
              const codeConfirmation = code[0] + code[1] + code[2] + code[3]
              setData({ ...data, code: codeConfirmation })

              // A commenter
              setOptions({ ...options, loading: false, disabled: false })
              setNotMatch(false)

              // A décommenter

              // if (data.codeGenerated.toString() === codeConfirmation) {
              //     alert("hourra")
              //     setNotMatch(false)
              // } else {
              //     alert('oups')
              //     setNotMatch(true)
              //     code[0] = undefined
              //     code[1] = undefined
              //     code[2] = undefined
              //     code[3] = undefined
              // }
          }
      } else if (currentRef.current.value === '') {
          if (prevRef !== undefined) {
              prevRef.current.focus()
          }
      }

  }
  // *******************
  const [options, setOptions] = useState({
      loading: false,
      disabled: true
  })
  const [isOpen, setIsOpen] = useState({
      fails: false
  })

  function handleSubmit(e) {
      e.preventDefault()
      // console.log(data)
      // setOptions({ ...options, loading: true })
      // // setPage(prev => prev += 1)
      // if (data.code === data.codeGenerated.toString()) {
          // setOptions({ ...options, loading: false, disabled: false })
          setPage(prev => prev += 1)
      // } else {
      //     setIsOpen({ ...isOpen, fails: true })
      //     setOptions({ ...options, loading: false })
      //     // setData({...data, codeGenerated: '' })
      // }
  }

  // useEffect(() => {
  //     if (data.code !== '') {
  //         setOptions({ ...options, disabled: false })
  //     } else {
  //         setOptions({ ...options, disabled: true })
  //     }
  // }, [data])
  return (
      <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
          <p className='text-center'>Nous avons envoyé une code de verification à votre adresse e-mail <span className="text-primary">{data.email}</span></p>
          {/* <Input title={"Code de verification"} required={true} type='number' value={data.code} onChange={(e) => { setData({ ...data, code: e.target.value }) }} /> */}
          <div className="grid grid-cols-4 justify-center items-center gap-x-4 py-5 px-0 md:px-20">

                  <input type="text" ref={inp1} value={code[0]} onChange={(e) => { handleChange(0, undefined, inp1, inp2) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                  <input type="text" ref={inp2} value={code[1]} onChange={(e) => { handleChange(1, inp1, inp2, inp3) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                  <input type="text" ref={inp3} value={code[2]} onChange={(e) => { handleChange(2, inp2, inp3, inp4) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                  <input type="text" ref={inp4} value={code[3]} onChange={(e) => { handleChange(3, inp3, inp4, undefined) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
              </div>
              {
                  notMatch && (
                      <div className="w-full">
                          <span className="flex flex-col justify-center items-center w-full rounded-sm gap-x-4 bg-orange-100 text-orange-400 pt-2 pb-4 px-2">
                              <span className="w-full flex justify-end items-center">
                              </span>
                              <span className="flex justify-center items-center gap-x-3">
                                  <p>
                                      Réessayer. nous avons envoyer une autre code de verification a votre adresse email.
                                  </p>
                              </span>
                          </span>
                      </div>
                  )
              }
          
          <ButtonBlack onClick={(e) => { handleSubmit(e) }} disabled={options.disabled}>
          Suivant
          </ButtonBlack>
          {/* <Feedback isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} /> */}
      </form>
  )
}

export default CodeVerification