import axios from 'axios';
import React, {useEffect, useState} from 'react'
import Applications from "../../datas/Applications.json"
import RippleButton from '../../../../components/Buttons/RippleButton'
import next from '../../../assets/icons/back-svgrepo-com.svg'
import userPic from "../../../assets/users/bonnie-green-2x.png";

const Home = () => {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
      // Fetch data from JSON or API
    //   axios.get('../../datas/Applications.json')
    //     .then(response => {
    //       setTasks(response.data)
    //     })
    //     .catch(error => {
    //       console.error("Error fetching tasks data:", error);
    //     })
    setTasks(Applications)
    }, [])

  return (
    <div className=' min-h-full w-full pr-6 xl:pr-40 pl-6 text-primary-black py-10'>

        <div className="grid grid-cols-1 md:grid-cols-3  gap-4 mb-6">
        
        {[1,2,3].map((item)=>{
          return(
          <div class="border-2 h-40 border-neutral-200 p-5 bg-white rounded-xl drop-shadow-lg shadow-neutral-400">
              <div class="icon mb-4">
                  <div class="icon-square"></div>
                  <div class="text-title">Team projects</div>
              </div>
              <div className="flex justify-between items-center">
                  <div class="text-[#999] text-sm">Apr 2, 2023</div>
                  <div class="menu-icon">•••</div>
              </div>
          </div>  
          )
        })}

        </div>
        <div className="bg-[#f5ffcf] w-full rounded-md py-4 px-6  flex justify-between items-center" >
            <div className="flex gap-4">
            {/* <div className="bg-white border rounded-full w-16 h-16"> */}
              <img src={userPic} alt="" className='bg-white border-2 border-white rounded-full w-14 h-14' />
            {/* </div> */}
            <div className="flex flex-col justify-between text-start">
                <p className='text-lg font-bold'>Votre profil est incomplète</p>
                <p className='text-sm'>Editer votre profil et créer votre CV.</p>
            </div>
            </div>
            <button className="bg-white font-semibold flex items-center gap-x-2 rounded-md shadow py-2 px-6 text-sm">
                Editer
                <img src={next} alt="" className='w-4 rotate-180' />
            </button>
            
        </div>

        <div className=" w-full">
      <div className="  py-4 ">
        <div className="flex items-center justify-between px-4">
          <p className="text-base sm:text-lg md:text-xl lg:text-lg font-bold text-gray-800">Historique des candidatures</p>
          <button className=" font-semibold flex items-center gap-x-2 rounded-md  py-2 text-sm">
            Voir tout
            <img src={next} alt="" className='w-4 rotate-180' />
          </button>
        </div>
      </div>
      
      <div className="bg-white py-4  text-sm">
        <div className=" overflow-x-auto">
          <table className="w-full whitespace-">
            <thead>
              <tr className="text-left bg-neutral-100 py-4 rounded-sm">
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Title</th>
                {/* <th className="pl-5">Priority</th> */}
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Date de candidature</th>
                {/* <th className="pl-5">View Count</th> */}
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Status</th>
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(task => (
                <tr key={task.id} className=" border-b border-gray-100 rounded ">
                  <td className="pl-5 py-2">
                    <div className="flex items-center gap-2">
                        <div className="w-14 h-14 bg-neutral-50 border border-slate-100 rounded-md">

                        </div>
                        <div className="flex flex-col justify-between">
                            
                      <p className="text-base font-medium mr-2">{task.title}</p>
                      <div className="flex gap-2 text-xs">
                        Aprivo France
                      </div>
                        </div>
                    </div>
                  </td>
                  <td className="pl-5">
                    <p className="text-sm leading-none  text-start">{task.dueDate}</p>
                  </td>
                  <td className="pl-5  justify-start">
                    <button className="py-3 px-3 text-sm text-[#0BA02C]  flex items-center gap-1"> 
                        <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-4 w-4 text-[#0BA02C]" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M5 12l5 5l10 -10"></path>
                        </svg>
                        {task.status}</button>
                  </td>
                  <td className="pl-5">
                  <RippleButton path={"candidature/"+task?.id}>Voir détail</RippleButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Home