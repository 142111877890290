import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TRAINING_API_END_POINT } from '../utils/constant';

const trainingSlice = createSlice({
  name: "trainings",
  initialState: {
    trainings: [],
    loading: false,
    error: null,
    message: null,
    singleTraining: {},
    filterKeys: {
      keyword: '',
      location: '',
      category: '',
      duration: '',
      level: '',
      limit: 10
    },
    myTrainings: [],
  },
  reducers: {
    requestForAllTrainings(state, action) {
      state.loading = true;
      state.error = null;
    },
    successForAllTrainings(state, action) {
      state.loading = false;
      state.trainings = action.payload;
      state.error = null;
    },
    failureForAllTrainings(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestForSingleTraining(state, action) {
      state.message = null;
      state.error = null;
      state.loading = true;
    },
    successForSingleTraining(state, action) {
      state.loading = false;
      state.error = null;
      state.singleTraining = action.payload;
    },
    failureForSingleTraining(state, action) {
      state.singleTraining = state.singleTraining;
      state.error = action.payload;
      state.loading = false;
    },
    requestForPostTraining(state, action) {
      state.message = null;
      state.error = null;
      state.loading = true;
    },
    successForPostTraining(state, action) {
      state.message = action.payload;
      state.error = null;
      state.loading = false;
    },
    failureForPostTraining(state, action) {
      state.message = null;
      state.error = action.payload;
      state.loading = false;
    },
    requestForDeleteTraining(state, action) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    successForDeleteTraining(state, action) {
      state.loading = false;
      state.error = null;
      state.message = action.payload;
    },
    failureForDeleteTraining(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    requestForMyTrainings(state, action) {
      state.loading = true;
      state.myTrainings = [];
      state.error = null;
    },
    successForMyTrainings(state, action) {
      state.loading = false;
      state.myTrainings = action.payload;
      state.error = null;
    },
    failureForMyTrainings(state, action) {
      state.loading = false;
      state.myTrainings = state.myTrainings;
      state.error = action.payload;
    },
    clearAllErrors(state, action) {
      state.error = null;
      state.trainings = state.trainings;
    },
    resetTrainingSlice(state, action) {
      state.error = null;
      state.trainings = state.trainings;
      state.loading = false;
      state.message = null;
      state.myTrainings = state.myTrainings;
      state.singleTraining = {};
    },
  },
});

// Fetch all trainings with filters
export const fetchTrainings = (filterKey) => async (dispatch) => {
  try {
    dispatch(trainingSlice.actions.requestForAllTrainings());
    let link = TRAINING_API_END_POINT + "/all?";
    let queryParams = [];

    if (filterKey?.keyword) queryParams.push(`keyword=${filterKey.keyword}`);
    if (filterKey?.location) queryParams.push(`location=${filterKey.location}`);
    if (filterKey?.category) queryParams.push(`category=${filterKey.category}`);
    if (filterKey?.limit) queryParams.push(`per_page=${filterKey.limit}`);

    link += queryParams.join("&");
    const response = await axios.get(link);
    dispatch(trainingSlice.actions.successForAllTrainings(response.data.data));
    dispatch(trainingSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(trainingSlice.actions.failureForAllTrainings(error.response?.data?.message));
  }
};

// Fetch a single training by ID
export const fetchSingleTraining = (trainingId) => async (dispatch) => {
  dispatch(trainingSlice.actions.requestForSingleTraining());
  try {
    const response = await axios.get(`${TRAINING_API_END_POINT}/${trainingId}`, {
      withCredentials: true,
    });
    dispatch(trainingSlice.actions.successForSingleTraining(response.data.training));
    dispatch(trainingSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(trainingSlice.actions.failureForSingleTraining(error.response.data.message));
  }
};

// Post a new training
export const postTraining = (data) => async (dispatch) => {
  dispatch(trainingSlice.actions.requestForPostTraining());
  try {
    const response = await axios.post(`${TRAINING_API_END_POINT}/save`, data, {
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    });
    dispatch(trainingSlice.actions.successForPostTraining(response.data.message));
    dispatch(trainingSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(trainingSlice.actions.failureForPostTraining(error.response.data.message));
  }
};

// Get user's own trainings
export const getMyTrainings = () => async (dispatch) => {
  dispatch(trainingSlice.actions.requestForMyTrainings());
  try {
    const response = await axios.get(`${TRAINING_API_END_POINT}/getmytrainings`, {
      withCredentials: true,
    });
    dispatch(trainingSlice.actions.successForMyTrainings(response.data.myTrainings));
    dispatch(trainingSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(trainingSlice.actions.failureForMyTrainings(error.response.data.message));
  }
};

// Delete a training by ID
export const deleteTraining = (id) => async (dispatch) => {
  dispatch(trainingSlice.actions.requestForDeleteTraining());
  try {
    const response = await axios.delete(`${TRAINING_API_END_POINT}/delete/${id}`, {
      withCredentials: true,
    });
    dispatch(trainingSlice.actions.successForDeleteTraining(response.data.message));
    dispatch(clearAllTrainingErrors());
  } catch (error) {
    dispatch(trainingSlice.actions.failureForDeleteTraining(error.response.data.message));
  }
};

// Clear all errors
export const clearAllTrainingErrors = () => (dispatch) => {
  dispatch(trainingSlice.actions.clearAllErrors());
};

// Reset the slice state
export const resetTrainingSlice = () => (dispatch) => {
  dispatch(trainingSlice.actions.resetTrainingSlice());
};

export default trainingSlice.reducer;
