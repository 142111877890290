import React from 'react'
import {JobCategoryData} from "../../../datas/JobCategories"
import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated'


const JobCategories = () => {

  return (
    <div className='px-6 lg:px-20 xl:px-40 pb-20'>
      <div className="flex justify-between items-center mb-10">
        <h4 className='text-2xl md:text-4xl font-bold'>Catégories d'offres</h4>
        {/* <button className="flex px-3 md:px-4 py-1.5 md:py-2 border-2 border-primary-black  font-bold text-primary-black rounded-full text-sm md:text-base">Voir tout</button> */}
        <ButtonHoverAnimated className='flex px-3 md:px-4 py-1.5 md:py-2 border-2 border-primary-black font-semibold text-primary-black rounded-full text-sm md:text-base'>
          Voir tout
        </ButtonHoverAnimated>
      </div>

      <div className="grid lg:grid-cols-3 xl:grid-cols-4 grid-cols-2 gap-4 md:gap-10 ">
{JobCategoryData.map((item)=>{
  return(
    <div className="flex items-center gap-x-4 rounded-md p-1.5 bg-white shadow-md hover:scale-95 ease-in duration-200 hover:shadow-sm cursor-pointer">
      <div className="rounded-md bg-[#f6f6f6] min-w-14 h-14 md:min-w-20 w-12 md:w-20 md:h-20 flex items-center justify-center">
        <img src={item.logo} alt={item.name} className='w-6 h-6 md:w-10 md:h-10' />
      </div>
      <div className="flex flex-col justify-between text-start py-1">
        <p className=' text-base md:text-lg font-semibold'>{item.name}</p>
        <p className='md:text-base text-sm text-primary-text'>{item.offerNumber} Offres</p>
      </div>
    </div>
  )
})}
      </div>
    </div>
  )
}

export default JobCategories