import React, {useState} from 'react'

const InputRadio = ({
    label,
    name,
    value,
    onChange,
    checked = false,
    disabled = false,
    className = '',
    selectedColor = 'black',
    selectedRingColor = 'black',
    id,
    defaultValue,
    ...props
  }) => {
    const [isChecked, setIsChecked] = useState(checked);
  
    const handleChange = (event) => {
      setIsChecked(event.target.checked)
      if (onChange) {
        onChange(event)
      }
    }
  
    return (
      <div className={`flex items-center gap-2 ${className}`}>
        <input
          type="radio"
          name={name}
          id={id}
          value={value}
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
          className={`${selectedRingColor}-ring peer peer-aria-selected:text-black peer-aria-selected:bg-black`}
          {...props}
        />
        <label htmlFor={id} className='text-sm font-medium text-gray-900 '>
          {label}
        </label>
      </div>
    )
  }
  

export default InputRadio