import React from 'react'
import { useNavigate } from 'react-router-dom'
// import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
// import next from '../../assets/icons/back-svgrepo-com.svg'
// import SecteurEntreprise from "../../datas/SecteurEntreprise.json"

const TrainerCard = ({item}) => {

    const navigate = useNavigate()

  return (
    <div onClick={()=>navigate("/formateur/"+item.id)} className='bg-white border rounded-lg overflow-hidden border py-6 px-6 transition-all ease-in hover:border-gray-400 cursor-pointer'>
    <div className="flex justify-between items-start">
        <div className=' flex flex-row gap-3 mb-3'>
            <div className='border bg-slate-50 rounded-sm flex items-center justify-center w-20 h-14'>
                {/* <img src={bookmaker} alt="logo"  className='w-5 h-5'/> */}
                <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7739 0C9.22935 0.00114169 7.97931 1.25472 7.98045 2.79943C7.97931 4.34414 9.2305 5.59772 10.7751 5.59886H13.5697V2.80057C13.5708 1.25586 12.3196 0.00228338 10.7739 0C10.7751 0 10.7751 0 10.7739 0V0ZM10.7739 7.46667H3.32391C1.77934 7.46781 0.528158 8.72139 0.5293 10.2661C0.527017 11.8108 1.7782 13.0644 3.32277 13.0667H10.7739C12.3185 13.0655 13.5697 11.8119 13.5685 10.2672C13.5697 8.72139 12.3185 7.46781 10.7739 7.46667Z" fill="#36C5F0"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M28.4709 10.2661C28.472 8.72139 27.2208 7.46781 25.6763 7.46667C24.1317 7.46781 22.8805 8.72139 22.8817 10.2661V13.0667H25.6763C27.2208 13.0655 28.472 11.8119 28.4709 10.2661ZM21.0197 10.2661V2.79943C21.0209 1.25586 19.7708 0.00228338 18.2263 0C16.6817 0.00114169 15.4305 1.25472 15.4316 2.79943V10.2661C15.4294 11.8108 16.6805 13.0644 18.2251 13.0667C19.7697 13.0655 21.0209 11.8119 21.0197 10.2661Z" fill="#2EB67D"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2243 28C19.7689 27.9988 21.0201 26.7452 21.0189 25.2005C21.0201 23.6558 19.7689 22.4022 18.2243 22.4011H15.4297V25.2005C15.4285 26.7441 16.6797 27.9977 18.2243 28ZM18.2243 20.5321H25.6755C27.22 20.531 28.4712 19.2774 28.4701 17.7327C28.4724 16.188 27.2212 14.9344 25.6766 14.9321H18.2254C16.6809 14.9333 15.4297 16.1869 15.4308 17.7316C15.4297 19.2774 16.6797 20.531 18.2243 20.5321Z" fill="#ECB22E"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.529298 17.7325C0.528156 19.2773 1.77934 20.5308 3.32391 20.532C4.86848 20.5308 6.11966 19.2773 6.11852 17.7325V14.9331H3.32391C1.77934 14.9342 0.528156 16.1878 0.529298 17.7325ZM7.98045 17.7325V25.1992C7.97817 26.7439 9.22935 27.9975 10.7739 27.9998C12.3185 27.9987 13.5697 26.7451 13.5685 25.2004V17.7348C13.5708 16.1901 12.3196 14.9365 10.7751 14.9342C9.22935 14.9342 7.97931 16.1878 7.98045 17.7325C7.98045 17.7337 7.98045 17.7325 7.98045 17.7325Z" fill="#E01E5A"/>
                </svg>
            </div>
            <div>
                
            </div>
        </div>   
        <div className="px-6 py-2 rounded-md bg-[#d4ff6eee] text-green-900">12 Formations</div>
    </div>
    <div className='text-level-1 flex-col justify-between col text-start'>
      <div className='text-lg font-semibold'>{item.organisation?.name}</div>
      {/* <span>{item.organisation.name}</span> */}
      <p className='text-primary-text mb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, ipsam? Officia commodi, id, consectetur rerum cumque accusantium ut molestias repudiandae consequatur minima aliquam natus...</p>
    </div>
    <div className="flex gap-2 text-sm text-primary-text">
      <div className="border text-semibold px-4 py-2 rounded-full">Buisness</div>
    </div>

</div>
  )
}

export default TrainerCard