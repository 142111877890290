import React from "react";
import { motion, AnimatePresence } from 'framer-motion'

const Modal = ({ children, styleContent, styleOverlay, isOpen, onClose, initialOverlay, animateOverlay, exitOverlay, initialContent, animateContent, exitContent, width= "90vw", defaultClassName="relative h-fit bg-white rounded-sm  max-md:w-[90vw] max-lg:w-[80vw] z-40 lg:w-[60vw]" }) => {

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && (
                        <motion.div
                            initial={(initialOverlay === undefined) ? { opacity: 0 } : initialOverlay}
                            animate={(animateOverlay === undefined) ? { opacity: 1 } : animateOverlay}
                            exit={(exitOverlay === undefined) ? { opacity: 0 } : exitOverlay}
                            className={` w-screen h-screen overflow-auto flex justify-center py-10   fixed z-50 top-0 right-0 bg-slate-700 bg-opacity-40 `}
                        >
                            <motion.div
                                initial={(initialContent === undefined) ? { opacity: 0, translateY: -50 } : initialContent}
                                animate={(animateContent === undefined) ? { opacity: 1, translateY: 0 } : animateContent}
                                exit={(exitContent === undefined) ? { opacity: 0, translateY: -50 } : exitContent}
                                className={defaultClassName}
                            >
                                {
                                    children
                                }
                            </motion.div>

                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    )
}
export default Modal