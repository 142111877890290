import React from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../../components/Header/Header'
import back1 from "../../assets/Illustrations/790f3793-3e73-4e81-b8b0-e91ef4deb252.jpg"

const Layout = () => {

    return (
        <>
            <Header />
            <div className="w-full mt-14 bg-primary-purple h-12 z-[40] fixed drop-shadow-d " 
            style={{backgroundImage:"url('"+back1+"')"}}
            ></div>
            {/* <div className="absolute   backdrop-blur-xl backdrop-filter bg-white bg-opacity-15 size-40 rounded-full -right-10 top-20  z-30"> </div>
            <div className=" absolute  rounded-full right-0 top-20 size-12 bg-primary-green z-20" ></div>
            <div className=" absolute  rounded-full right-0 top-20 size-7 bg-primary-beige z-20" ></div> */}
            <div className='flex pt-24 w-full overflow-x-hidden'>
                
                <Sidebar/>
                <Outlet/>
            </div>
                            
        </>
      )
}

export default Layout