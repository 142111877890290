import React, {useState} from 'react'
import datas from '../../../../datas/Datas.json'
import JobCard from '../../../../components/Cards/JobCard'

const Offer = ({item}) => {
    const [jobs, setJobs] = useState(datas)
  return (
    <div className='grid grid-cols-1 gap-y-4'>
       {jobs.map((item)=>{
          return( 
            <JobCard key={item.id} item={item} />
            )
          
        })} 
    </div>
  )
}

export default Offer