export const USER_API_END_POINT="http://localhost:8000/api/user";
export const JOB_API_END_POINT="http://localhost:8000/api/offer";
export const APPLICATION_API_END_POINT="http://localhost:8000/api/v1/application";
export const COMPANY_API_END_POINT="http://localhost:8000/api/recruiter";
export const TRAINING_API_END_POINT ="http://localhost:8000/api/training";
export const TRAINER_API_END_POINT ="http://localhost:8000/api/trainer";
export const CANDIDATE_API_END_POINT ="http://localhost:8000/api/candidate";
export const REQUEST_API_END_POINT ="http://localhost:8000/api/request";
export const API_END_POINT = "http://localhost:8000/api";

// photo api end point
export const CANDIDATE_IMAGE_URL_END_POINT ="http://localhost:8000/api/storage/candidate";
export const COMPANY_IMAGE_URL_END_POINT ="http://localhost:8000/api/storage/company";
export const JOB_IMAGE_URL_END_POINT ="http://localhost:8000/api/storage/offer";
export const TRAINING_IMAGE_URL_END_POINT ="http://localhost:8000/api/storage/training";
export const TRAINER_IMAGE_URL_END_POINT ="http://localhost:8000/api/storage/trainer";
export const USER_IMAGE_URL_END_POINT ="http://localhost:8000/api/storage/user";