import React, { useState } from 'react';
import ico from "../../../plateform/assets/searchIcon/search.svg";
import { CgClose } from 'react-icons/cg';

const SelectIcon = ({
  icon = ico,
  label,
  name,
  options = [], // Liste des options
  value,
  onChange,
  placeholder = "",
  required = false,
  className = '',
  noBorder = false,
  cancel = false,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value || '');

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChange && onChange(e);
  };

  const handleClear = () => {
    setSelectedValue('');
    onChange && onChange({ target: { name, value: '' } });
  };

  return (
    <div className={className}>
      <label className="block mb-1 text-sm font-medium text-gray-700">{label}</label>
      <div className="relative mt-1 col-span-2">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <img src={icon} alt="icon" />
        </div>
        <select
          {...props}
          name={name}
          value={selectedValue}
          onChange={handleChange}
          className={`${!noBorder ? "border border-gray-300 focus:ring-blue-500 focus:border-blue-500" : "border-none outline-none"} text-gray-900 text-sm rounded-lg block w-full ps-10 p-2.5`}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <option key={index} value={option?.value || option}>
              {option?.label || option}
            </option>
          ))}
        </select>
        {selectedValue && cancel && (
          <button
            onClick={handleClear}
            className="absolute end-0 flex items-center pe-3.5 inset-y-0 bg-white/70 rounded-full"
          >
            <CgClose />
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectIcon;
