import React, { useState } from 'react'
// import img from '../../../assets/users/helene-engels.png'
// import bookmaker from '../../../assets/icons/bookmaker-svgrepo-com.svg'
import logo from '../../../assets/users/helene-engels.png'
import Input from '../../../../components/Form/Input'
// import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated'
import ImagePicker from '../../../../components/ImagePicker'
import Select from '../../../../components/Form/Select'
import TextAreaEditor  from "../../../../components/Form/TextAreaEditor"

const Parameter = () => {
    const [option, setOption] = useState(1)

  return (
    <div className='min-h-full w-full pr-6 lg:pr-20 xl:pr-40 pl-6 text-primary-black py-6 '>
<div className="flex justify-between items-center mb-4">
    <h1 className='font-semibold'>Paramètres du compte</h1>
    {/* <button>Menu</button> */}
</div>
<div className="grid grid-cols-1 gap-4 text-sm">
    <div className="col-span-4">
        <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
            <button 
            onClick={()=>setOption(1)}
            className="border-b-gray-950 px-6 py-2 flex items-center gap-1 border-b-2 text-primary-black">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z" stroke="#161616 " stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M2.90625 20.2491C3.82834 18.6531 5.1542 17.3278 6.75064 16.4064C8.34708 15.485 10.1579 15 12.0011 15C13.8444 15 15.6552 15.4851 17.2516 16.4065C18.848 17.3279 20.1739 18.6533 21.0959 20.2493" stroke="#161616 " stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Mon profil
            </button>
            <button 
            onClick={()=>setOption(2)}
            className="px-6 py-2 flex items-center gap-1 text-primary-text hover:text-primary-black">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.90432 8.58739C5.07307 8.23114 5.26994 7.89364 5.49494 7.56551L5.45744 5.12801C5.45744 4.90301 5.55119 4.68739 5.72932 4.53739C6.60119 3.80614 7.59494 3.21551 8.69182 2.82176C8.90744 2.74676 9.14182 2.76551 9.32932 2.88739L11.4199 4.14364C11.8137 4.11551 12.2074 4.11551 12.6012 4.14364L14.6918 2.88739C14.8887 2.77489 15.1231 2.74676 15.3387 2.82176C16.4074 3.20614 17.4106 3.77801 18.3012 4.52801C18.4699 4.66864 18.5731 4.89364 18.5637 5.11864L18.5262 7.55614C18.7512 7.88426 18.9481 8.22176 19.1168 8.57801L21.2449 9.75926C21.4418 9.87176 21.5824 10.0593 21.6199 10.2843C21.8168 11.3999 21.8262 12.5624 21.6199 13.6968C21.5824 13.9218 21.4418 14.1093 21.2449 14.2218L19.1168 15.403C18.9481 15.7593 18.7512 16.0968 18.5262 16.4249L18.5637 18.8624C18.5637 19.0874 18.4699 19.303 18.2918 19.453C17.4199 20.1843 16.4262 20.7749 15.3293 21.1686C15.1137 21.2436 14.8793 21.2249 14.6918 21.103L12.6012 19.8468C12.2074 19.8749 11.8137 19.8749 11.4199 19.8468L9.32932 21.103C9.13244 21.2155 8.89807 21.2436 8.68244 21.1686C7.61369 20.7843 6.61057 20.2124 5.71994 19.4624C5.55119 19.3218 5.44807 19.0968 5.45744 18.8718L5.49494 16.4343C5.26994 16.1061 5.07307 15.7686 4.90432 15.4124L2.77619 14.2311C2.57932 14.1186 2.43869 13.9311 2.40119 13.7061C2.20432 12.5905 2.19494 11.428 2.40119 10.2936C2.43869 10.0686 2.57932 9.88114 2.77619 9.76864L4.90432 8.58739Z" stroke="#767F8C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z" stroke="#767F8C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Compte
            </button>
        </div>
    </div>
</div>

<div className="divide-y">

    {option === 2 &&
    <>
    {/* <div className="text-sm text-start space-y-2 my-4">
        <h2 className="font-semibold text-sm">Informations du compte</h2>
        <p className='text-sm text-primary-text'>
            Gérez et modifiez les informations associées à votre compte.
        </p>
    </div>  */}
    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Adresse email</h2>
            <p className='text-sm text-primary-text'>
                Consultez ou mettez à jour votre adresse email associée à votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4">
            <div className="text-sm text-start space-y-2">
                <h2 className="font-semibold text-sm">johndoe@gmail.com</h2>
                <p className='text-sm text-primary-text'>
                    Votre adresse email a été vérifiée.
                </p>
            </div>
            <Input label="Email" />
            <div className="flex justify-end my-4">
                <button className='text-xs px-6 py-2.5 rounded-md bg-primary-black text-white'>
                    Modifier
                </button>
            </div>
        </div>
    </div>

    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Sécurité du compte</h2>
            <p className='text-sm text-primary-text'>
                Mettez à jour votre mot de passe pour renforcer la sécurité de votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4">
            <Input type='password' label="Ancien mot de passe" />
            <Input type='password' label="Nouveau mot de passe" />
            <div className="flex justify-end my-4">
                <button className='text-xs px-6 py-2.5 rounded-md bg-primary-black text-white'>
                    Modifier
                </button>
            </div>
        </div>
    </div>  

                <div className="flex justify-end py-6">
                <button className="text-red-600 text-sm flex items-center gap-1">
                    Désactiver un compte
                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_98_23157)">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#FF6550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 8H12.01" stroke="#FF6550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 12H12V16H13" stroke="#FF6550" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_98_23157">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                </button>
            </div>  
    </>
    }

{option === 1 &&
    <>
{/* change logo */}
    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Votre logo</h2>
            <p className='text-sm text-primary-text'>
                Mettez à jour votre mot de passe pour renforcer la sécurité de votre compte.
            </p>
        </div>  
        <div className="flex items-center col-span-3 gap-x-4">
            <img src={logo} alt="logocompany" className='w-28 h-28 rounded-full shadow-sm '/>
            <ImagePicker></ImagePicker>
        </div>
    </div>

    {/* Detail */}
    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Sécurité du compte</h2>
            <p className='text-sm text-primary-text'>
                Mettez à jour votre mot de passe pour renforcer la sécurité de votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4">
            <Select label="Secteur d'activité" />
            <Input type='date' label="Date de fondation" />
            <Input label="Site web" />
            <Select label="Taille de l'entreprise" />
            <TextAreaEditor label='Slogan'/>
            <TextAreaEditor label='Description'/>
            <div className="flex justify-end my-4">
                <button className='text-xs px-6 py-2.5 rounded-md bg-primary-black text-white'>
                    Modifier
                </button>
            </div>
        </div>
    </div>

        {/* Localisation */}
        <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Localisation</h2>
            <p className='text-sm text-primary-text'>
                Mettez à jour votre mot de passe pour renforcer la sécurité de votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4">
            <Input label="Adresse" />
            <Select label="Région" />
            <Select label="District" />
            <div className="flex justify-end my-4">
                <button className='text-xs px-6 py-2.5 rounded-md bg-primary-black text-white'>
                    Modifier
                </button>
            </div>
        </div>
    </div>

{/* social media */}
    <div className="grid grid-cols-5 gap-4 py-6">
        <div className="text-sm text-start space-y-2 col-span-2">
            <h2 className="font-semibold text-sm">Réseaux sociaux</h2>
            <p className='text-sm text-primary-text'>
                Mettez à jour votre mot de passe pour renforcer la sécurité de votre compte.
            </p>
        </div>  
        <div className="col-span-3 space-y-4 text-sm">
            {/* facebook */}
            <div className="flex border rounded-md divide-x">
                <div className=" text-primary-black p-2">www.facebook.com/</div>
                <input name="facebook-url" className=" text-primary-text outline-none  p-2" value="johndoe"/>
            </div>

            {/* linkedin */}
            <div className="flex border rounded-md divide-x">
                <div className=" text-primary-black p-2">www.linkedin.com/</div>
                <input name="facebook-url" className=" text-primary-text outline-none  p-2" value="johndoe"/>
            </div>

            {/* twitter */}
            <div className="flex border rounded-md divide-x">
                <div className=" text-primary-black p-2">www.twitter.com/</div>
                <input name="facebook-url" className=" text-primary-text outline-none  p-2" value="johndoe"/>
            </div>
        </div>
    </div>    
    </>
}



            
        </div>
        
       
    </div>
    
  )
}

export default Parameter