import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import './AnimatedButton.css'; // Import the CSS file


const ButtonHoverAnimated = ({ 
  className = " text-sm px-6 py-2 rounded-md border-2 border-black ",
  onClick,
  path="",
  children }) => {
    const navigateTo = useNavigate()
  const [pos, setPos] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    setPos({
      x: e.clientX - left,
      y: e.clientY - top
    });
  };

  const handleMouseLeave  = (e) => {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    setPos({
      x: e.clientX - left,
      y: e.clientY - top
    });
  };

  return (
    <button
      className={"  btn cursor-pointer  hover:text-white duration-100 "+ className }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={()=>{
        if(path) navigateTo(path)
        if(onClick)onClick()

      }}
    >
      {children}
      <span style={{ top: `${pos.y}px`, left: `${pos.x}px` }}></span>
    </button>
  );
};


export default ButtonHoverAnimated