import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import next from '../../assets/icons/back-svgrepo-com.svg'
import img from '../../assets/users/helene-engels.png'
import { motion, AnimatePresence } from 'framer-motion'
import img4 from "../../assets/images/pexels-curtis-loy-3404897-5089386.jpg"

const TrainerCard1 = ({item}) => {
  const [showModal, setShowModal] = useState(false)
  
return (
  <>
    <div  onClick={()=>setShowModal(true)} className='group relative bg-white h-80 rounded-lg overflow-hidden border transition-all ease-in hover:border-gray-400 cursor-pointer flex flex-col'>
        <img 
        // src={item.image}
        src={img4}
         alt={item.name} className='absolute inset-0 h-full w-full object-cover object-top' />
        
        <div className="relative ease-in duration-200 mt-auto z-1 h-24 group-hover:h-32  text-start px-6 py-4 text-white bg-gray-950/25 backdrop-blur-lg backdrop-filter">
        <div className=' text-xl'>
          {/* {item.name} */}
          John Doe Finral
          </div>
        <div className='flex gap-x-1 text-white/60 text-sm'>
          Maquilleuse Professionnelle <br />
          {/* {item.current_job} */}
          San Francisco, Antananarivo
          </div>
        </div>
    </div>

    {/* Start modal */}
    <AnimatePresence>
    {
        showModal && (
    
    <motion.div 
            initial={{ opacity: 0  }}
            animate={{opacity: 1}}
            exit={{ opacity: 0 }}
    className="fixed ease-in duration-200 z-40 bg-gray-900/10 top-0 bottom-0 left-0 right-0 flex justify-end backdrop-blur-sm backdrop-filter">
    <motion.div
      // Animation pour l'apparition
      initial={{ opacity: 0, right: "-100%", y: 20 }}
      animate={{ opacity: 1, right: 0, y: 0 }}
      exit={{ opacity: 0, right: "-100%", y: 20 }}
      transition={{ type: "spring", stiffness: 130, damping: 20 }}
      className="fixed px-6 overflow-y-auto bg-white w-full lg:w-2/3 xl:w-1/2 min-h-full lg:rounded-s-2xl drop-shadow-lg max-h-screen"
    >
           <div className="flex justify-end py-4">
            <button onClick={()=>setShowModal(false)}>Fermer</button>
            </div>
            <div className="flex justify-between items-center mb-4">
                <div className="flex gap-4">
                <img src={img} alt="image" className='w-14 h-14 rounded-full'/>
                    <div className="flex-col flex justify-between text-start">
                    <h4 className='text-xl font-semibold'>Preskovya Dubina</h4>
                    <p className='text-primary-text'>UI UX Designer</p>
                    </div>
                </div>
                <div className="flex gap-x-2">
                <button className="px-6 py-2 border rounded-md flex gap-2 items-center text-white bg-black"><img src={bookmaker} alt="logo"  className='w-5 h-5'/> Mon CV</button>
                    <button className="p-2 border rounded-md"><img src={bookmaker} alt="logo"  className='w-5 h-5'/></button>
                    <button className="p-2 border rounded-md"><img src={bookmaker} alt="logo"  className='w-5 h-5'/></button>
                </div>
            </div>
            <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
                <button className="border-b-gray-950 px-6 py-2 border-b-2 font-bold hover text-primary-black">Bio</button>
                <button className="px-6 py-2 text-primary-text hover text-primary-black">CV</button>
                <button className="px-6 py-2 text-primary-text hover text-primary-black">Expérience</button>
                <button className="px-6 py-2  text-primary-text hover text-primary-black">Education</button> 
            </div>
            <div className="space-y-6">
                <div className="flex-col text-start">
                    <h4 className=' font-semibold  mb-2'>Bio</h4>
                    <p className='text-primary-text'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem deserunt qui vitae commodi, nulla laborum? Sed iure a quia aperiam, nulla recusandae illum facere ad maxime quae. Consectetur, ex. Odit.</p>
                </div>

                <div className="flex-col text-start">
                    <h4 className=' font-semibold  mb-2'>Compétences</h4>
                    <div className=" flex gap-2">
                        <div className="py-2 px-6 text-primary-text bg-gray-50">Java</div>
                        <div className="py-2 px-6 text-primary-text bg-gray-50">Java</div>
                        <div className="py-2 px-6 text-primary-text bg-gray-50">Java</div>
                        <div className="py-2 px-6 text-primary-text bg-gray-50">Git / Gitlab / Github</div>
                    </div>
                </div>

                {/* Expérience */}
                <div className="flex-col text-start">
                    <h4 className=' font-semibold  mb-4'>Expérience</h4>
                    {
                        ["1","2", "3"].map((item)=>{
                            return(
                                <div className="flex gap-4 items-start w-full mb-4">
                                <img src={bookmaker} alt="entreprise" className='w-12 h-12 rounded-full bg-gray-50 border' />
                                <div className='w-full'>
                                    <div className="flex justify-between items-end mb-2">                            <div className="text-start flex-col">
                                            <h6>Title</h6>
                                            <div className="inline-block gap-2 text-sm ">
                                                London . Dribbble
                                            </div>
                                        </div>
                                        <p className='text-sm'>2018-2019</p>
                                    </div>
                                    <p className=' text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, quam vel cumque quasi officiis voluptates debitis praesentium, inventore architecto quo labore quod!</p>
                                </div>
                                </div>   
                            )
                        })
                    }
                
                </div>

                {/* Education */}
                <div className="flex-col text-start">
                    <h4 className=' font-semibold  mb-4'>Education</h4>
                    {
                        ["1","2", "3"].map((item)=>{
                            return(
                                <div className="flex gap-4 items-start w-full mb-4">
                                <img src={bookmaker} alt="entreprise" className='w-12 h-12 rounded-full bg-gray-50 border' />
                                <div className='w-full'>
                                    <div className="flex justify-between items-end mb-2">                            <div className="text-start flex-col">
                                            <h6>Title</h6>
                                            <div className="inline-block gap-2 text-sm ">
                                                London . Dribbble
                                            </div>
                                        </div>
                                        <p className='text-sm'>2018-2019</p>
                                    </div>
                                    <p className=' text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, quam vel cumque quasi officiis voluptates debitis praesentium, inventore architecto quo labore quod!</p>
                                </div>
                                </div>   
                            )
                        })
                    }
                
                </div>
            </div>
        </motion.div>
    </motion.div>

// </motion.div> 
)}
</AnimatePresence>
    </>
  )
}

export default TrainerCard1