import React from 'react'
import { useNavigate } from 'react-router-dom'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import next from '../../assets/icons/back-svgrepo-com.svg'
import { GoLocation } from 'react-icons/go'

const RecruiterCard = ({key, item}) => {
  const navigate = useNavigate()

  return (
    <div key={key} onClick={() => navigate("/recruteur/" + item.id)} className='bg-white border rounded-lg overflow-hidden py-6 px-6 transition-all ease-in hover:border-gray-400 cursor-pointer'>
        <div className="flex justify-between items-start">
            <div className='flex flex-row gap-3 mb-3'>
                <div className='border bg-slate-50 rounded-sm flex items-center justify-center w-20 h-14'>
                    {/* Logo ou icône */}
                    <img src={bookmaker} alt="logo" className='w-5 h-5'/>
                </div>
                <div>
                  <div className='text-lg font-semibold text-start'>{item.organisation.name}</div>
                  <div className='text-sm text-primary-text flex items-center gap-1 text-start'> <GoLocation></GoLocation>{item.organisation.location}</div>
                </div>
            </div> 
            <div className="px-4 py-2 rounded-md whitespace-nowrap bg-[#d4ff6eee] text-green-900">
                {item.offerCount} offres
            </div>
        </div>
        <div className='text-level-1 flex-col justify-between text-start'>
          <p className='text-primary-text mb-4'>{item.description}</p>
        </div>
        <div className="flex gap-2 text-sm text-primary-text">
          <div className="border text-semibold px-4 py-2 rounded-full">{item.sector}</div>
        </div>
    </div>
  )
}

export default RecruiterCard
