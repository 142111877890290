import React, {useState, useEffect} from 'react'
// import ProfileSidebar from './ProfileSidebar'
import {FormationDatas} from '../../datas/FormationDatas'
import { Link, useLocation } from 'react-router-dom'
import '../FindJob/FindJob.css'
import FormationCard from '../../components/Cards/FormationCard'
import InputIcon from '../../../components/Form/InputIcon'
import Select from '../../../components/Form/Select'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import location from "../../assets/searchIcon/location.svg"
import category from "../../assets/searchIcon/category.svg"
import FormationCardSkeleton from "../../components/Skeletons/FormationCardSkeleton"
import back11 from '../../assets/images/49eddec5-17f2-4a0c-b7ca-5ece838b0848.jpg'
import LayoutSwitcher from '../../components/LayoutSwitcher'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTrainings } from '../../../redux/TrainingSlice'

const Formation = () => {
  const dispatch = useDispatch();
  const { trainings, loading, error } = useSelector(state => state.training);
  const [filters, setFilters] = useState({
    keyword: '',
    location: '',
    category: '',
  });
  const [listStyle, setListStyle] = useState('md:grid-cols-1');
  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Appel API pour récupérer toutes les formations avec filtres
  const fetchFilteredTrainings = () => {
    dispatch(fetchTrainings(filters)); // Déclencher l'action Redux avec les filtres
  };

  useEffect(() => {
    fetchFilteredTrainings(); // Charger les données au montage du composant
  }, []);

  // Gérer les changements des filtres (mot-clé, localisation, etc.)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Gérer la recherche
  const handleSearch = () => {
    fetchFilteredTrainings(); // Déclencher une nouvelle recherche avec les filtres actuels
  };

  return (
    <div className=''>
      <div className="bg-purple-300 h-56 w-full px-6 lg:px-20 xl:px-40 py-8" style={{ backgroundImage: `url(${back11})`, objectFit: "cover" }}>
        <div className="flex justify-between mb-10">
          <h5 className='font-semibold text-lg'>Formation</h5>
          <li className="flex gap-x-2 text-sm items-center">
            <ul className="text-gray-600"> <Link to="/home">Accueil</Link> / </ul>
            <ul className="font-medium">Formation</ul>
          </li>
        </div>

        <div className="bg-white rounded-lg shadow h-16 p-2">
          <div className="grid lg:grid-cols-7 sm:grid-cols-5 grid-cols-3">
            <InputIcon
              className='col-span-2'
              name="keyword"
              placeholder='Offre, poste, mot clé ...'
              noBorder={true}
              onChange={handleInputChange}
            />
            <InputIcon
              className='col-span-2 hidden sm:block'
              name="location"
              icon={location}
              placeholder='Localisation'
              noBorder={true}
              onChange={handleInputChange}
            />
            <InputIcon
              className='col-span-2 hidden lg:block'
              name={category}
              placeholder='Sélectionner un secteur'
              noBorder={true}
              onChange={handleInputChange}
            />
            <div className="grid gap-x-2">
              <button onClick={handleSearch} className="p-4 py-3 bg-black rounded-md text-white font-medium">
                Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* customise list display */}
      <div className="bg-white px-6 lg:px-20 xl:px-40 h-16 shadow-sm mb-6 py-3">
        <div className="flex justify-between items-center">
          <Select name="period" options={["Récent","Populaire"]} mb='0'/>
          <LayoutSwitcher setListStyle={setListStyle} listStyle={listStyle} />
        </div>
      </div>

      <div className="flex-1 px-6 lg:px-20 xl:px-40">
        <div className="py-3">
          <div className={`grid grid-cols-1 min-[1920px]:grid-cols-3 md:grid-cols-1 sm:grid-col-span-1 gap-6 ${listStyle}`}>
            {loading ? (
              Array.from({ length: 6 }).map((_, index) => (
              <FormationCardSkeleton key={index}/>
              ))
            ) : (
              trainings.map((item) => (
                <FormationCard key={item.id} item={item} />
              ))
            )}
            {error && <div className="text-red-500">Erreur: {error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formation;