import React from 'react'
import "./HomeCard.css"

const index = ({ baseColor = 'bg-neutral-400'}) => {
  return (
    <div>
      <div className="group-19">
        <div 
        className="group-16"
        >
          <div className="h-1 w-[122px] rounded-full bg-white absolute mt-[22px] "></div>
        {/* <div className="group-31"></div> */}
          {/* <div className="group-29"></div> */}
          <svg width="125" height="24" viewBox="0 0 125 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 20C0.5 15.2718 0.501062 11.776 0.861658 9.09391C1.22032 6.42621 1.9264 4.63857 3.28249 3.28249C4.63857 1.9264 6.42621 1.22032 9.09391 0.861658C11.776 0.501062 15.2718 0.5 20 0.5H80.5C86.4796 0.5 89.3576 0.505916 92.034 1.39804C94.7104 2.29017 97.0163 4.01223 101.8 7.6L123 23.5H0.5V20Z" fill="white" stroke="#E6E4F0"/>
          </svg>

          
        </div> 
        <div className="group-100 drop-shadow-md bg-red-300">
          <div className=' w-full'>
               <div class="flex gap-2 mb-7">
            <div class={"w-8 h-8 rounded-md "+baseColor+""}></div>
            <div class="text-neutral-600 text-lg font-semibold text-start">Total de formation</div>
          </div>
          <div className="flex justify-between items-center">
              <div class="text-[#1f1f1f] text-3xl font-semibold">12</div>
              <div class="text-neutral-400 text-sm ">Apr 2, 2023</div>
          </div>
          </div>
       
          {/* <div className="group-99" ></div>
          <div className="group-98"></div> */}
        </div>
      </div>
    </div>
  )
}

export default index