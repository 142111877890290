import img1 from "../assets/FormationImages/pexels-freestockpro-1540408.jpg"
import img2 from "../assets/FormationImages/pexels-ketut-subiyanto-4353622.jpg"
import img3 from "../assets/FormationImages/pexels-shkrabaanthony-5475750.jpg"

export const FormationDatas = [
    {
        "id":1,
        "img":img1,
        "title": "Formation Make-up",
        "type": "Cours acceleré",
        "method": "En ligne",
        "begin_at": "10-09-2024 10:12:30",
        "end_at": "10-09-2024 10:12:30",
        "description":
        "Amazon recherche un Ingénieur en Machine Learning pour concevoir, développer et optimiser des modèles de machine learning pour nos produits. Vous serez amené à collaborer avec une équipe talentueuse et à travailler sur des projets à grande échelle.",
        "organisation":
            {
                "id":1,
                "name":"Felana Beauty"
            }
        ,
        "salary": "Négociable",
        "work_time": "fultime",
        "work_type": "remote",
        "applicants": "12",
        "created_at": "10-09-2024 10:12:30"
    }, 
    {
        "id":2,
        "img":img2,
        "title": "Cours langue anglais",
        "type": "Cours acceleré",
        "method": "Présentiel",
        "begin_at": "10-09-2024 10:12:30",
        "end_at": "10-09-2024 10:12:30",
        "description":
        "Amazon recherche un Ingénieur en Machine Learning pour concevoir, développer et optimiser des modèles de machine learning pour nos produits. Vous serez amené à collaborer avec une équipe talentueuse et à travailler sur des projets à grande échelle.",
        "organisation":
            {
                "id":1,
                "name":"Google"
            }
        ,
        "created_at": "10-09-2024 10:12:30"
    },
     {
        "id":3,
        "img":img3,
        "title": "Formation développement Web",
        "type": "Cours normal",
        "method": "Présentiel",
        "begin_at": "10-09-2024 10:12:30",
        "end_at": "10-09-2024 10:12:30",
        "description":
        "Amazon recherche un Ingénieur en Machine Learning pour concevoir, développer et optimiser des modèles de machine learning pour nos produits. Vous serez amené à collaborer avec une équipe talentueuse et à travailler sur des projets à grande échelle.",
        "organisation":
            {
                "id":1,
                "name":"Fihary Soft"
            }
        ,
        "salary": "Négociable",
        "work_time": "fultime",
        "work_type": "remote",
        "applicants": "12",
        "created_at": "10-09-2024 10:12:30"
    }
]