import React, {useState} from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import next from '../../assets/icons/back-svgrepo-com.svg'
import img from '../../assets/users/helene-engels.png'
import { motion, AnimatePresence } from 'framer-motion'
import img4 from "../../assets/images/pexels-curtis-loy-3404897-5089386.jpg"
import { CgClose } from 'react-icons/cg'
import SendEmailModal from '../../../components/Modal/SendEmailModal'
import { MdEmail } from 'react-icons/md'
import { TfiEmail } from 'react-icons/tfi'
import { GoDownload } from 'react-icons/go'
import SaveIcon from "../SaveIcon"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 

const FindTalentCard = ({item}) => {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [showSendEmailModal, setShowSendEmailModal] = useState(false)
    const {showScroll, setshowScroll} = useOutletContext()
    const [loading, setLoading] = useState(false)

    const animateSkeleton =()=>{
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    }
  return (
    <>
    <div  className='bg-white rounded-lg overflow-hidden border p-4 transition-all ease-in hover:border-gray-400 cursor-pointer'>
    <div className="flex justify-between  ">
        <div className=' flex flex-row gap-3  items-center w-[80%]'>
            <img src={img4} alt="logo"  className='w-56 h-56 object-cover rounded-sm'/>
            <div className='w-full flex flex-col justify-between h-full'>
                <p className='text-level-1 flex-col justify-between text-start mb-4'>
                    <div className='text-lg font-semibold'>{item?.name || item?.nom}</div>
                    <span className='text-sm'>{item?.candidate_profile?.current_job}</span>
                    <p className='text-start text-sm text-primary-text'>{item?.candidate_profile?.bio || "Aucune bio disponible pour ce candidat"}</p>
                </p>

                    <div className="grid grid-cols-3 text-start w-full mb-4">
                        <div>
                            <p className='text-sm text-primary-text'>Diplôme</p>
                            <p>{item?.candidate_profile?.date_birth}</p>
                        </div>
                        <div>
                            <p className='text-sm text-primary-text'>Diplôme</p>
                            <p>Bacc</p>
                        </div>
                        <div>
                            <p className='text-sm text-primary-text'>Diplôme</p>
                            <p>Bacc</p>
                        </div>
                    </div>

                    <div className="flex gap-2">
                    {item?.candidate_profile?.highest_diploma && <div className='border-r pr-2 border-gray-300'>{item?.candidate_profile?.highest_diploma}</div>}
                        
                        

                        <div className="flex flex-wrap gap-2 ">
                            {item?.candidate_profile?.resume_skills?.map((skill, index)=>{
                                if(index < 5)return(
                                    <div key={index} className="bg-neutral-50 text-neutral-500 text-sm px-2 py-1 border rounded-full">{skill?.name}</div>
                                )
                            })}

                            <div className="bg-neutral- border-primary-black text-primary-black text-sm px-2 py-1 border rounded-full">{item?.candidate_profile?.resume_skills?.lenght-4} et plus</div>
                            
                        </div>
                    </div>
                    
            </div>
        </div> 

        <div className="flex items-end flex-col justify-between ">
            <SaveIcon></SaveIcon> 
            <button  
            onClick={()=>{
                setShowModal(true)
                animateSkeleton()
                setshowScroll(false)
            }

            }
            className='bg-[#4dff4d] whitespace-nowrap border-none rounded-sm text-[#145926] font-semibold text-sm px-4 py-2 flex gap-2 items-center'>Voir Le Profil </button>
        </div>  
        
    </div>

</div>

<AnimatePresence>
    {
        showModal && (
    
        <motion.div 
        initial={{ opacity: 0  }}
        animate={{opacity: 1}}
        exit={{ opacity: 0 }}
        className="fixed ease-in duration-200 z-40 bg-gray-900/10 top-0 bottom-0 left-0 right-0 flex justify-end backdrop-blur-sm backdrop-filter">
        <motion.div
        // Animation pour l'apparition
        initial={{ opacity: 0, right: "-100%", y: 20 }}
        animate={{ opacity: 1, right: 0, y: 0 }}
        exit={{ opacity: 0, right: "-100%", y: 20 }}
        transition={{ type: "spring", stiffness: 130, damping: 20 }}
        className="fixed px-6 overflow-y-auto bg-white w-full lg:w-2/3 xl:w-1/2 min-h-full lg:rounded-s-2xl drop-shadow-lg max-h-screen"
        >
            <div className="flex justify-end py-4">
                <button 
                onClick={()=>{
                    setShowModal(false)
                    setshowScroll(true)
                    }}>
                    <CgClose/>
                </button>
            </div>

            <div className="flex justify-between items-center mb-4">
                {/* <div className="flex gap-4">
                    <img src={img} alt="image" className='w-14 h-14 rounded-full'/>
                    <div className="flex-col flex justify-between text-start">
                    <h4 className='text-xl font-semibold'>Preskovya Dubina</h4>
                    <p className='text-primary-text'>UI UX Designer</p>
                    </div>
                </div> */}
                   <div className="flex gap-4 items-center">
          {loading ?
            <Skeleton circle={true} height={50} width={50} highlightColor='#fbfbfb' baseColor='#e9e9e9'/>
          :
            <img src={item?.image || img4} alt={item.name} className='w-10 h-10 rounded-full object-cover' />
          }
   
       
          <div className='flex flex-col justify-between  items-start '>
          {loading ?
            <Skeleton width={100} highlightColor='#fbfbfb' baseColor='#e9e9e9'/>
          :
          <h1 className='text-lg font-semibold text-start'>John DOE</h1>
          }

{/* <div className='flex flex-col justify-between  items-start '> */}
          {loading ?
            <Skeleton width={150} highlightColor='#fbfbfb' baseColor='#e9e9e9'/>
          :
          <p className='text-sm text-start'>Lot II 123 Antananarivo</p>
          }
            
            
          </div>
        </div>
                <div className="flex gap-x-2">
                    <button className="px-6 py-2 border rounded-md text-sm flex gap-2 items-center text-white bg-primary-black">
                        <GoDownload/> Mon CV
                    </button>
                    <button 
                    onClick={()=>setShowSendEmailModal(true)}
                    className="p-2 w-10 flex justify-center items-center border rounded-md"><TfiEmail/></button>
                </div>
            </div>

            {/* <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
                <button className="border-b-gray-950 px-6 py-2 border-b-2 font-bold hover text-primary-black">Bio</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">CV</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">Expérience</button>
                <button className="px-6 py-2  text-primary-text hover:text-primary-black">Education</button> 
            </div> */}

            <div className="space-y-6">
                <div className="flex-col text-start">
                    {/* <h4 className=' font-semibold  mb-2'>Bio</h4> */}
                    {
                        loading ?
                        <Skeleton count={2} height={10} highlightColor='#fbfbfb' baseColor='#e9e9e9'/>
                        :
                        <p className="text-primary-text">
                            {item?.candidate_profile?.bio ||
                            "Aucune bio disponible pour ce candidat"}
                        </p>
          }
                   
                </div>
                { 
                    item?.candidate_profile?.resume_skills?.lenght && 
                    <div className="flex-col text-start">
                        <h4 className=' font-semibold  mb-2'>Compétences</h4>
                        <div className=" flex gap-2">
                        { 
                                item?.candidate_profile?.resume_skills?.map((skill, index)=>{
                                    return (
                                        <div className="py-2 px-6 text-primary-text bg-gray-50">{skill?.name}</div>
                                    )
                                }
                                )
                            }
                            
                        </div>
                    </div>
                }

                {
                    loading ?
                    <>
                        <div className="flex-col items-start flex mb-4">
                        <Skeleton width={200} height={15} highlightColor='#fbfbfb' baseColor='#e9e9e9'/>
                        </div>
                        <Skeleton count={2} height={10} highlightColor='#fbfbfb' baseColor='#e9e9e9' className='w-full'/>
                    </>
                    :
                    <>
                        {/* Expérience */}
            { 
                item?.candidate_profile?.experiences?.lenght && 
                <div className="flex-col text-start">
                    <h4 className=' font-semibold  mb-4'>Expérience</h4>
                    {
                        item?.candidate_profile?.experiences?.map((experience, index)=>{
                            return(
                                <div className="flex gap-4 items-start w-full mb-4">
                                <img src={bookmaker} alt="entreprise" className='w-12 h-12 rounded-full bg-gray-50 border' />
                                <div className='w-full'>
                                    <div className="flex justify-between items-end mb-2">                            
                                        <div className="text-start flex-col">
                                            <h6>{experience?.title} . {experience?.title}</h6>
                                            <div className="inline-block gap-2 text-sm ">
                                            {experience?.company} . {experience?.address}
                                            </div>
                                        </div>
                                        <p className='text-sm'>{experience?.start_month} {experience?.start_year} - {experience?.start_month} {experience?.end_year}</p>
                                    </div>
                                    <p className=' text-primary-text'>{experience?.description}</p>
                                </div>
                                </div>   
                            )
                        })
                    }
                
                </div>
            }

                {/* Education */}
                { 
                    item?.candidate_profile?.educations?.lenght && 
                    <div className="flex-col text-start">
                        <h4 className=' font-semibold  mb-4'>Education</h4>
                        {
                            item?.candidate_profile?.educations?.map((item)=>{
                                return(
                                    <div className="flex gap-4 items-start w-full mb-4">
                                    <img src={bookmaker} alt="entreprise" className='w-12 h-12 rounded-full bg-gray-50 border' />
                                    <div className='w-full'>
                                        <div className="flex justify-between items-end mb-2">                            <div className="text-start flex-col">
                                                <h6>Title</h6>
                                                <div className="inline-block gap-2 text-sm ">
                                                    London . Dribbble
                                                </div>
                                            </div>
                                            <p className='text-sm'>2018-2019</p>
                                        </div>
                                        <p className=' text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, quam vel cumque quasi officiis voluptates debitis praesentium, inventore architecto quo labore quod!</p>
                                    </div>
                                    </div>   
                                )
                            })
                        }
                    
                    </div>
                }

                {/* Languages */}
                { 
                    item?.candidate_profile?.languages?.lenght && 
                    <div className="flex-col text-start">
                        <h4 className=' font-semibold  mb-4'>Langues</h4>
                        <div className=" flex items-center gap-2 flex-wrap">
                        {
                            item?.candidate_profile?.languages?.map((language, index)=>{
                                return(
                                    <div className="py-2 pl-4 pr-3.5 text-primary-text flex gap-4 items-center border rounded-sm">
                                        <div className="w-8 h-8 bg-primary-black flex items-center justify-center text-white rounded-full">
                                            AN
                                        </div>
                                        <div className="flex flex-col gap-y-1 text-primary-black">
                                            <p className='text-start font-semibold'>Anglais</p>
                                            <p>Intérmédiaire</p>
                                        </div>
                                    </div>
                                )}
                            )
                        }

                        </div>
                    </div>
                }
                    </>
                }
                </div>
            </motion.div>
        </motion.div>
)}
</AnimatePresence>

<SendEmailModal isOpen={showSendEmailModal} onClose={setShowSendEmailModal}/>
</>
  )
}

export default FindTalentCard