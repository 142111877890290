import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import bookmaker from '../../../assets/icons/bookmaker-svgrepo-com.svg'
import next from '../../../assets/icons/back-svgrepo-com.svg'
import SaveIcon from "../../../components/SaveIcon"
import back2 from '../../../assets/FormationImages/pexels-shkrabaanthony-5475750.jpg'
import Dropdown from '../../components/Dropdown'


const TrainingCard = ({item}) => {
  const  navigate = useNavigate()
    
  return (
    <div 
    className='bg-white rounded-lg overflow-hidden border p-4 transition-all ease-in hover:border-gray-400 cursor-pointer flex flex-col justify-between'>
        <div className="flex flex-col mb-2 space-y-2">
        <div className="flex items-start justify-between">
            <div 
            onClick={()=>navigate("/offre/"+item.id)} 
            className=' flex flex-row gap-3 mb-3'>
              <div>
                <p className='text-level-1 flex-col justify-between text-start'>
                  {/* <div className='text-lg font-semibold'>{item.job_title}</div> */}
                  <div className='font-semibold'>{item.organisation.name}</div>
                  <div className='text-sm text-primary-text'>Ampasapito Antananarivo</div>
                </p>
              </div>
            </div>
            <Dropdown/>
        </div>
        <div 
          onClick={()=>navigate("/offre/"+item.id)} 
          className="flex gap-x-2 text-primary-text">
          <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">
            {item.work_type}
          </div>
          <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">
            {item.work_time}
          </div>
        </div>
               
        </div>  

        <div className='space-y-3'>
          <img src={back2} alt="image" className='h-56 min-[1500px]:h-48  rounded-md w-full'/>  
          <div className='flex justify-between items-center text-sm gap-2'>
                  <p className=' gap-2 flex text-sm text-primary-black-300'>
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z" stroke="#5E6670" stroke-width="1.5" stroke-miterlimit="10"/>
                    <path d="M14.5703 5.43076C15.2408 5.24184 15.9441 5.1988 16.6326 5.30454C17.3212 5.41029 17.9791 5.66236 18.562 6.04377C19.1449 6.42519 19.6393 6.92709 20.012 7.51568C20.3846 8.10427 20.6268 8.76588 20.7221 9.45594C20.8175 10.146 20.764 10.8485 20.565 11.5161C20.366 12.1837 20.0263 12.8009 19.5687 13.3262C19.1111 13.8514 18.5463 14.2726 17.9123 14.5611C17.2782 14.8497 16.5897 14.9991 15.8931 14.9992" stroke="#5E6670" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054" stroke="#5E6670" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.8926 15C17.2166 14.999 18.5213 15.3171 19.6962 15.9273C20.8712 16.5375 21.8819 17.4218 22.6426 18.5054" stroke="#5E6670" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div className='text-start whitespace-nowrap'>12 Candidats</div></p>
                  <div className='text-sm text-end'>Publié le  10/02/2024</div>
                </div>
        </div>
    </div>
  )
}


export default TrainingCard