import React, {useState} from 'react'
import bookmaker from '../../assets/icons/bookmark.svg'
import bookmakerfill from '../../assets/icons/bookmark-fill.svg'
import Loading from '../Loading/Loading'
import { FaMarker } from 'react-icons/fa'
import { LuBookMarked } from 'react-icons/lu'

const SaveIcon = ({OfferId, value}) => {
const [loading, setloading] = useState(false)
const [isSaved, setIsSaved] = useState(false)
const [show, setshow] = useState(true)

const save =()=>{
    setloading(true)
    setshow(false)
    setTimeout(() => {
        setloading(false)
        setshow(true)
        setIsSaved(true)
    }, 1000);
}
  return (
    <button
    onClick={()=>save()}
    className='hover:bg-neutral-100 p-1 rounded-full'>

        {!isSaved && show &&
        <img onClick={()=>save()} src={bookmaker} alt="logo"  className='w-6 h-6'/>
        }
        {isSaved && show &&
 <img onClick={()=>save()} src={bookmakerfill} alt="logo"  className='w-6 h-6'/>       
        }
       
        
        {loading && <Loading width={5}/>}
        

    </button>
  )
}

export default SaveIcon