"use client";

import React, { useEffect } from 'react'
import Login from './app/auth/Login'
import Register from './app/auth/register'
import { Route, BrowserRouter, Routes, useLocation } from 'react-router-dom'
import WebsiteLayout from './app/plateform/layout/WebsiteLayout'
import Formation from './app/plateform/pages/Formation'
import FindJob from './app/plateform/pages/FindJob'
import FindTalent from './app/plateform/pages/FindTalent'
import Home from './app/plateform/pages/Home'
import JobDetail from './app/plateform/pages/FindJob/JobDetail'
import FindTrainers from './app/plateform/pages/FindTrainers'
import FindRecruiter from './app/plateform/pages/FindRecruiter'
import FormationDetail from './app/plateform/pages/Formation/FormationDetail'
import Request from './app/plateform/pages/Request'
import RecruiterDetail from './app/plateform/pages/FindRecruiter/RecruiterDetail';
import TrainerDetail from './app/plateform/pages/FindTrainers/TrainerDetail';
import TermesAndConditions from './app/plateform/pages/TermesAndConditions';
import Help from './app/plateform/pages/Help'
import Plans from './app/plateform/pages/Plans'

import CandidateLayout from './app/plateform/candidate/Layout';
import CandidatHome from "./app/plateform/candidate/pages/Home"
import ForgotPassword from "./app/auth/ForgotPassword"
import Applications from './app/plateform/candidate/pages/Applications'
import Resume from './app/plateform/candidate/pages/Resume'
import Parameter from './app/plateform/candidate/pages/Parameter';
import Enrollments from "./app/plateform/candidate/pages/Enrollments"

// recruiter routes
import EditOffer from "./app/plateform/recruiter/pages/Recruitment/Edit"
import RecruitementDetail from "./app/plateform/recruiter/pages/Recruitment/Enrollments"
import RecruiterLayout from './app/plateform/recruiter/Layout';
import RecruiterHome from './app/plateform/recruiter/pages/Home';
import Recruitment from './app/plateform/recruiter/pages/Recruitment'
// import RecruiterApplication from './app/plateform/recruiter/pages/Applications';
// import Application from './app/plateform/recruiter/pages/Offer/Application'
// import OfferDetail from './app/plateform/recruiter/pages/Offer/OfferDetail'
import PostJob from './app/plateform/recruiter/pages/PostJob'
import RecruiterParameter from "./app/plateform/recruiter/pages/Parameter"
import RecruiterCandidate from './app/plateform/recruiter/pages/Offer/Candidate';
import SavedCandidates from './app/plateform/recruiter/pages/SavedCandidates'
import Abonnement from './app/plateform/recruiter/pages/Abonnement';

import PageNotFound from "./app/plateform/pages/PageNotFound"
import ContactUs from "./app/plateform/pages/ContactUs"
import TrainerLayout from "./app/plateform/trainer/Layout"
import TrainerHome from "./app/plateform/trainer/pages/Home"
import PostTraining from './app/plateform/trainer/pages/PostTraining';
import Trainings from "./app/plateform/trainer/pages/Trainings"
import TrainerParameter from "./app/plateform/trainer/pages/Parameter"
import Students from './app/plateform/trainer/pages/Students';
import AboutTraining from './app/plateform/trainer/pages/Trainings/Enrollments/partials/About';
import Enrollement from './app/plateform/trainer/pages/Trainings/Enrollments/partials/Enrollement';

import TrainerEnrollements from './app/plateform/trainer/pages/Trainings/Enrollments'
import EditTraining from "./app/plateform/trainer/pages/Trainings/Edit"
import TestResume from './app/plateform/pages/TestResume';



 const Routers = () => {
  // const  {pathname} = useLocation()

  // useEffect(() => {
  //   window.scrollTo(0, 0)
 
  // }, [pathname])

  return (
    <BrowserRouter>
    <Routes>
    
        <Route path='/' element={<WebsiteLayout/>} >
            <Route index element={<Home/>}/>
            {/* <Route path='home' element={<AdminHome/>}/> */}
            <Route path="/offre" element={<FindJob/>} />
            <Route path='/offre/:id' element={<JobDetail/>}/>

            <Route path="/formation" element={<Formation/>} />
            <Route path="/formation/:id" element={<FormationDetail/>} />
 
            <Route path='/candidat' element={<FindTalent/>}/>
            {/* <Route path='/candidat/:id' element={<CandidateDetail/>}/> */}

            <Route path='/recruteur' element={<FindRecruiter/>}/>
            <Route path='/recruteur/:id' element={<RecruiterDetail/>}/>

            <Route path='/formateur' element={<FindTrainers/>}/>
            <Route path='/formateur/:id' element={<TrainerDetail/>}/>
            
            <Route path='/requete' element={<Request/>}/>
            <Route path='/requete/:id' element={<Request/>}/>

            <Route path='/termes-et-conditions' element={<TermesAndConditions/>}></Route>
             <Route path='/aide' element={<Help/>}></Route>
             <Route path='plans' element={<Plans/>}></Route>
             <Route path="contact" element={<ContactUs/>} />
             <Route path="*" element={<PageNotFound/>} />
        </Route>

        {/* <Route path='/candidat' element={<CandidateLayout/>} >
          <Route index element={<CandidatHome/>}/>
          <Route path='candidature' element={<Applications/>}/>
          <Route path='cv' element={<Resume/>}/>
          <Route path='enregistrement' element={<Enrollments/>}></Route>
          <Route path='parametre' element={<Parameter/>}></Route>
        </Route> */}

        <Route path='/mira-lenta' element={<CandidateLayout/>} >
          <Route index element={<CandidatHome/>} />
          <Route path='candidature' element={<Applications/>} />
          <Route path='cv' element={<Resume/>} />
          <Route path='enregistrement' element={<Enrollments/>} />
          <Route path='parametre' element={<Parameter/>} />
        </Route>
        
        <Route path='/mira-recruteur' element={<RecruiterLayout/>} >
          <Route index element={<RecruiterHome/>}/>
          <Route path='offre' element={<Recruitment/>}/>
          {/* <Route path='offre/:id' element={<OfferDetail/>}/>
          <Route path='offre/:id/modif' element={<Recruitment/>}/>
          <Route path='offre/:id/application' element={<RecruiterCandidate/>}/> */}

{/* <Route path='offre/:id' element={<OfferDetail/>}/> */}
          <Route path='offre/:id/modifier' element={<EditOffer/>}/>
          <Route path='offre/:id/candidature/:opt' element={<RecruitementDetail/>}/>
          
          <Route path='abonnement' element={<Abonnement/>}/>
          <Route path='offre/nouveau' element={<PostJob/>}/>
          <Route path='candidat' element={<SavedCandidates/>}></Route>
          <Route path='parametre' element={<RecruiterParameter/>}></Route>
        </Route>

        <Route path='/mira-formateur' element={<TrainerLayout/>}>
          <Route index element={<TrainerHome/>}/>
          <Route path='formation' element={<Trainings/>}/>
          <Route path='etudiant' element={<Students/>}/>
          <Route path='formation/:id/modifier' element={<EditTraining/>}/>
          <Route path='abonnement' element={<Abonnement/>}/>
          <Route path='formation/nouveau' element={<PostTraining/>}/>
          <Route path='formation/:id/inscription/:opt' element={<TrainerEnrollements/>}/>
          <Route path='formation/:id' element={<AboutTraining/>}/>
          <Route path='formation/:id/inscription' element={<Enrollement/>}/>
          <Route path='parametre' element={<TrainerParameter/>}/>
          {/* AboutTraining Enrollement EditTraining*/}
        </Route>

        {/* <Route path='/admin' element={<AdminLayout/>} > 
            <Route index element={<Login/>} />
            <Route path='home' element={<AdminHome/>}/>
        </Route> */}
        <Route path="/login" element={<Login/>} />
        <Route path="*" element={<PageNotFound/>} />
        <Route path="/forgotPassword" element={<ForgotPassword/>} />
        <Route path="/register" element={<Register/>} />  
        <Route path='test' element={<TestResume></TestResume>}></Route>
    </Routes>
  </BrowserRouter>
  )
}

export default Routers
