import React, { useRef, useState } from "react"
// import HomeIcon from "../../assets/icons/home.svg"
// import HomeActiveIcon from "../../assets/icons/case-svgrepo-com.svg";
// import NetworkIcon from "../../assets/icons/network.svg";
// import JobsIcon from "../../assets/icons/case-svgrepo-com.svg";
// import NotificationIcon from "../../assets/icons/notification.svg";
// import PostIcon from "../../assets/icons/post.svg";
import logoblack from "../../assets/logo/Fichier 1@2x.png"
import { Link, useLocation, useNavigate } from "react-router-dom"
import './Header.css'
import userPic from "../../assets/users/bonnie-green-2x.png"
// import message from "../../assets/icons/chat-line-square-svgrepo-com.svg"
// import search from "../../assets/icons/search-svgrepo-com.svg"
import Dropdown from "../../candidate/components/Dropdown"
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import { BiMenu } from "react-icons/bi"
import { motion, AnimatePresence } from 'framer-motion'
import { CgClose } from 'react-icons/cg'


const Header = () => {
  // const { user } = useSelector((state) => state.auth);
  const [isPosting, setIsPosting] = useState(false);
  const [toggleMe, setToggleMe] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const containerRef = useRef(null)
  const sidebarRef = useRef(null)
  const location = useLocation()
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const navigate = useNavigate()
  const [showSidebar, setshowSidebar] = useState(false)

  const menuCandidate = [
    { name: 'Accueil', path: '/' },
    { name: 'Offre', path: '/offre' },
    { name: 'Des Entreprise Qui Recrutent', path: '/recruteur' },
    { name: 'Formation', path: '/formation' },
    { name: 'Centre De Formation', path: '/formateur' },
    { name: 'Trouver Du Talent', path: '/candidat' },
    { name: 'Besoin', path: '/requete' },
    { name: 'Nous Contacter', path: '/contact' },
  ]

  // "Accueil","Offre","Des entrprise qui rechutent","Formation","Centre de formation", "Trouver du talent", "Besoin","A propos"

  // useClickAway(containerRef, () => {
  //   setToggleMe(false);
  // });

  // useClickAway(sidebarRef, () => {
  //   setToggleSidebar(false);
  // });

  if (isPosting || toggleSidebar) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const isActive = (path) => {
    if (path === location.pathname) {
      return true;
    }
    return false;
  }

  return (
    <div className="bg-white/80 backdrop-blur-xl backdrop-filter shadow fixed top-0 w-full z-10">
      
      {/* Desktop Header */}
      <div className="flex justify-between items-center h-14 w-full max-w-[1920px] px-6 lg:px-20 xl:px-40 ">
        <div className="flex gap-3 items-center">
          <button 
          onClick={()=>setshowSidebar(true)}
          className="border-[1.5px] border-primary-black p-1 bg rounded-md block md:hidden">
            <BiMenu className="text-2xl"/>    
          </button>
          <img onClick={()=>navigate("/")} src={logoblack} alt="Mira" className="w-[72px] md:w-20 cursor-pointer" />
        </div>

        {/* Navbar menu */}
        <div className=" w-[570px] h-full hidden md:flex">
          
          <Link to="/" className="flex-1">
          <div className={`link-item text-gray-600`}>
            <h3 className="text-sm font-normal mt-[2px]">Accueil</h3>
          </div>
          </Link>
         
          <button className="flex-1">
            <div className="link-item">
            <Dropdown title="Opportunité" position="left" width={"250px"} navLink={[[ "/offre","Offre"],[ "/recruteur","Des entreprise qui recrutent"],[ "/formation","Formation"], [ "/formateur","Ecole/Centre de formation"]]}/>
            </div>
          </button>

          <button className="flex-1 items-center">
            <div className="link-item">
              <Dropdown title="Talent" position="left"  width={"250px"} navLink={[[ "/candidat","Candidat"], [ "/requete","Besoin"]]}/>
            </div>
          </button>
          
          <Link to="/plans" className="flex-1">
          <div className={`link-item text-gray-600`}>
            <h3 className="text-sm font-normal mt-[2px]">Tarifs</h3>
          </div>
          </Link>

          <Link to="/contact" className="flex-1 items-center">
            <div className="link-item">
              <h3 className="text-sm font-normal mt-[2px]">Contact</h3>
            </div>
          </Link>
          
        </div>

        <div className="flex gap-x-2 items-center h-full">
         
          <svg aria-hidden="true" className="w-6 h-6 text-gray-900 dark:text-gray-400 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          <div
            className="flex gap-2 items-center justify-center w-[32px]"
            onClick={() => setToggleMe(!toggleMe)}
            ref={containerRef}
          >
            <img src={userPic} className='w-[32px] rounded-full h-[32px]'  alt="user icon" />
           
            {toggleMe && (
              <div className="absolute top-16 right-20 bg-white rounded-lg shadow border z-10 whitespace-nowrap">
                <Link to={`/profile`}>
                  <div className="text-sm cursor-pointer hover:bg-gray-100 py-2 px-6 text-slate-700 flex gap-2 items-center">
                  Mon profil
                  </div>
                </Link>
                <hr />
                <Link to={`/candidat/cv`}>
                  <div className="text-sm cursor-pointer hover:bg-gray-100 py-2 px-6 text-slate-700 flex gap-2 items-center">
                  Mon CV
                  </div>
                </Link>
                <Link to={`/candidat/candidature`}>
                  <div className="text-sm cursor-pointer hover:bg-gray-100 py-2 px-6 text-slate-700 flex gap-2 items-center">
                  Candidature
                  </div>
                </Link>
                <Link to={`/profile`}>
                  <div className="text-sm cursor-pointer hover:bg-gray-100 py-2 px-6 text-slate-700 flex gap-2 items-center">
                  Mes enregistrements
                  </div>
                </Link>
                <hr/>
                <button
                onClick={()=>setShowLogoutModal(true)}
                  className="w-full text-sm cursor-pointer hover:bg-gray-100 py-2 px-6 text-slate-700 flex gap-2 items-center"
                >
                  Se déconnecter
                </button>
              </div>
            )}
          </div>
        </div>

      </div>

      <div
        className={`absolute top-0 left-0 bottom-0 right-0 h-screen w-full z-30 flex md:hidden transition-transform duration-500 ${
          toggleSidebar ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div
          className="bg-white h-full w-[250px] flex flex-col justify-between"
          ref={sidebarRef}
        >
          <div className="p-6 border-b">
            {/* <Link
              to={`/profile/${user.uid}`}
              onClick={() => setToggleSidebar(false)}
            > */}
              {/* <Avatar src={user?.photoURL} sx={{ width: 65, height: 65 }} /> */}
              <h4 className="mt-3 mb-1 font-semibold text-xl text-gray-900">
                {/* {user.displayName} */}
              </h4>
              <p className="text-sm text-gray-500">View Profile</p>
            {/* </Link> */}
          </div>
          <div
            className="p-6 border-t flex items-center gap-2 text-gray-700"
            // onClick={() => signOut(auth)}
          >
             Sign Out
          </div>
        </div>
      </div>
      {toggleSidebar && (
        <div
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-screen z-20 bg-[rgba(0,0,0,0.6)]"
          onClick={() => setshowSidebar(false)}
        ></div>
      )}
            <ConfirmModal textTitle="Confirmation de déconnexion" textContent="Voulez-vous vous déconnecter?" setIsOpen={()=>setShowLogoutModal(false)} isOpen={showLogoutModal}></ConfirmModal>
    
            <AnimatePresence>
    {
        showSidebar && (
    
        <motion.div 
          initial={{ opacity: 0  }}
          animate={{opacity: 1}}
          exit={{ opacity: 0 }}
          onClick={()=>setshowSidebar(false)}
        className="fixed ease-in duration-200 z-40 h-screen bg-gray-900/10 top-0 bottom-0 left-0 right-0 flex justify-end backdrop-blur-sm backdrop-filter">
        <motion.div
          // Animation pour l'apparition
          initial={{ opacity: 0, left: "-100%", y: 20 }}
          animate={{ opacity: 1, left: 0, y: 0 }}
          exit={{ opacity: 0, left: "-100%", y: 20 }}
          transition={{ type: "spring", stiffness: 130, damping: 20 }}
          className="fixed px-6 overflow-y-auto bg-white w-full sm:w-80 min-h-full  drop-shadow-lg max-h-screen"
        >
        <div className="flex justify-end py-4">
            <button onClick={()=>setshowSidebar(false)}><CgClose/></button>
        </div>

        <img onClick={()=>navigate("/")} src={logoblack} alt="Mira" className="w-24 cursor-pointer mx-auto mb-4" />
        <hr />

        <ul className="text-start ">
          {menuCandidate.map((item, index)=>{
            return (
              <>
        <li key={index} className="my-2 px-4 rounded-md  py-2 cursor-pointer hover:bg-neutral-100">
          <Link to={item.path}>
          {item.name}
          </Link>
          </li>
               <hr className="border-neutral-100"/>   
              </>
              )
          })}
        </ul>
        </motion.div>  
        </motion.div> 
)} 
        </AnimatePresence>  
    </div>
  )
}

export default Header;
