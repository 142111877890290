import React, { useState } from 'react'
import ico from "../../plateform/assets/searchIcon/search.svg"
import { CgClose } from 'react-icons/cg'


const InputIcon = ({
  icon = ico,
  label, 
  type = 'text', 
  name, 
  value ="", 
  placeholder ="", 
  onChange, 
  required = false, 
  className = '',
  handleChange, 
  noBorder=false,
  cancel = false,
  searchValue= "",

  ...props
}) => {
  // const [val, setVal] = useState(value)
  return (
    <div className={className}>
      <div className="relative mt-1 col-span-2">
    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
      <img src={icon} alt="" />
    </div>
    <input
    {...props}
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={(e)=>{
        if (onChange) {
          onChange(e); // Vérifiez si onChange est défini avant de l'appeler
        }
      }}
      className={(!noBorder ? " border border-gray-300 focus:ring-blue-500 focus:border-blue-500 ":" border-none outline-none ")+"  text-gray-900 text-sm rounded-lg  block w-full ps-10 p-2.5 "}
      placeholder={placeholder}
      required
    />
{
value &&  <button 
  onClick={()=>onChange({ target: { name, value: "" } })}
  className="absolute end-0 flex items-center pe-3.5 inset-y-0 bg-white/70 rounded-full ">
  <CgClose/>
</button>

}
   
    </div>
    </div>
  )
}

export default InputIcon
