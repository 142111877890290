import React, {useState, useEffect} from 'react'
import {JobCategoryData} from '../../datas/TrainersData'
import { Link } from 'react-router-dom'
import TrainerCard from '../../components/Cards/TrainerCard.js'
import InputIcon from '../../../components/Form/InputIcon'
import Select from '../../../components/Form/Select'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import Checkbox from '../../../components/Form/Checkbox/index.jsx'
import SecteurEntreprise from "../../datas/SecteurEntreprise.json"
import search from "../../assets/searchIcon/search.svg"
import location from "../../assets/searchIcon/location.svg"
import category from "../../assets/searchIcon/category.svg"
import back11 from '../../assets/images/49eddec5-17f2-4a0c-b7ca-5ece838b0848.jpg'


const FindTrainers = () => {

    const [trainers, setJobs] = useState(JobCategoryData)
    useEffect(() => {
      setJobs(JobCategoryData)
  
    }, [])
    
    return (
      <div className=''>
        <div className="bg-purple-300 h-56 w-full p-responsive py-8"  style={{ backgroundImage: `url(${back11})`, objectFit: "cover" }}>
          <div className="flex justify-between mb-10">
          <h5 className='font-semibold text-lg'>Formateur</h5>
          <li className="flex gap-x-2 text-sm items-center">
            <ul className="text-gray-600"> <Link to="/home">Accueil</Link>  / </ul>
            <ul className="font-medium">Emploi</ul>
          </li>
          </div>

          <div className="bg-white rounded-lg shadow h-16 p-2">
        <div className="grid lg:grid-cols-7 sm:grid-cols-5 grid-cols-3">
          <InputIcon className='col-span-2' name="keyword" placeholder='Offre, poste, mot clé ...' noBorder={true} />
          <InputIcon className='col-span-2 hidden sm:block' icon={location} name="localisation" placeholder='Localisation' noBorder={true}/>
          <InputIcon className='col-span-2 hidden lg:block' icon={category} name="secteur" placeholder='Seléctionner un secteur' noBorder={true}/>
          <div className="grid  gap-x-2">
            <button className="p-4 py-3 bg-black rounded-md text-white font-medium">
              Rechercher
            </button>
          </div>
        </div>
      </div>
            
        </div>


                 {/* customise list display */}
          <div className="bg-white  px-6 lg:px-20 xl:px-40 h-16 shadow-sm  mb-6 py-3">
            <div className="flex justify-between items-center ">
              <Select name="period" options={["Récent","Populaire"]} mb='0'/>
              <div className="border-gray-200 border flex items-center gap-x-1 p-1 rounded-md ">
                <button className="flex justify-center items-center  bg-gray-100 w-8 h-8 rounded-sm text-primary-black">
                  <svg className='text-primary-black' width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                    <g opacity="0.5" clip-path="url(#clip0_98_22837)">
                    <path d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" stroke="#161616 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z" stroke="#161616 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z" stroke="#161616 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z" stroke="#161616 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_98_22837">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>

                </button>
                <button className="flex justify-center items-center w-8 h-8">
                  <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5" clip-path="url(#clip0_98_37017)">
                    <path d="M18 4.5H6C4.89543 4.5 4 5.39543 4 6.5V8.5C4 9.60457 4.89543 10.5 6 10.5H18C19.1046 10.5 20 9.60457 20 8.5V6.5C20 5.39543 19.1046 4.5 18 4.5Z" stroke="#515B6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 14.5H6C4.89543 14.5 4 15.3954 4 16.5V18.5C4 19.6046 4.89543 20.5 6 20.5H18C19.1046 20.5 20 19.6046 20 18.5V16.5C20 15.3954 19.1046 14.5 18 14.5Z" stroke="#515B6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_98_37017">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>
            </div>
          </div>   

        <div className="flex-1 p-responsive">
          <div className="flex gap-x-6">
     <div className="hidden md:block w-80">
      <div className="bg-white rounded-lg overflow-hidden border py-3 ">
      <p className='flex justify-between mx-6 pb-3 items-center '> 
        <span className='text-sm font-bold text-black'>Filtre</span> 
        <button className='btn-green px-4 py-2 text-xs'>Appliquer</button>
      </p>
      <hr />

<div className='  text-level-2  filter-job'>

  {/* Type de contrat */}
  <div className="border-b py-4 px-6">
    <div  className='text-start text-sm font-bold mb-2'>Catégorie</div>
    <div className='flex-col space-y-2'>
    {
    SecteurEntreprise.map((item, index) => (
      <Checkbox key={index} name={`secteur${index}`} value={item} label={item} />
    ))
    }    
    </div>  
  </div> 
</div>
      </div>
    </div>
    <div className="flex-1 ">
        <div className='grid grid-cols-1 lg:grid-cols-2  gap-6'>
        {trainers.map((item)=>{
            return( 
              <TrainerCard key={item.id} item={item} />
              )
             
            })}
            </div>
     
    </div>
    </div>
  </div>
      </div>
    )
  }
  
export default FindTrainers