import React, {useEffect, useState} from 'react'
import { CgArrowLeft } from 'react-icons/cg'
import icon1 from "../../../../../assets/jobIcons/icon1.svg"
import "react-quill/dist/quill.snow.css"
import axios from "axios"
import {Link, useParams} from "react-router-dom"
import "react-loading-skeleton/dist/skeleton.css"

const About = () => {
    const { id } = useParams(); // Récupérer l'id du job à partir de l'URL
    const [jobDetails, setJobDetails] = useState({})
    const mockJobData = {
      title: 'Développeur Web Full Stack',
      company: 'TechCorp Solutions',
      location: 'Paris, France',
      type: 'CDI',
      schedule: 'Temps plein',
      companyDescription: "TechCorp Solutions est une entreprise leader dans le développement d'applications web innovantes.",
      description: `
        Voici quelques points importants :
        - Développer des applications web robustes et scalables.
        - Collaborer avec les équipes UX/UI pour améliorer l'interface utilisateur.
        - Maintenir le code propre et bien documenté.
        - Participer à la revue de code et aux tests pour assurer la qualité du produit.
        - Gérer et résoudre les bugs signalés par les utilisateurs.
      `,
      missions: `
        Les missions et responsabilités incluent :
        - Concevoir et développer des fonctionnalités nouvelles pour l'application.
        - Assurer une haute performance des applications à travers des optimisations continues.
        - Participer aux réunions de planification pour comprendre et définir les besoins.
        - Intégrer des APIs tierces et gérer les bases de données.
        - Déployer les mises à jour sur les serveurs de production en respectant les standards de l'entreprise.
        - Collaborer activement avec les équipes de QA pour corriger les anomalies détectées.
        - Former et encadrer les nouveaux membres de l'équipe technique.
      `,
      avantages: `
        Les avantages du poste incluent :
        - Salaire compétitif avec des primes de performance.
        - Assurance santé complète pour vous et votre famille.
        - Programmes de formation continue et d'amélioration des compétences. 
        - Télétravail possible selon les conditions de l'entreprise.
        - Participation aux événements et séminaires de développement technologique.
        - Ambiance de travail agréable avec une équipe dynamique et innovante.
        - Espace de travail moderne avec toutes les commodités nécessaires.
      `,
      skills: ['JavaScript', 'React', 'Node.js', 'CSS', 'HTML'],
      details: [
        { icon: icon1, title: 'Salaire', label: '50 000 € - 60 000 € par an' },
        { icon: icon1, title: 'Expérience', label: '3+ années' },
        { icon: icon1, title: 'Niveau d\'études', label: 'Bac +5' },
      ],
    }
    const jobDetail = [
      {
        icon:icon1,
        title: "Type d'offre",
        label: "CDI",
      },
      {
        icon:icon1,
        title: "Méthode de travail",
        label: "Local",
      },
      {
        icon:icon1,
        title: "Type d'offre",
        label: "CDI",
      },
      {
        icon:icon1,
        title: "Type d'offre",
        label: "CDI",
      },
      {
        icon:icon1,
        title: "Type d'offre",
        label: "CDI",
      }
    ]

    const fetchJobDetails = async () => {
        try {
          const response = await axios.get(`https://api.example.com/jobs/${id}`); // Remplacer par l'URL de votre API
          setJobDetails(response.data);
        } catch (error) {
          console.error('Erreur lors de la récupération des détails du job:', error);
        }
      };
    
      useEffect(() => {
        // Simuler la récupération des données du job
        setJobDetails(mockJobData);
      }, [])

  return (
    <div className=''>
        <div className=" flex justify-between  gap-10 text-sm">
      
       {/* Scrollable Content */}
       <div className="w-2/3 leading-loose text-justify text-black space-y-6">

      {/* A propos de d'entreprise */}
      <div>
         <h4 className=" font-bold mb-2">
           A propos de l'entreprise
         </h4>
         <p className="text-primary-text">{jobDetails.companyDescription}</p>
       </div>

       <div>
         <h4 className=" font-bold mb-2">
           Description
         </h4>
         <p className='text-primary-text'>
           {jobDetails.description}
         </p>
       </div>

       <div>
         <h4 className=" font-bold mb-2">
           Compétences
         </h4>
         <div className="flex gap-4">
           <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
             Javascript
           </div>
           <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
             Javascript
           </div>
           <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
             Javascript
           </div>
           <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
             Javascript
           </div>
         </div>
       </div>

       <div>
         <h4 className=" font-bold mb-2">
           Missions et Responsabilités
         </h4>
         <p className='text-primary-text'>
          {jobDetails.missions}
          </p>
       </div>

       <div>
         <h4 className=" font-bold mb-2">
           Avantages
         </h4>
         <p className='text-primary-text'>
           {jobDetails.avantages}
         </p>
       </div>


      </div>

      {/* Sticky Box */}
       <div className="w-1/3 h-fit   border border-gray-300 rounded-lg sticky top-20">
       <h5 className=' font-semibold py-4 text-start px-6'>Information générale</h5>
       {jobDetail.map((item)=>{
         return(
         <div className="border-b flex gap-4 items-center py-4 mx-6">
           <img src={item.icon} alt="..." />
           <div className="flex justify-between flex-col text-start">
             <div className='text-sm text-primary-text'>{item.title}</div>
             <div className='font-semibold'>{item.label}</div>
           </div>
         </div>  
         )
         })}  
           </div>
     </div>

    </div>
  )
}

export default About