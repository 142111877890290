import React from 'react'
import FAQDropdown from '../../../../components/Dropdown/FAQDropdown'

const FAQSection = () => {


  const FAQDatas = [
    {
      title: "Comment puis-je m'inscrire à un cours ?",
      message:
        "Pour vous inscrire à un cours, rendez-vous sur la page des cours, choisissez celui qui vous intéresse, puis cliquez sur le bouton 'S'inscrire'. Vous serez guidé à travers le processus d'inscription.",
    },
    {
      title: "Comment puis-je postuler à une offre d'emploi ?",
      message:
        "Pour postuler à une offre d'emploi, créez un compte et complétez votre profil. Ensuite, trouvez l'offre qui vous intéresse et cliquez sur 'Postuler'. Suivez les instructions pour soumettre votre candidature.",
    },
    {
      title: "Y a-t-il des frais pour utiliser la plateforme ?",
      message:
        "L'inscription et la recherche de cours ou d'offres d'emploi sont gratuites. Cependant, certains cours peuvent être payants ou offrir des certifications moyennant des frais. Les détails sont indiqués sur la page du cours.",
    },
    {
      title: "Comment puis-je annuler mon inscription à un cours ?",
      message:
        "Pour annuler votre inscription, accédez à votre tableau de bord, sélectionnez le cours concerné et choisissez l'option 'Annuler l'inscription'. Notez que des frais d'annulation peuvent s'appliquer selon la politique du cours.",
    },
    {
      title: "Comment puis-je contacter le support client ?",
      message:
        "Si vous avez des questions ou besoin d'assistance, vous pouvez contacter notre support client via l'onglet 'Contactez-nous' sur notre site ou par email à support@votresite.com. Nous sommes là pour vous aider.",
    },
    // {
    //   title: "Quels types de cours proposez-vous ?",
    //   message:
    //     "Nous proposons une large gamme de cours allant des compétences techniques (programmation, data science) aux soft skills (leadership, communication). Consultez notre catalogue pour découvrir toutes nos offres.",
    // },
    // {
    //   title: "Puis-je obtenir un certificat après avoir terminé un cours ?",
    //   message:
    //     "Oui, la plupart de nos cours offrent un certificat de réussite que vous pouvez ajouter à votre CV ou profil LinkedIn. Les détails sont disponibles sur la page de chaque cours.",
    // },
  ]
  
  
  return (
    <div className='w-full py-20 px-6 lg:px-20 xl:px-40  grid grid-cols-1 lg:grid-cols-2 gap-y-6'>
        <div>
          <div className="flex items-center gap-x-2 mb-4">
            <div className="relative bg-primary-green shadow-md min-w-10 h-10 rounded-full flex items-center justify-center text-2xl text-emerald-700">?</div>
            <h2 className='text-4xl font-semibold'>Des question courantes</h2>
          </div>
            <p className=' text-primary-text text-start'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis illo doloribus mollitia consectetur, et labore libero voluptate eveniet.
            </p>
        </div>
        <div className="flex-col">
          {FAQDatas.map((item, index)=>{
            return(
          <FAQDropdown key={index} className="mb-4 " question={item.title}>
            {item.message}
          </FAQDropdown>
            )
          })}
        </div>
    </div>
  )
}

export default FAQSection