import React from 'react'
import img404 from "../../assets/images/404.png"
import ButtonBlack from '../../../components/Buttons/ButtonBlack'
import ButtonHoverAnimated from '../../../components/Buttons/ButtonHoverAnimated'


const index = () => {
  return (
    <div>
        <div className=" mx-auto my-14 lg:my-28 flex  lg:flex-row  gap-6 lg:px-20 px-6 xl:px-40 flex-col-reverse   ">
            <div className=' place-center w-1/2 w-full  '>
                <div className='space-y-10 '>
           <h1 className='font-bold text-2xl lg:text-4xl'>Aucune page trouvée</h1>
                <p className='text-primary-text text-base lg:text-lg'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum quod beatae, dicta recusandae quasi aperiam cupiditate ipsam, labore aliquam, laboriosam nemo assumenda harum vel. Quo iusto iste repudiandae sit eos.</p>
            <ButtonBlack DefaultClassName={"py-3 px-6 rounded-sm mr-6"}>Aller à la page d'acceuil</ButtonBlack>
            <ButtonHoverAnimated className='border border-black px-6 py-2 rounded-sm text-base'>Retour</ButtonHoverAnimated>
                     
                </div>
                </div>
                <div className="lg:w-1/2 w-full flex items-center justify-center">
            <img src={img404} alt="404 not found" className='w-[400px] lg:min-w-[600px]'/>
                </div>
        </div>
    </div>
  )
}

export default index