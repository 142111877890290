import React from "react";
// import Logo from "./Logo";
// import loader from '../../../assets/icons/puff.svg'

const Loading = ({width=12}) => {
  return (
    <div class={"loader animate-spin ease-linear rounded-full border-[3px] border-t-[3px] border-t-neutral-500 border-gray-100 h-"+width+" w-"+width}></div>
  )
}

export default Loading
