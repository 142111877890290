import React, { useEffect, useState } from 'react'
import { CgArrowLeft } from 'react-icons/cg'
import { GoLocation } from 'react-icons/go'
import { Link, useParams } from 'react-router-dom'
import About from './partials/About'
import Enrollement from './partials/Enrollement'
import { GrEdit } from 'react-icons/gr'


const Enrollments = () => {
  const {opt} = useParams()
  const [option, setOption] = useState(opt?.toString() || "1")

  // useEffect(() => {
  //   setOption(opt?.toString())

  // })
  
  return (
    <div className='layout-1'>
          <Link to="/mira-formateur/formation" className="flex gap-2 items-center mb-4 text-primary-text text-sm">
            <CgArrowLeft/>
            <h1 className=''>Retour à la liste</h1>
          </Link>

          <h2 className='text-start mb-1 font-semibold text-xl flex items-start gap-x-2'> UI UX Designer 
            <div className='flex items-center gap-1 rounded-full border text-sm py-1 px-2 font-normal border-[#3700c3] bg-[#3700c3]/5 text-[#3700c3]'>
            <span className='w-2.5 h-2.5 rounded-full text-[#3700c3] bg-[#3700c3]'>
            </span> En cours</div>
            <Link to="/mira-formateur/formation/1/modifier" className='hover:bg-neutral-100 rounded-full p-1.5'>
              <GrEdit/>
            </Link>
        </h2>

        <div className="flex gap-1 items-center">
         
          <GoLocation/> Antsahavola Antnanarivo
        </div>
        
        <div className="border rounded-md grid grid-cols-2 md:grid-cols-4 gap-4 text-start mt-4 py-4 px-6">
            <div>
                <p className='text-sm text-primary-text'>Type de cours</p>
                <p className='font-semibold text-sm'>Cours Normal</p>
            </div>
            <div>
                <p className='text-sm text-primary-text'>Méthode d'ensignement</p>
                <p className='font-semibold text-sm'>En ligne</p>
            </div>
            <div>
                <p className='text-sm text-primary-text'>Niveau</p>
                <p className='font-semibold text-sm'>Débutant</p>
            </div>
            <div>
                <p className='text-sm text-primary-text'>Certificat</p>
                <p className='font-semibold text-sm'>Oui</p>
            </div>
        </div>

        <div className="grid grid-cols-1  gap-4 text-sm">
            <div className="col-span-4 ">
                <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
                    <button onClick={()=>setOption("1")} className={" px-6 py-2 border-b-2 hover:text-primary-black " + (option === "2" ? "border-b-transparent text-primary-text font-normal" : "border-b-gray-950 text-primary-black font-bold")}>Description</button>
                    <button onClick={()=>setOption("2")} className={" px-6 py-2 border-b-2 hover:text-primary-black " + (option === "1" ? "border-b-transparent text-primary-text font-normal" : "border-b-gray-950 text-primary-black font-bold")}>Candidats</button>
                </div>
            </div>
        </div>

        {option === "1" && <About/>}
        {option === "2" && <Enrollement/>}

    </div>
  )
}

export default Enrollments