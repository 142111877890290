
import React, {useState} from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { motion, AnimatePresence } from 'framer-motion'

const FAQDropdown = ({ className, height = { height: 200 }, question, children, ...prop }) => {
    const [isOpen, setIsOpen] = useState(false)    
    return (
        <>
        <div {...prop} className={"border rounded-md "+className}>
            <div onClick={()=>setIsOpen(!isOpen)} className=" w-full cursor-pointer border-b flex justify-between items-center rounded-sm px-4 py-4 space-x-3">
                <span className="font-semibold">{question}</span>
                <FaAngleDown className={`w-5 h-5 text-xl transition-all duration-200 ${isOpen ? ' rotate-180' : ''}`} />
            </div>
            <AnimatePresence>
                {
                    isOpen && (
                        <motion.div
                            initial={{ height: "0px" }}
                            animate={{height: "150px"}}
                            exit={{ height: "0px"}}

                            className={`w-full text-start overflow-hidden px-4  `}>
<div className="h-4"></div>
                            {children}
                            <div className="h-4"></div>
                        </motion.div>
                    )
                }
            </AnimatePresence>
            </div>
        </>
    )
}

export default FAQDropdown