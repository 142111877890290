import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'


const RippleButton = ({ 
    disabled,
    onClick,
    path,
    children, 
    type="button",
    showArrow = true,
    className= "bg-primary-green text-green-900 font-semibold hover:bg-primary-green/80" }) => {

      const navigate = useNavigate()

  const handleClick = (e) => {
    e.preventDefault();

    const button = e.currentTarget;
    const overlay = document.createElement('span'); // Create ripple span
    overlay.classList.add('ripple-overlay'); // Add ripple effect class
    button.appendChild(overlay);

    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    overlay.style.left = `${x}px`;
    overlay.style.top = `${y}px`;

    setTimeout(() => {
      overlay.remove(); // Remove the span after animation
      navigate(path)
    }, 600); // Duration of animation
  };
if(type === "link"){
    return (
        <button
          className={`ripple-button ${className} flex items-center  text-white px-6 py-2 rounded-md m-2 text-xl font-medium shadow-lg overflow-hidden`}
          onClick={handleClick}
        >
          {children}
        </button>
      );  
}
  return (
    <Link
    to={path}
      className={`ripple-button ${className} flex gap-x-2 items-center justify-center px-6 py-2 rounded-sm m-2 text-sm text-emerald-800 font-medium shadow overflow-hidden`}
      onClick={handleClick}
    >
      {children}

      {showArrow && 
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 12H19" stroke="#005934" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 5L19 12L12 19" stroke="#005934" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      }
    </Link>
  );
};

export default RippleButton
