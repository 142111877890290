import React, {useState} from 'react'
import bookmaker from '../../../../assets/icons/bookmaker-svgrepo-com.svg'
import { HiPencil } from 'react-icons/hi'
import { CgClose } from 'react-icons/cg'
import Modal from '../../../../../components/Modal'
import Input from '../../../../../components/Form/Input'
import Select from '../../../../../components/Form/Select'

const PersonnalInfo = () => {
    const [showModal, setShowModal] = useState(false)
    
  return (
    <div className="flex-col text-start  border p-4 rounded-md">
                    <div className="flex justify-between items-center  mb-4">
                        <div className=' font-semibold'>Information personnel</div>
                        
                        <button  onClick={()=>setShowModal(true)} className="bg-neutral-50 border rounded-sm p-1">
                            <HiPencil className='w-5 h-5 text-neutral-500'/>
                        </button> 
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                        {/* Email */}
                        <div className="py-2 text-primary-text flex gap-4 items-center rounded-sm">
                            <div className="w-10 h-10 bg-neutral-50 border flex items-center justify-center text-white rounded-sm">
                            <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Email</p>
                                <p>johndoe@gmail.com</p>
                            </div>
                        </div>

                        {/* Téléphone */}
                        <div className="py-2 text-primary-text flex gap-4 items-center rounded-sm">
                            <div className="w-10 h-10 bg-neutral-50 border flex items-center justify-center text-white rounded-sm">
                            <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Anglais</p>
                                <p>Intérmédiaire</p>
                            </div>
                        </div>

                        {/* Date de naissance */}
                        <div className="py-2 text-primary-text flex gap-4 items-center rounded-sm">
                            <div className="w-10 h-10 bg-neutral-50 border flex items-center justify-center text-white rounded-sm">
                            <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Date de naissance</p>
                                <p>01 Janvier 2011</p>
                            </div>
                        </div>

                         {/* Sexe */}
                         <div className="py-2 text-primary-text flex gap-4 items-center rounded-sm">
                            <div className="w-10 h-10 bg-neutral-50 border flex items-center justify-center text-white rounded-sm">
                            <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Sexe</p>
                                <p>Féminin</p>
                            </div>
                        </div>

                    </div>

                    <hr className='my-4'/>

                    {/* Email */}
                    <div className="py-2 text-primary-text flex gap-4 items-center rounded-sm">
                            <div className="w-10 h-10 bg-neutral-50 border flex items-center justify-center text-white rounded-sm">
                            <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
                            </div>
                            <div className="flex flex-col gap-y-1 text-primary-black">
                                <p className='text-start font-semibold'>Poste actuel</p>
                                <p>Etudiant</p>
                            </div>
                        </div>

                    {/* Email */}
                    <div className="py-2 text-primary-text flex gap-4 items-center rounded-sm">
                        <div className="w-10 h-10 bg-neutral-50 border flex items-center justify-center text-white rounded-sm">
                        <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
                        </div>
                        <div className="flex flex-col gap-y-1 text-primary-black">
                            <p className='text-start font-semibold'>Email</p>
                            <p>johndoe@gmail.com</p>
                        </div>
                    </div>

                {/* Modal modifier  des information personnel */}
            <Modal  isOpen={showModal} onClose={()=>setShowModal(false)}>
                <div className="flex items-center justify-between py-2 px-4">
                    <p>Modifier les information personnels</p>
                    <button  onClick={()=>setShowModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
                    <CgClose/>
                    </button>
                </div>
                <hr className='mb-6'/>
                <div className="px-6 space-y-4 mb-4">
                    <Input label="Email"/>
                    <Input label="Date de naissance"/>
                    <Select label="Sexe" placeholder="Veuillez sélectionner..." options={["Féminin", "Masculin"]}/>
                    <Input label="Téléphone"/>
                    <Input label="Métier actuelle"/>
                    {/* <Input label="Email"/> */}
                    {/* <Select label='Langue' placeholder="Veuillez sélectionner" options={["Anglais", "Français", "Chinois", "Avancé", "Billingue", "Natale"]}/>
                    <Select label="Niveau" placeholder="Veuillez sélectionner" options={["Notion", "Débutant", "Intermédiaire", "Avancé", "Billingue", "Natale"]}></Select> */}
                </div>
                <div className="flex items-center justify-end p-6">
                    <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
                    Enregistrer
                    </button>
                </div>
            </Modal>  
                </div>
  )
}

export default PersonnalInfo