import React, {useEffect, useState} from 'react'
import ApplicationsData from "../../datas/Applications.json"
import RippleButton from '../../../../components/Buttons/RippleButton'
import { BiFilter } from 'react-icons/bi'
import ico from "../../../assets/searchIcon/search.svg"

const Applications = () => {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
      // Fetch data from JSON or API
    //   axios.get('../../datas/Applications.json')
    //     .then(response => {
    //       setTasks(response.data)
    //     })
    //     .catch(error => {
    //       console.error("Error fetching tasks data:", error);
    //     })
    setTasks(ApplicationsData)
    }, [])

  return (
    <div className='min-h-full w-full pr-6  xl:pr-40 pl-6 text-primary-black py-10'>
                {/* <div className="flex justify-between items-center mb-6"> */}
            <h1 className='font-semibold mb-6 text-start'>Candidature</h1>

            <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
                <button className="border-b-gray-950 px-6 py-2 border-b-2 font-bold hover text-primary-black">Tous</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">En attente</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">Entrentien</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">Réfusé</button>
                <button className="px-6 py-2  text-primary-text hover:text-primary-black">Embauché</button> 
            </div>

        <div className=" flex justify-between items-center">
          <h4 className='font-semibold whitespace-nowrap'>Historique des candidatures</h4>

          <div className="flex gap-2 ">
            {/* Search bar */}
            <div className="relative flex items-center w- h-10 rounded-lg   overflow-hidden border ">
              <div className="grid place-items-center h-full w-12 text-gray-300">
                  <img src={ico} alt="serachbtn" />
              </div>
              <input
              className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
              type="text"
              id="search"
              placeholder="Mot clé..." 
              // onChange={(e)=>setFormData({...formData, keyword: e.target.value})}
              /> 
            </div>

          {/* Filter button */}
          <button className="px-4 py-2 font-medium border text-[#24292F] hover:bg-primary-black hover:text-white rounded-md focus:outline-none text-sm flex gap-1 items-center justify-between">
            <BiFilter className='w-5 h-5'/>
            Filtrer
          </button>
          </div>

        </div>
        <div className="    overflow-auto  py-4  w-full">
        <div className=" ">
          {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati facere error eligendi modi, corrupti autem in minima explicabo neque libero nulla maxime voluptatum assumenda exercitationem asperiores rem, quo inventore repudiandae? */}
          <table className="w-full">
            <thead className='text-sm'>
              <tr className="text-left bg-neutral-100 py-4 rounded-sm">
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm ">Title</th>
            
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Date de candidature</th>
               
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Status</th>
                <th className="pl-5 bg-neutral-100 py-4 rounded-sm">Action</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(task => (
                <tr key={task.id} className=" border-b border-gray-100 rounded ">
                  <td className="pl-5 py-2 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                        <div className="min-w-14 h-14 bg-neutral-50 border border-slate-100 rounded-md flex items-center justify-center">
                        <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.93359V26.7116L15.2701 35.8927L15.6245 35.322L15.2701 18.0677L0.525037 8.94415L0 8.93359Z" fill="#449B82"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M30.3751 8.85156V26.8801L15.2702 35.8943V18.0692L29.8218 8.86512L30.3751 8.85156Z" fill="#9BDB9C"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1875 0.00390625L30.375 8.8512L15.2701 18.3663L0 8.93471L15.1875 0.00390625Z" fill="#56CDAD"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8159 7.09375L17.6792 10.1342V16.271L12.5325 13.1806L7.58368 16.11V28.9367L12.7204 25.7513V18.847L18.2067 22.3492L22.8159 19.491V7.09375Z" fill="white"/>
                        </svg>

                        </div>
                        <div className="flex flex-col justify-between">
                            
                      <p className="text-base font-medium mr-2">{task.title}</p>
                      <div className="flex gap-2 text-xs">
                        Aprivo France
                      </div>
                        </div>
                    </div>
                  </td>
                  <td className="pl-5">
                    <p className="text-sm leading-none  text-start">{task.dueDate}</p>
                  </td>
                  <td className="pl-5  justify-start">
                    <button className="py-3 px-3 text-sm text-[#0BA02C]  flex items-center gap-1"> 
                        <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-4 w-4 text-[#0BA02C]" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M5 12l5 5l10 -10"></path>
                        </svg>
                        {task.status}</button>
                  </td>
                  <td className="pl-5 whitespace-nowrap">
                  <RippleButton path={"candidature/"+task?.id}>Voir détail</RippleButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Applications