import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TRAINER_API_END_POINT } from '../utils/constant';

const trainerSlice = createSlice({
  name: "trainers",
  initialState: {
    trainers: [],
    loading: false,
    error: null,
    message: null,
    singleTraining: {},
    filterKeys: {
      keyword: '',
      location: '',
      category: '',
      duration: '',
      level: '',
      limit: 10
    },
    myTrainers: [],
  },
  reducers: {
    requestForAllTrainers(state, action) {
      state.loading = true;
      state.error = null;
    },
    successForAllTrainers(state, action) {
      state.loading = false;
      state.trainers = action.payload;
      state.error = null;
    },
    failureForAllTrainers(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    requestForSingleTraining(state, action) {
      state.message = null;
      state.error = null;
      state.loading = true;
    },
    successForSingleTraining(state, action) {
      state.loading = false;
      state.error = null;
      state.singleTraining = action.payload;
    },
    failureForSingleTraining(state, action) {
      state.singleTraining = state.singleTraining;
      state.error = action.payload;
      state.loading = false;
    },

    clearAllErrors(state, action) {
      state.error = null;
      state.trainers = state.trainers;
    },
    resetTrainingSlice(state, action) {
      state.error = null;
      state.trainers = state.trainers;
      state.loading = false;
      state.message = null;
      state.myTrainers = state.myTrainers;
      state.singleTraining = {};
    },
  },
});

// Fetch all trainers with filters
export const fetchTrainers = (filterKey) => async (dispatch) => {
  try {
    dispatch(trainerSlice.actions.requestForAllTrainers());
    let link = TRAINER_API_END_POINT + "/all?";
    let queryParams = [];

    if (filterKey?.keyword) queryParams.push(`keyword=${filterKey.keyword}`);
    if (filterKey?.location) queryParams.push(`location=${filterKey.location}`);
    if (filterKey?.category) queryParams.push(`category=${filterKey.category}`);
    if (filterKey?.limit) queryParams.push(`per_page=${filterKey.limit}`);

    link += queryParams.join("&");
    const response = await axios.get(link);
    dispatch(trainerSlice.actions.successForAllTrainers(response.data.data));
    dispatch(trainerSlice.actions.clearAllErrors());
  } catch (error) {
    dispatch(trainerSlice.actions.failureForAllTrainers(error.response?.data?.message));
  }
};

export default trainerSlice