import React from 'react'
import { Link } from 'react-router-dom'
import Input from '../../../../components/Form/Input'
import Select from '../../../../components/Form/Select'
import ButtonBlack from '../../../../components/Buttons/ButtonBlack'


const CreateCandidateAccount = ({setPage,page , data, setData}) => {
  return (
    <>
      <div className="w-full  p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow mt-20">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Créer un compte
          </h2>
          <form className="mt-8 space-y-2" action="#">

          <div className="grid grid-cols-2 gap-x-4">
            <Input label="Nom" name="nom"/>
            <Input label="Prénom" name="prenom"/>
          </div>

          <div className="grid grid-cols-2 gap-x-4">
            <Select label="Région" name="region"/>
            <Select label="District" name="district"/>
          </div>

            <Input label="Email" type='email' name="email" required/>

            <div className="grid grid-cols-2 gap-x-4">
                <Input label="Mot de passe" type='password' name="password"/>
                <Input label="Mot de passe de confirmation" type='password' name="password"/>
            </div>
            <p className='text-start font-medium text-gray-900  text-sm'>Veuiller utiliser un mot de passe avec minimum 8 caractères</p>
            <div className="flex items-start py-2">
                <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" name="remember" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" required/>
                </div>
                <div className="ml-3 text-sm">
                <label for="remember" className="font-medium text-gray-900 ">J'accepte les <Link to="termes-et-conditions" className="text-gray-950 cursor-pointer text-primary-700 hover:underline  font-semibold"> Termes et Conditions</Link>  d'utilisation</label>
                </div>
            </div>
            <ButtonBlack DefaultClassName='w-60 py-3 rounded-md'>Suivant</ButtonBlack>
            {/* <button type="submit" className="w-full px-5 py-3 text-base font-medium text-center bg-gray-400 text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-2 focus:ring-neutral-300 sm:w-auto">S'inscrire maintenant</button> */}
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Déjà un compte? <Link to="login" className="text-gray-950 cursor-pointer text-primary-700 hover:underline">Se connecter</Link>
            </div>
        </form>
      </div>
</>
  )
}

export default CreateCandidateAccount