import React from 'react'
import ToggleSwitch from '../../../components/ToogleSwitch'
import { Link } from 'react-router-dom'
import image2 from "../../assets/Illustrations/findintelescope.png"


const index = () => {

    const plans = [
        {
            name: "Basic",
            price: "Gratuit",
            features: [
              "Accès aux offres d'emploi basiques",
              "Création d'un CV simple",
              "Inscription aux formations gratuites",
              "Alertes personnalisées pour les nouvelles offres correspondant à votre profil"
            ],
            description: "Pour les candidats en début de carrière ou les formateurs qui débutent.",
            buttonLabel: "Commencer gratuitement"
          },
          {
            name: "Standard",
            price: "40.000 Ariary",
            features: [
              "Accès complet aux offres d'emploi",
              "Création de CV avancé avec des modèles personnalisés",
              "Participation à des formations en ligne certifiantes",
              "Suivi des candidatures en temps réel",
              "Accès aux conseils et guides de gestion de carrière"
            ],
            description: "Pour les professionnels en pleine évolution ou les formateurs expérimentés.",
            buttonLabel: "S'abonner"
          },
          {
            name: "Professional",
            price: "50.000 Ariary",
            features: [
              "Tout le contenu du plan Standard",
              "Coaching personnalisé de carrière",
              "Accès à des offres d'emploi exclusives",
              "Accès à des ateliers de formation premium",
              "Support prioritaire"
            ],
            description: "Pour les professionnels confirmés et formateurs chevronnés.",
            buttonLabel: "Passer au Professional"
          },
    ]

  return (
    <div>
    <div className='w-full px-[20px] xl:px-40 lg:px-20'>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 py-14">
      <div className="flex-col items-center flex space-y-10">
  <h1 className="text-5xl font-bold leading-normal text-start">
    Une solution complète pour Vous
  </h1>
  <p className='text-start'>
    Que vous soyez candidat en quête d'opportunités, recruteur à la recherche des meilleurs talents, entreprise souhaitant optimiser ses processus ou centre de formation voulant proposer ses services, notre plateforme vous offre tous les outils nécessaires pour atteindre vos objectifs.
  </p>
</div>

        <div className="flex space-y-6 flex-col items-end justify-between  w-full">
        <img src={image2} alt="image de requête" className='w-[250px]'/>
            <div className="flex items-center gap-x-2">
                Tarif Mensuel <ToggleSwitch/> Tarif Annuel
            </div>
        </div>  
        </div> 
        <hr />

        {/* pricing */}
        <div className="sm:flex sm:flex-col sm:align-center ">
            <div className="mt-12 space-y-3 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6  xl:grid-cols-3">
            {plans.map((plan, index) => (
                <SubscriptionPlan key={index} {...plan} />
            ))}
            </div>
        </div>
        </div> 
    </div>
  )
}

export default index

const SubscriptionPlan = ({ name, description, price, features, link }) => {
    return (
        <div className="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200">
            <div className="p-6">
                <h2 className="justify-center flex items-center gap-2 text-xl leading-6 font-bold text-slate-900"><div className="w-4 h-4 bg-primary-green relative rounded-full"></div>{name}</h2>
                <p className="mt-2 text-base text-[#363835] leading-tight">{description}</p>
                <p className="mt-8">
                    <span className="text-4xl font-bold text-slate-900 tracking-tighter">{price}</span>
                    <span className="text-base font-medium text-slate-500">/mois</span>
                </p>
                <Link to={link} className="mt-8 block w-full bg-neutral-900 rounded-md py-2 text-sm font-semibold text-white text-center">
                    Rejoindre {name}
                </Link>
            </div>
            <div className="pt-6 pb-8 px-6">
                <h3 className="text-sm font-bold text-slate-900 tracking-wide uppercase">Ce qui est inclus</h3>
                <ul className="mt-4 space-y-3">
                    {features.map((feature, index) => (
                        <li key={index} className="flex space-x-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5 text-neutral-900" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l5 5l10 -10"></path>
                            </svg>
                            <span className="text-base text-start text-neutral-700">{feature}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};