import React from 'react';
// import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Styles de base pour le skeleton
// import next from '../../assets/icons/back-svgrepo-com.svg';

const JobCardSkeleton = () => (
  <div className='bg-white rounded-lg overflow-hidden border py-6 px-6 transition-all ease-in hover:border-gray-400 cursor-pointer'>
    <div className="flex justify-between">
      <div className='flex flex-row gap-3 mb-3'>
        <div className=' flex items-center justify-center w-14 h-14'>
          <Skeleton circle={true} height={56} width={56} />
        </div>
        <div>
          <p className='text-level-1 flex-col justify-between col text-start'>
            <div className='text-lg font-semibold'>
              <Skeleton width={500} />
            </div>
            <span>
              <Skeleton width={500} />
            </span>
          </p>
        </div>
      </div>
      <Skeleton width={32} height={32} />
    </div>

    <div className="flex gap-x-2 text-primary-text">
      <Skeleton width={80} height={40} />
      <Skeleton width={80} height={40} />
      <Skeleton width={80} height={40} />
    </div>

    <div className='text-level-2'>
      {/* <ul className='flex gap-3 mb-2'>
        <li>
          <Skeleton width={120} />
        </li>
      </ul>
      <p className="mb-2 text-start">
        <Skeleton count={3} />
      </p> */}

      <div className='flex justify-between items-end'>
        <p className='gap-2 flex text-sm text-primary-black-300'>
          <Skeleton width={100} />
        </p>
        <Skeleton width={120} height={40} />
      </div>
    </div>
  </div>
);
export default JobCardSkeleton