import React from 'react';
import ButtonBlack from '../../../components/Buttons/ButtonBlack';
import InputRadio from '../../../components/Form/InputRadio';
import Checkbox from '../../../components/Form/Checkbox';
import SelectIcon from '../../../components/Form/SelectIcon';
import InputIcon from '../../../components/Form/InputIcon';
import Modal from '../../../components/Modal';
import location from "../../assets/searchIcon/location.svg"
import category from "../../assets/searchIcon/category.svg"

const AdvancedSearchModal = ({ showModal, setshowModal, filters, handleInputChange, handleSearch, handleCheckboxChange }) => {
  return (
    <Modal isOpen={showModal} onClose={() => setshowModal(false)}>
      {/* Header */}
      <div className="bg-white border-b p-2">
        <div className="grid grid-cols-1 xl:grid-cols-7">
          {/* Recherche par mot clé */}
          <InputIcon
            className="col-span-2"
            // icon="search"
            value={filters.keyword}
            name="keyword"
            placeholder="Offre, poste, mot clé ..."
            noBorder={true}
            onChange={handleInputChange}
          />
          {/* Localisation */}
          <InputIcon
            className="col-span-2"
            icon={location}
            value={filters.localisation}
            name="localisation"
            placeholder="Localisation"
            noBorder={true}
            onChange={handleInputChange}
          />
          {/* Sélection du secteur */}
          <SelectIcon
            options={[
              { value: 'category1', label: 'Catégorie 1' },
              { value: 'category2', label: 'Catégorie 2' },
              { value: 'category3', label: 'Catégorie 3' },
            ]}
            value={filters.secteur}
            className="col-span-2"
            icon={category}
            name="secteur"
            placeholder="Sélectionner un secteur"
            noBorder={true}
            onChange={handleInputChange}
          />
          {/* Bouton Rechercher */}
          <div className="grid gap-x-2">
            <button
              onClick={handleSearch}
              className="px-4 py-2 xl:py-3 bg-black rounded-md text-white"
            >
              Rechercher
            </button>
          </div>
        </div>
      </div>

      {/* Corps de la Modale */}
      <div className="grid grid-cols-4">
        {/* Type de contrat */}
        <div className="border-l py-4 px-6">
          <h3 className="text-start text-sm font-bold mb-2">Type de contrat</h3>
          <div className="flex flex-col space-y-2">
            {['Temps plein', 'Temps partiel', 'Freelance', 'Stage'].map((value, index) => (
              <Checkbox
                key={index}
                name="contractType"
                value={value}
                label={value}
                onChange={() => handleCheckboxChange('contractType', value)}
              />
            ))}
          </div>
        </div>

        {/* Expérience */}
        <div className="border-b py-4 px-6">
          <h3 className="text-start text-sm font-bold mb-2">Expérience</h3>
          <div className="flex-col space-y-2">
            {['Tous', '1 - 2 Années', '3 - 4 Années', '5 - 6 Années', '6 - 7 Années', '8 - 9 Années', '10 Années et plus'].map((value, index) => (
              <InputRadio
                key={index}
                name="experience"
                id={`experience_${index}`}
                label={value}
                value={value}
                onChange={handleInputChange}
                checked={filters.experience === value}
              />
            ))}
          </div>
        </div>

        {/* Éducation */}
        <div className="border-l py-4 px-6">
          <h3 className="text-start text-sm font-bold mb-2">Éducation</h3>
          <div className="flex flex-col space-y-2">
            {['Tous', 'BAC', 'BAC+2', 'BAC+3', 'Master', 'Doctorat'].map((value, index) => (
              <InputRadio
                key={index}
                name="education"
                id={`level_${index}`}
                value={value}
                label={value}
                onChange={handleInputChange}
                checked={filters.education === value}
              />
            ))}
          </div>
        </div>

        {/* Niveau d'emploi */}
        <div className="border-l py-4 px-6">
          <h3 className="text-start text-sm font-bold mb-2">Niveau d'emploi</h3>
          <div className="flex flex-col space-y-2">
            {['Tous','Confirmé', 'Intermédiaire', 'Junior'].map((value, index) => (
              <InputRadio
                key={index}
                name="level"
                id={`level_${index}`}
                label={value}
                value={value}
                onChange={handleInputChange}
                checked={filters.level === value}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex items-center justify-between bg-gray-50 py-2 px-4">
        <ButtonBlack onClick={() => console.log('Effacer')}>Effacer</ButtonBlack>
        <button onClick={() => setshowModal(false)} className="px-6 py-2 rounded-md border text-primary-black">
          Fermer
        </button>
      </div>
    </Modal>
  );
};

export default AdvancedSearchModal;
