import React, {useEffect, useState} from 'react'
import img from "../../assets/pexels-yankrukov-7793990.jpg"
import logo from "../../plateform/assets/logo/Fichier 1@2x.png"
import Input from '../../components/Form/Input'
import { Link, useNavigate } from 'react-router-dom'
import ButtonBlack from '../../components/Buttons/ButtonBlack'
import { useDispatch, useSelector } from 'react-redux'
import { clearAllUserErrors, login } from "../../redux/userSlice";
import Notification from '../../components/Notification'

const Login = () => {

    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const [showNotif, setShowNotif] = useState({
        show: false,
        message:"Une erreur est survenue",
        variant: "danger"
      })

    const handleInput= (e)=>{
        setForm({...form, [e.target.name]: e.target.value })
    }
  
    const { loading, isAuthenticated, error, user } = useSelector(
      (state) => state.user
    );
  
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
  
    const handleLogin = (e) => {
      e.preventDefault();
      if(form.email &&form.password){
        dispatch(login(form))
    }

    };
  
    useEffect(() => {
      if (error) {
        setShowNotif({...showNotif, variant: "danger", message:"Une erreur est survenue", show: true})
        dispatch(clearAllUserErrors());
      }
      if (isAuthenticated) {
        setShowNotif({...showNotif, variant: "success", message:"Connexion réussie !", show: true})
        setTimeout(() => {
            if(user){
              switch(user.role){
                case "recruteur":navigateTo("/offre");
                break
                case "candidat":navigateTo("/");
                break
                case "formateur":navigateTo("/formation");
                break
                default: navigateTo("/register");
                break
            }  
            }
            
            
        }, 2000);
        
      }
    }, [dispatch, error, loading, isAuthenticated]);
  
  return (   
    <section>
        <div className="flex">
            <div className="hidden lg:block lg:fixed  top-0 left-0   lg:w-[25%]  text-black ">
                <img src={img} alt="" className='w-full h-screen object-cover'/>
            </div>
            <div className=" lg:w-[25%] h-full bg-red-700"></div>
      
        <div className=" w-full lg:w-[75%] min-h-screen flex  justify-center bg-primary-beige"> 
        <div class="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 ">
            <div className="flex justify-center mb-4 "> 
                <img src={logo} alt="logo"  className='w-24 h-12 '/>
            </div>
            <div class="p-6 w-[600px] space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
            <div>
           
                 <h2 class="text-2xl font-bold text-gray-900  mb-2">
                Bienvenue !
            </h2>
            <p className='text-sm text-primary-text mx-6 px-10'>Nous avous ce qu'il vous faut: des ofres d'emplois, de stages, de formations.</p>
            </div>
           
            <form onSubmit={handleLogin} class="mt-8 space-y-6" action="#">
                <Input label="Email" name="email" type="email" required onChange={handleInput}/>
                <Input label="Mot de passe" name="password" type='password' onChange={handleInput} required/>
               
                <div class="flex items-start">
                    <a href="#" class="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500">Mot de passe oublié?</a>
                </div>
                <ButtonBlack  onClick={handleLogin} DefaultClassName='py-3 px-10 w-72 rounded-sm'>Se connecter</ButtonBlack>
                <div class="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Pas de compte? <Link to="/register" class="text-primary-700 hover:underline dark:text-primary-500">Créer un compte</Link>
                </div>
            </form>
        </div>
    </div>
      </div>
        </div>

        <Notification variant={showNotif.variant} text={showNotif.message} isOpen={showNotif.show} setIsOpen={()=>setShowNotif({...showNotif, show: false})} />
    </section>
)
}
 

export default Login