import React, { useState } from 'react'
import Input from '../../../../../components/Form/Input'
import Select from '../../../../../components/Form/Select'
import TextAreaEditor from '../../../../../components/Form/TextAreaEditor'
import ButtonHoverAnimated from "../../../../../components/Buttons/ButtonHoverAnimated"
// import DropdownCheckbox from "../../../../../../components/Dropdown/DropdownCheckbox"
import ImagePicker from '../../../../../components/ImagePicker'
import Notification from '../../../../../components/Notification'
import { BiMoney } from 'react-icons/bi'
import ButtonBlack from '../../../../../components/Buttons/ButtonBlack'
import DropdownCheckbox from '../../../../../components/Dropdown/DropdownCheckbox'


const Edit = () => {


  const [showNotif, setShowNotif] = useState({
    show: false,
    message:"Enregistrement effectué avec succès!",
    variant: "success"
  })

  return (
    <div className='min-h-full w-full pr-6 lg:pr-20 xl:pr-40 pl-6 text-primary-black py-6 '>
        <div className="flex justify-between items-center mb-4">
            <h1 className='font-semibold'>Modifier une offre</h1>
            <ButtonHoverAnimated
            onClick={()=>setShowNotif({...showNotif, show: true})}
            className='border bg-neutral-200 text-sm rounded-md px-6 py-2  text-white'><div className='flex gap-2'> 
              Publier</div> 
            </ButtonHoverAnimated>
        </div>

        <div className='space-y-4'>
            <Input label="Titre de l'offre"/>
            <Select label="Type d'emploi" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Select label="Mode de travail" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Select label="Catégorie" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Input label="Date d'éxpiration" type='date'/>
            <div className="p-3 border rounded-md flex gap-4">
              {/* <img src={img} alt="imgz" className="h-full w-40 rounded-sm" /> */}
              <div className='flex flex-col justify-between items-start text-start' >
                <h2 className='text-sm'>Ajouter une image</h2>
                <p className='text-xs text-primary-text'>Format accepté: SVG, PNG, JPG, JPGE</p>
                <ButtonBlack>Choisir</ButtonBlack>
              </div>
            </div>

            <h3 className='font-semibold text-start mb-4'>Localisation</h3>
            <div className="grid grid-cols-3 gap-4">
            <Input label="Adresse"/>
            <Select label="Région" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Select label="District" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            </div>

            <h3 className='font-semibold text-start mb-4'>Critère de sélection</h3>
            <div className="grid grid-cols-3 gap-4">
            <Input label="Niveau de diplôme"/>
            <Select label="Compétence réquise" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Select label="Niveau professionnel" placeholder='Veuillez sélectionner' options={["Tout public", "Junior", "Intermédiaire", "Confirmé"]}/>
            
            </div>
            <DropdownCheckbox className/>

            <h3 className='font-semibold text-start mb-4'>Salaire</h3>
            <div className="grid grid-cols-3 gap-4 items-center">
              <div className="bg-neutral-50 border rounded-md p-2 flex-col flex justify-start">
                <input type="radio" name="salaire" id="salaire" className='w-4 h-4'/>
                <div className="flex flex-col justify-center space-y-2">
                  <BiMoney className='mx-auto'/>
                  <p>Préciser</p>
                </div>
              </div>
              <label htmlFor='salaire1' className="cursor-pointer bg-neutral-50 border rounded-md p-2 flex-col flex justify-start">
                <input type="radio" name="salaire" id="salaire1" className='w-4 h-4'/>
                <div className="flex flex-col justify-center space-y-2">
                  <BiMoney className='mx-auto'/>
                  <p>A négocier</p>
                </div>
              </label>
              <label htmlFor='salaire2' className="cursor-pointer bg-neutral-50 border rounded-md p-2 flex-col flex justify-start">
                <input type="radio" name="salaire" id="salaire2" className='w-4 h-4'/>
                <div className="flex flex-col justify-center space-y-2">
                  <BiMoney className='mx-auto'/>
                  <p>Aucun</p>
                </div>
              </label>
            </div>
            <div className="grid grid-cols-2 gap-4">
            <Input label="Salaire à payer (en Ariary)"/>
            <Select label="Fréquence" options={["Mois", "Année"]}/>
            </div>
            
            <TextAreaEditor label='Description'/>
            <TextAreaEditor label='Responsabilité'/>
            <TextAreaEditor label='Avantage'/>

        </div>
        <Notification isOpen={showNotif.show} setIsOpen={()=>setShowNotif({...showNotif, show: false})} text={showNotif.message}/>
    </div>
  )
}

export default Edit