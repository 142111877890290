import React, { useState } from 'react'
import Input from '../../../../components/Form/Input'
import Select from '../../../../components/Form/Select'
import TextAreaEditor from '../../../../components/Form/TextAreaEditor'
// import { BiMoney } from 'react-icons/bi'
import ButtonHoverAnimated from "../../../../components/Buttons/ButtonHoverAnimated"
// import img from "../../../../plateform/assets/images/fond vert.jpg"
// import ButtonBlack from '../../../../components/Buttons/ButtonBlack'
import DropdownCheckbox from "../../../../components/Dropdown/DropdownCheckbox"
import ImagePicker from '../../../../components/ImagePicker'
import Notification from '../../../../components/Notification'

const PostTraining = () => {

  const [showNotif, setShowNotif] = useState({
    show: false,
    message:"Enregistrement effectué avec succès!",
    variant: "success"
  })

  return (
    <div className='min-h-full w-full pr-6 lg:pr-20 xl:pr-40 pl-6 text-primary-black py-6 '>
        <div className="flex justify-between items-center mb-4">
            <h1 className='font-semibold'>Publier une formation</h1>
            <ButtonHoverAnimated
            onClick={()=>setShowNotif({...showNotif, show: true})}
            className='border bg-neutral-200 text-sm rounded-md px-6 py-2  text-white'><div className='flex gap-2'> 
              Publier </div> 
            </ButtonHoverAnimated>
        </div>

        <div className='space-y-4'>
            <Input label="Titre de formation"/>
            <Select label="Type de formation" placeholder='Veuillez sélectionner' options={["Cours Normal", "Cours Acceleré", "Cours Avancé"]}/>
            <Select label="Mode d'enseignement'"  options={["Présentiel", "A Distance"]}/>
            <Select label="Catégorie" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Select label="Niveau" placeholder='Veuillez sélectionner' options={["Débutant", "Intermédiaire", "Avancé"]}/>
            <Input label="Date d'éxpiration" type='date'/>
            <ImagePicker label='Image'></ImagePicker>

            <h3 className='font-semibold text-start mb-4'>Localisation</h3>
            <div className="grid grid-cols-3 gap-4">
            <Input label="Adresse"/>
            <Select label="Région" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            <Select label="District" placeholder='Veuillez sélectionner' options={["CDI", "CDD"]}/>
            </div>

            <TextAreaEditor label='Description'/>
            <TextAreaEditor label='Programme'/>
            {/* <TextAreaEditor label='Avantage'/> */}
        </div>

        <Notification isOpen={showNotif.show} setIsOpen={()=>setShowNotif({...showNotif, show: false})} text={showNotif.message}/>
    </div>
  )
}

export default PostTraining