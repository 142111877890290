import React, {useEffect, useState} from 'react'
import ApplicationsData from "../../Datas/Applications.json"
// import RippleButton from '../../../../components/Buttons/RippleButton'
import { BiFilter } from 'react-icons/bi'
import ico from "../../../assets/searchIcon/search.svg"
// import Dropdown from "../../../../components/Dropdown/Dropdown"
import TrainingCard from '../../components/Card/TrainingCard'
import TrainingCardSkeleton from '../../components/Skeleton/TrainingCardSkeleton'
import {FormationDatas} from "../../../datas/FormationDatas"

const Training = () => {
  const [training, setTraining] = useState(FormationDatas)
  const [tasks, setTasks] = useState([]);
  const [loading, setloading] = useState(false)

    useEffect(() => {
      // Fetch data from JSON or API
    //   axios.get('../../datas/Applications.json')
    //     .then(response => {
    //       setTasks(response.data)
    //     })
    //     .catch(error => {
    //       console.error("Error fetching tasks data:", error);
    //     })
    setTasks(ApplicationsData)
    }, [])

  return (
    <div className='layout-1'>
      <h1 className='font-semibold mb-6 text-start'>Formation</h1>
      <div className=" flex justify-between items-center mb-4 gap-x-4">
         {/* Search bar */}
         <div className="relative flex items-center w-full md:w-96 h-10 rounded-lg   overflow-hidden border ">
          <div className="grid place-items-center h-full w-12 text-gray-300">
              <img src={ico} alt="serachbtn" />
          </div>
          <input
          className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
          type="text"
          id="search"
          placeholder="Mot clé..." 
          // onChange={(e)=>setFormData({...formData, keyword: e.target.value})}
          /> 
        </div>

        <div className="flex gap-2 ">
        {/* Filter button */}
        <button className="px-4 py-2 font-medium border text-[#24292F] hover:bg-primary-black hover:text-white rounded-md focus:outline-none text-sm flex gap-1 items-center justify-between">
        <BiFilter className='w-5 h-5'/>
        Filtrer
        </button>
        </div>
      </div>

      {/* Tableau */}
     <div className="grid grid-cols-1 md:grid-cols-2 min-[1500px]:grid-cols-3 gap-4">

     
     {loading ?
      Array.from({ length: 6 }).map((_, index) => (
        <TrainingCardSkeleton key={index} />
      ))
      :
      training.map((item)=>{
        return ( 
          <TrainingCard item={item}/>
          ) 
        }
      )
      }
     </div>

    </div>
  )
}

export default Training