import React, { useEffect, useRef, useState } from "react"
// import { FaEllipsisVertical } from "react-icons/fa6"
import { Link, useNavigate } from "react-router-dom"
// import axios from "axios"
// import { api } from "../../../../../api"
// import api from "../../../../../api"


const Dropdown = ({title= "Menu", marginBottom=6, mb="16", width = "200px", className="", position="right", navLink=[["item1","path1"], ["item2","path2"]] }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const navigate = useNavigate()
  const trigger = useRef(null)
  const dropdown = useRef(null)
  const id = localStorage.getItem("soro-id")

  const [showProfileModal, setShowProfileModal] = useState(false);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler)
    return () => document.removeEventListener("click", clickHandler)
  })



  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  })

  const logout =()=>{
    localStorage.removeItem('soro-id')
    localStorage.removeItem('soro-email')
    localStorage.removeItem('soro-role')
    navigate("/login")
  }

  return (
    <section className="bg-gray-2  dark:bg-dark">
      <div className="container">
        <div className="flex justify-center">
          <div className="relative inline-block">
            <button
              ref={trigger}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className={" flex items-center justify-center gap-1 text-base font-medium text-dark "+ className}
            >
              <span className="sm:block hidden text-sm">{title}</span>
              <span
                className={`sm:block hidden  transition-all duration-200 ${dropdownOpen ? ' rotate-180' : ''}`}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4062 5.65625 17.6875 5.9375C17.9688 6.21875 17.9688 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1562 10.1875 14.25 10 14.25Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
              className={`absolute mt-${marginBottom} ${position}-0 top-full w-[${width}] divide-y divide-stroke overflow-hidden rounded-lg bg-white border shadow-md ${dropdownOpen ? "block" : "hidden"}`}
            >

              {navLink.map((item, index)=>{
                return (
                  <div>
                  <Link
                    to={item[0]}
                    onClick={()=>setDropdownOpen(false)}
                    className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50 dark:text-white dark:hover:bg-white/5"
                  >
                    <span className="flex items-center text-start gap-2 text-sm">
                      {item[1]}
                    </span>
                  </Link>
                  </div>
                )})
              }
              
            </div>
          </div>
        </div>
      </div>    
    </section>
  );
}


export default Dropdown