import React from 'react';

const Subscription = () => {
  return (
    <div className='w-full px-4 lg:px-10 xl:px-20'>
      <div className="bg-primary-green rounded-xl px-6 lg:px-48 py-6 md:py-10 xl:py-20 overflow-hidden flex flex-col justify-center text-center space-y-6">
        <h6 className="text-2xl lg:text-4xl font-bold text-primary-black">
          Abonnez-vous pour recevoir les dernières mises à jour !
        </h6>
        <p className="text-primary-black lg:text-lg">
          Rejoignez notre communauté et soyez le premier à découvrir les nouveaux cours, offres spéciales et conseils exclusifs pour booster vos compétences et votre carrière. 
        </p>
        <div className="flex justify-center">
          <div className="bg-white rounded-md shadow p-2 w-full max-w-md flex justify-between">
            <input
              type="email"
              className="outline-none p-2 w-full"
              placeholder="Entrez votre email..."
            />
            <button className="bg-primary-black text-white px-4 py-2 rounded-sm">
              S'abonner
            </button>
          </div>
        </div>
        {/* <p className="text-sm text-primary-black opacity-75">
          Nous respectons votre vie privée. Vous pouvez vous désabonner à tout moment.
        </p> */}
      </div>
    </div>
  );
};

export default Subscription;
