import React from 'react'
import back1 from "../../assets/images/image1.png"
import icon1 from "../../assets/jobIcons/icon1.svg"
// import ButtonHoverAnimated from '../../../components/Buttons/ButtonHoverAnimated'
import datas from '../../datas/Datas.json'
// import FindTalentCard from '../../components/Cards/FindTalentCard'
import JobCard from '../../components/Cards/JobCard'

const TrainerDetail = () => {

  const jobs = datas

  const jobDetail = [
    {
      icon:icon1,
      title: "Site Web",
      label: "http://haisoa.com",
    },
    {
      icon:icon1,
      title: "Email",
      label: "contact@haisoa.com",
    },
    {
      icon:icon1,
      title: "Téléphone",
      label: "024 47 555 97",
    },
    {
      icon:icon1,
      title: "Twiter",
      label: "hhhh@122",
    },
    {
      icon:icon1,
      title: "Type d'offre",
      label: "CDI",
    }
  ]  

  return (
    <>
    <div className="bg-primary-green mb-6 rounded-lg h-56  mx-20 mt-10 bg-gray-800" style={{backgroundImage: "url("+back1+")", objectFit: "cover", backgroundSize:"100%"}}>
 
    </div> 
    <div className="mx-40 rounded-md h-28 bg-white relative -mt-20 mb-10 rounded-40 border backdrop-shadow px-6 py-4 flex justify-between items-center">
      <div className="flex gap-4">
        <div className="  bg-gray-50 border rounded-md flex justify-center items-center w-28 h-20 ">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_87_6284)">
          <path d="M11.4057 11.4038H0V0H11.4057V11.4038Z" fill="#F1511B"/>
          <path d="M24.0004 11.4038H12.5938V0H23.9994V11.4038H24.0004Z" fill="#80CC28"/>
          <path d="M11.4057 24.0001H0V12.5962H11.4057V24.0001Z" fill="#00ADEF"/>
          <path d="M24.0004 24.0001H12.5938V12.5962H23.9994V24.0001H24.0004Z" fill="#FBBC09"/>
          </g>
          <defs>
          <clipPath id="clip0_87_6284">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
          </svg>
        </div>

          <div className="flex flex-col text-start justify-between py-2">
            <h1 className='text-2xl font-semibold'>EU Robot</h1>
            <div className="inline-flex gap-1 text-sm text-primary-text"> Tanambao Fianarantsoa</div>
          </div>        
      </div>
      

      <div className="flex gap-4">
        <button className="py-2 px-6 rounded-md bg-black text-white">Envoyer Un Mail</button>
      </div>
    </div>
    
    <div className=" mb-10 flex sticky top-0 border-b mx-40 my-4 text-sm bg-white pt-2">
      <button className="border-b-gray-950 px-6 py-2 border-b-2 font-bold hover text-primary-black">A propos</button>
      <button className="px-6 py-2 text-primary-text hover text-primary-black">Formations (3)</button>    
    </div>
    <div className="px-40 flex justify-between gap-10">
     
      {/* Scrollable Content */}
      <div className="w-2/3 leading-loose text-justify text-black space-y-6">

     
     {/* A propos de d'entreprise */}
     <div>
        <h4 className="text-xl font-bold mb-2">
          A propos de l'entreprise
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
          Labore ut similique quas in officia, sequi neque!
        </p>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-2">
          Description
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
          Labore ut similique quas in officia, sequi neque!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto est commodi pariatur aspernatur nihil? Nesciunt maiores deserunt porro? Eveniet ipsum animi a velit distinctio, rerum illum. Deleniti dolor sed porro!
          Quae laborum, quo, nisi tempore delectus vel placeat laudantium laboriosam odit rem quia repellat omnis non sed sint sequi? Explicabo quisquam adipisci officia quis sequi repellendus inventore provident quod libero.
          Asperiores, harum incidunt nesciunt ab eveniet enim nobis eligendi. Exercitationem, reprehenderit voluptatum ipsam culpa nihil voluptate quas, accusantium fugit possimus suscipit modi ullam inventore magni aspernatur dicta laboriosam amet. Placeat.
          Excepturi nobis consectetur, repudiandae cumque aliquam totam deleniti tempore ex officia, suscipit id saepe rerum veritatis amet adipisci odit? Quisquam, quo velit reiciendis voluptatibus libero modi totam itaque recusandae nobis!
          Ipsa et quas possimus porro facere nobis non ducimus nihil neque eos, aspernatur accusantium nesciunt fuga, similique quia odit ipsum accusamus quam voluptates beatae quod, aperiam quae. Exercitationem, soluta. Ducimus.
        </p>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-2">
          Missions et Responsabilités
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
         <ul className='list-item decoration-solid'>
          <li className='list-item'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
          <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
         </ul>
         </p>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-2">
          Avantages
        </h4>
        <p className='text-primary-text'>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
          Labore ut similique quas in officia, sequi neque!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto est commodi pariatur aspernatur nihil? Nesciunt maiores deserunt porro? Eveniet ipsum animi a velit distinctio, rerum illum. Deleniti dolor sed porro!
          Quae laborum, quo, nisi tempore delectus vel placeat laudantium laboriosam odit rem quia repellat omnis non sed sint sequi? Explicabo quisquam adipisci officia quis sequi repellendus inventore provident quod libero.
          Asperiores, harum incidunt nesciunt ab eveniet enim nobis eligendi. Exercitationem, reprehenderit voluptatum ipsam culpa nihil voluptate quas, accusantium fugit possimus suscipit modi ullam inventore magni aspernatur dicta laboriosam amet. Placeat.
          Excepturi nobis consectetur, repudiandae cumque aliquam totam deleniti tempore ex officia, suscipit id saepe rerum veritatis amet adipisci odit? Quisquam, quo velit reiciendis voluptatibus libero modi totam itaque recusandae nobis!
          Ipsa et quas possimus porro facere nobis non ducimus nihil neque eos, aspernatur accusantium nesciunt fuga, similique quia odit ipsum accusamus quam voluptates beatae quod, aperiam quae. Exercitationem, soluta. Ducimus.
        </p>
      </div>


     </div>

     {/* Sticky Box */}
      <div className="w-1/3 h-[500px] bg-white  border-2 border-gray-300 rounded-2xl sticky top-20">
      <h5 className='text-xl font-semibold py-4 text-start px-6'>Contact</h5>
      {jobDetail.map((item)=>{
        return(
        <div className="border-b flex gap-4 items-center py-4 mx-6">
          <img src={item.icon} alt="..." />
          <div className="flex justify-between flex-col text-start">
            <div className='text-sm text-primary-text'>{item.title}</div>
            <div className='font-semibold'>{item.label}</div>
          </div>
        </div>  
        )
        })}  
        {/* <ButtonHoverAnimated className="px-6 py-2 ">Commencer</ButtonHoverAnimated> */}
        <div className="w-full px-4">
       {/* <button className=' w-full rounded-md py-4 bg-primary-black text-white font-medium '>Postuler Maintenant</button> */}
       
        </div>
          </div>
    </div>

    <div className='w-full py-20 px-20 lg:px-40 bg-primary-beige'>
      <div className="flex justify-between items-center mb-10">
          <h4 className='text-4xl font-bold'>Offres similaires</h4>
          <button className="flex border-black rounded-sm items-center gap-2 px-4 py-2 border-2 border-primary-black  font-bold text-primary-black">Voir tout
          <svg className='w-6 rotate-180' viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#3C3D42" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#3C3D42" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>
          </button>
      </div>
      <div className='grid xl:grid-cols-3  min-[1920px]:grid-cols-3 md:grid-cols-1 sm:grid-col-span-1 gap-6'>
    {jobs.map((item)=>{
      return( 
        <JobCard key={item.id} item={item} />
        )
       
    })}</div>
  </div>

  </>
  )
}

export default TrainerDetail