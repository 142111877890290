import React, { useState, useRef, useEffect } from 'react';
import ico from "../../plateform/assets/searchIcon/search.svg"

const DropdownCheckbox = ({ valueSlelected = ["React"], skills = ['React', 'JavaScript', 'CSS', 'Node.js', "Fluter", "Dart"] }) => {
  const [isOpen, setIsOpen] = useState(false);  
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownRef = useRef(null)
  const trigger = useRef(null)
  const dropdown = useRef(null)

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter(i => i !== item));
    }
  };

  const removeItem = (item) => {
    setSelectedItems(selectedItems.filter(i => i !== item));
    setIsOpen(false)
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
    console.log("Selected items",selectedItems)
  };

  const handleBlur = (e) => {
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  }

    // close on click outside
    useEffect(() => {
      const clickHandler = ({ target }) => {
        if (!dropdown.current) return;
        if (
          !isOpen ||
          dropdown.current.contains(target) ||
          trigger.current.contains(target)
        )
          return;
        setIsOpen(false);
      };
      document.addEventListener("click", clickHandler)
      return () => document.removeEventListener("click", clickHandler)
    })
  
  
  
    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!isOpen || keyCode !== 27) return;
        setIsOpen(false);
      };
      document.addEventListener("keydown", keyHandler);
      return () => document.removeEventListener("keydown", keyHandler);
    })

  return (
    <div className="relative inline-block w-full" ref={dropdownRef}>
      <div
      ref={trigger}
        onClick={handleToggleDropdown}
        // onFocus={()=>setIsOpen(true)}
        // onBlur={handleBlur}
        className="bg-white w-full text-primary-black border focus:ring-2 focus:outline-none focus:ring-neutral-300/20 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-between items-center"
        type="button"
      >
        {selectedItems.length !== 0 
        ?
        <div className="flex flex-wrap">
          {selectedItems.map((item, index) => (
            <button
              key={index}
              onClick={() => removeItem(item)}
              className="px-3 py-1 bg-neutral-50 text-neutral-600 rounded-sm mr-2"
            >
              {item} <span className="ml-2">✕</span>
            </button>
          ))}
        </div>
        :
        "Sélectionner une compétence"
        }
        
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </div>

      {isOpen && (
        <div
        ref={dropdown}
        className=" z-[4] w-full bg-white rounded-lg shadow-md shadow-primary-black/20 absolute mt-2 right-0 pb-2">
          <div className="mx-2 mt-2 flex items-center w- h-10 rounded-lg   overflow-hidden border ">
              <div className="grid place-items-center h-full w-12 text-neutral-600 ">
                  <img src={ico} alt="serachbtn" />
              </div>
              <input
              className="peer h-full w-full outline-none text-sm text-neutral-600 pr-2"
              type="text"
              id="search"
              placeholder="Mot clé..." 
              // onChange={(e)=>setFormData({...formData, keyword: e.target.value})}
              /> 
            </div>
          
          <ul className="p-3 space-y-1 text-sm text-gray-700 max-h-60 overflow-y-auto">
            {skills.map((skill, index) => (
              <li key={index}>
                <div className="flex items-center justify-start gap-2 p-2 rounded hover:bg-gray-100">
                  <input
                    id={`checkbox-item-${index}`}
                    type="checkbox"
                    value={skill}
                    onChange={(e) =>{
                      
                      handleCheckboxChange(e, skill)
                    // console.log("Selected items",selectedItems)
                    }}
                    checked={selectedItems.includes(skill)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor={`checkbox-item-${index}`} className="w-full ms-2 text-sm text-start font-medium text-gray-900 rounded">
                    {skill}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownCheckbox;
