import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { COMPANY_API_END_POINT } from '../utils/constant';

const companySlice = createSlice({
  name: 'companies',
  initialState: {
    singleCompany: {},
    companies: [],
    loading: false,
    error: null,
  },
  reducers: {
    // reducer for fetching all companies
    requestForCompanies(state) {
      state.loading = true;
      state.error = null;
    },
    successForCompanies(state, action) {
      state.loading = false;
      state.companies = action.payload;
      state.error = null;
    },
    failureForCompanies(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    // reducers for fetching one company
    requestForSingleCompany(state) {
        state.loading = true;
        state.error = null;
      },
      successForSingleCompany(state, action) {
        state.loading = false;
        state.singleCompanies = action.payload;
        state.error = null;
      },
      failureForSingleCompany(state, action) {
        state.loading = false;
        state.error = action.payload;
      },

  },
});

export const fetchCompanies = () => async (dispatch) => {
  dispatch(companySlice.actions.requestForCompanies());
  try {
    const response = await axios.get(`${COMPANY_API_END_POINT}/all`);
    dispatch(companySlice.actions.successForCompanies(response.data.companies));
  } catch (error) {
    dispatch(companySlice.actions.failureForCompanies('Erreur lors de la récupération des entreprises'));
  }
};

export const findCompany = (company_id) => async (dispatch) => {
    dispatch(companySlice.actions.requestForSingleCompany());
    try {
      const response = await axios.get(`${COMPANY_API_END_POINT}/${company_id}`);
      dispatch(companySlice.actions.successForSingleCompany(response.data.companies));
    } catch (error) {
      dispatch(companySlice.actions.failureForSingleCompany('Erreur lors de la récupération des entreprises'));
    }
  };

  export const deleteCompany = (company_id) => async (dispatch) => {
    dispatch(companySlice.actions.requestForSingleCompany());
    try {
      const response = await axios.get(`${COMPANY_API_END_POINT}/${company_id}`);
      dispatch(companySlice.actions.successForSingleCompany(response.data.companies));
    } catch (error) {
      dispatch(companySlice.actions.failureForSingleCompany('Erreur lors de la récupération des entreprises'));
    }
  };

export const { requestForCompanies, successForCompanies, failureForCompanies } = companySlice.actions;
export default companySlice.reducer;
