import React from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../../components/Header/Header'

const Layout = () => {

    return (
        <>
            <Header />
            <div className='flex mt-14 w-full overflow-x-hidden'>
                <Sidebar/>
                    <Outlet/>
            </div>
                            
        </>
      )
}

export default Layout