import React, { useEffect, useState } from 'react'
import RequestCard from '../../components/Cards/RequestCard'
import image1 from "../../assets/images/gabrielle-henderson-DgCPTkDqhHg-unsplash.jpg"
import { useLocation } from 'react-router-dom'
import Modal from '../../../components/Modal'
import TextAreaEditor from '../../../components/Form/TextAreaEditor'
import { CgClose } from 'react-icons/cg'
import Input from '../../../components/Form/Input'
import Select from '../../../components/Form/Select'
import ImagePicker from "../../../components/ImagePicker"
import image2 from "../../assets/Illustrations/marketing1.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequests, addRequest } from '../../../redux/requestSlice';

const Request = () => {
    const dispatch = useDispatch();
    const { requests, loading, error } = useSelector((state) => state.requests);
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const [selectedType, setSelectedType] = useState("Tous");
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [description, setDescription] = useState('');
    const [newRequest, setNewRequest] = useState({
        title: '',
        user_id: user?.id,
        description: '',
        type: '',
        category_id: '',
        photo: ''
    });
    const [showAddModal, setshowAddModal] = useState(false);

    useEffect(() => {
        dispatch(fetchRequests());
    }, [dispatch]);

    useEffect(() => {
        if (selectedType === 'Tous') {
            setFilteredRequests(requests);
        } else {
            setFilteredRequests(requests?.filter(req => req.type === selectedType));
        }
    }, [selectedType, requests]);

    const handleFilter = (type) => {
        setSelectedType(type);
    };

    const handleSubmit = () => {
      setNewRequest((prevState) => ({
        ...prevState,
        description: description,  // Mettre la description à jour
      }));
      console.log("Ajout de reqete ",newRequest)
        // dispatch(addRequest(newRequest));
        // setshowAddModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewRequest({
            ...newRequest,
            [name]: value,
        });
    };

    const  { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // if (error) return <>Une erreur est survenue !</>;

    return (
        <div className='w-full px-6 lg:px-20 xl:px-40'>
            <div className="grid grid-cols-2 py-6 items-center justify-between">
                <h1 className="text-5xl font-bold text-start leading-normal">
                    Faites un revues des requêtes des candidats
                </h1>
                <div className="flex justify-center">
                    <img src={image2} alt="image de requête" className='w-[350px]' />
                </div>
            </div>

            <div className="flex items-end justify-between top-20 border-b w-full my-4 text-sm bg-white pt-2 mb-6">
                <div>
                    <button
                        onClick={() => handleFilter('Tous')}
                        className={`px-6 py-2 border-b-2 font-bold ${selectedType === 'Tous' ? 'text-primary-black' : 'text-primary-text'}`}
                    >
                        Tous
                    </button>
                    <button
                        onClick={() => handleFilter('Emploi')}
                        className={`px-6 py-2 ${selectedType === 'Emploi' ? 'text-primary-black' : 'text-primary-text'}`}
                    >
                        Emploi
                    </button>
                    <button
                        onClick={() => handleFilter('Stage')}
                        className={`px-6 py-2 ${selectedType === 'Stage' ? 'text-primary-black' : 'text-primary-text'}`}
                    >
                        Stage
                    </button>
                    <button
                        onClick={() => handleFilter('Divers')}
                        className={`px-6 py-2 ${selectedType === 'Divers' ? 'text-primary-black' : 'text-primary-text'}`}
                    >
                        Divers
                    </button>
                </div>
                <div className='pb-1'>
                    <button 
                        onClick={() => setshowAddModal(true)}
                        className='relative bg-primary-black p-2 rounded-sm text-white'
                    >
                        Publier une offre
                    </button>
                </div>
            </div>

            <p className='font-bold text-start mb-6'>{filteredRequests?.length || 0} Résultats</p>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {filteredRequests?.map((item) => (
                    <RequestCard key={item.id} item={item} loading={loading} />
                ))}
            </div>

            <Modal isOpen={showAddModal} onClose={() => setshowAddModal(false)}>
                <div className="p-6">
                    <div className="flex justify-end">
                        <button onClick={() => setshowAddModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
                            <CgClose />
                        </button>
                    </div>
                    <div className="flex justify-between items-center mt-4">
                        <div className="flex gap-4 items-center">
                            <img src={image1} alt="img" className='w-10 h-10 rounded-full' />
                            <div className='flex flex-col justify-between'>
                                <h1 className='text-lg font-semibold text-start'>John DOE</h1>
                                <p className='text-sm text-start'>Lot II 123 Antananarivo</p>
                            </div>
                        </div>
                        <button 
                            onClick={handleSubmit}
                            className='relative bg-primary-black px-4 py-2 rounded-sm text-white'
                        >
                            Publier la requête
                        </button>
                    </div>

                    <hr className='my-4' />

                    <Input label="Titre" name="title" onChange={handleChange} />
                    <Select label="Type de Requête" name="type" onChange={handleChange} options={['Emploi', 'Stage', 'Divers']} />
                    <TextAreaEditor label="Description" name="description" value={description} setValue={setDescription}/>
                    <br />
                    <ImagePicker  onImageChange={handleChange}/>
                </div>
            </Modal>
        </div>
    );
};

export default Request;
