import React from 'react'

const Input = ({
  label, 
  type = 'text', 
  name, 
  value, 
  placeholder, 
  onChange, 
  required = false, 
  className = '',
  classContainer=" mb-2 ",
  textSize ="text-sm",
  ...props
}) => {
  return (
    <div className={""+classContainer}>
      {label && (
        <label 
          htmlFor={name} 
          className={`block mb-2 ${textSize} font-medium text-gray-900 text-start`}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className={` border border-gray-300 text-gray-900 ${textSize} rounded-lg outline-none focus:ring-2 focus:ring-primary-green/20 focus:border-green-400 block w-full p-2.5   ${className}`}
        {...props}
      />
    </div>
  )
}

export default Input
