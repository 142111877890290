import React, { useEffect, useState } from 'react'
import ChooseAccountType from './steps/ChooseAccountType'
// import CreateAccount from './steps/CreateAccount'
import ChooseCategory from './steps/ChooseCategory'
import Welcome from './steps/Welcome'
import { useNavigate } from 'react-router-dom'
import img from "../../plateform/assets/images/fond vert.jpg"
import CreateCandidateAccount from "./steps/CreateAccount/CreateCandidateAccount"
import CreateRecruiterAccount from './steps/CreateAccount/CreateRecruiterAccount'
import CreateTrainerAccount from './steps/CreateAccount/CreateTrainerAccount'
import { CgArrowLeft } from 'react-icons/cg'
import img2 from "../../assets/pexels-yankrukov-7793990.jpg"

const Register = () => {
    const navigate = useNavigate()
    const [role, setRole] = useState("Candidat")
    const [data, setData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })

    const [candidateData, setCandidateData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })

    const [recruiterData, setRecruiterData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })

    const [trainerData, setTrainerData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })


    const [page, setPage] = useState(1)

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className="flex selection:bg-primary-green ">
            <div className="hidden lg:block lg:fixed  top-0 left-0   lg:w-[25%]  text-black ">
                <img src={img2} alt="" className='w-full h-screen object-cover drop-shadow-xl'/>
            </div>
            <div className=" lg:w-[25%] h-full bg-red-700"></div>
            <div className="w-full lg:w-[75%]  min-h-screen flex  justify-center bg-[#f7fce3]">
                <div className="flex items-start">
                    <button 
                    className={(page !== 1) ? " absolute mb-3 p-2 border bg-white ml-0  mt-5 rounded-full block ":" hidden  p-2 border bg-white m-10 rounded-full" +" p-2 border bg-white m-10 rounded-full"} 
                    onClick={()=>setPage(page-1)}>
                        <CgArrowLeft></CgArrowLeft>
                    </button>
                </div>
                <div className=" w-8/12 ">           
                    {
                        page === 1 && (
                            <ChooseAccountType setPage={setPage} data={data} page={page} setData={setData} role={role} setRole={setRole} />
                        )
                    }
                    {
                        page === 3 && (
                            <ChooseCategory setPage={setPage} data={data} page={page} setData={setData} />
                        )
                    }
                    {
                        (page === 2 && role === "Candidat") && (
                            <CreateCandidateAccount setPage={setPage} data={data} page={page} setData={setData} />
                        )
                    }
                    {
                        (page === 2 && role === "Recruteur") && (
                            <CreateRecruiterAccount setPage={setPage} data={data} page={page} setData={setData} />
                        )
                    }
                    {
                        (page === 2 && role === "Formateur") &&  (
                            <CreateTrainerAccount setPage={setPage} data={data} page={page} setData={setData} />
                        )
                    }
                    {
                        page === 4 && (
                            <Welcome setPage={setPage} data={data} page={page} setData={setData} />
                        )
                    }
                </div> 
            </div>
        </div>
        
    )
}

export default Register