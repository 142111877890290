import React from 'react';

const Select = ({
  label,
  name,
  value,
  onChange,
  options = [],
  required = false,
  className = '',
  placeholder="",
  mb="mb-2",
  ...rest
}) => {
  return (
    <div className={mb}>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700 mb-1 text-start"
        >
          {label}
        </label>
      )}
      <select
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        className={` border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:ring-2 focus:ring-primary-green/20 focus:border-green-400 block w-full p-2.5  ${className}`}
        {...rest}
      >
        { placeholder !== "" && <option value="">{placeholder}</option> }
        {options.map((option, index) => (
          <option key={index} value={option} >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
