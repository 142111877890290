import React, {useState} from 'react'
import { HiPencil } from 'react-icons/hi'
import TextAreaEditor from '../../../../../components/Form/TextAreaEditor'
import { CgClose } from 'react-icons/cg'
import Modal from '../../../../../components/Modal'

const Bio = () => {
    const [showBioModal, setshowBioModal] = useState(false)

  return (
    <div className="flex-col text-start  border p-4 rounded-md">
        <div className="flex justify-between items-center  mb-4">
            <div className=' font-semibold'>Bio</div>
            
            <button  onClick={()=>setshowBioModal(true)} className="bg-neutral-50 border rounded-sm p-1">
                <HiPencil className='w-5 h-5 text-neutral-500'/>
            </button> 
        </div>
        <p className='text-primary-text'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem deserunt qui vitae commodi, nulla laborum? Sed iure a quia aperiam, nulla recusandae illum facere ad maxime quae. Consectetur, ex. Odit.</p>
        
        {/* Modal modification de la biographie */}
        <Modal  isOpen={showBioModal} onClose={()=>setshowBioModal(false)}>
            <div className="flex items-center justify-between py-2 px-4">
                <p>Modifier la biographie</p>
                <button  onClick={()=>setshowBioModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
                <CgClose></CgClose>
                </button>
            </div>
            <hr className='mb-6'/>
            <div className="px-6 space-y-4 mb-4">
                <TextAreaEditor label='Bio'/>
            </div>
            <div className="flex items-center justify-end p-6">
                <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
                Enregistrer
                </button>
            </div>
        </Modal>
    </div>
  )
}

export default Bio