import React from 'react'
import welcome from "../../../plateform/assets/Illustrations/welcome-removebg-preview.png"
import ButtonBlack from '../../../components/Buttons/ButtonBlack'

const Welcome = () => {

  return (
<div className='space-y-6 py-20'>
  <div className="flex justify-center">
    <img src={welcome} alt="" className='w-60' />
  </div>
  <h2 className='text-2xl md:text-4xl font-semibold'>Bienvenue sur Mira!</h2>
  <p className='px-10'>
    Nous sommes ravis de vous accueillir sur Mira, votre plateforme dédiée à la gestion simplifiée des rendez-vous et services. 
    Naviguez en toute simplicité et profitez de nos fonctionnalités!
  </p>
  <ButtonBlack>Terminer</ButtonBlack>
</div>

  )
}

export default Welcome