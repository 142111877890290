import React, {useState, useEffect} from 'react'
import datas from '../../datas/Datas.json'
import './FindJob.css'
import JobCard from '../../components/Cards/JobCard'
import InputIcon from '../../../components/Form/InputIcon'
import Select from '../../../components/Form/Select'
import Modal from "../../../components/Modal"
import InputRadio from '../../../components/Form/InputRadio'
import Checkbox from '../../../components/Form/Checkbox'
import ButtonBlack from '../../../components/Buttons/ButtonBlack'
import search from "../../assets/searchIcon/search.svg"
import location from "../../assets/searchIcon/location.svg"
import category from "../../assets/searchIcon/category.svg"
import filter from "../../assets/searchIcon/filter.svg"
import JobCardSkeleton from "../../components/Skeletons/JobCardSkeleton"
import back11 from '../../assets/images/49eddec5-17f2-4a0c-b7ca-5ece838b0848.jpg'
import SelectIcon from '../../../components/Form/SelectIcon'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJobs } from '../../../redux/jobSlice'
import Pagination from "../../components/Pagination"
import LayoutSwitcher from '../../components/LayoutSwitcher'
import AdvancedSearchModal from '../../components/Modals/AdvancedSearchModal'

const FindJob = () => {
  const [showModal, setshowModal] = useState(false)
  const [listStyle, setListStyle] = useState("md:grid-cols-1")
  const dispatch = useDispatch();
  const { jobs, loading, error } = useSelector((state) => state.job);

  const [filters, setFilters] = useState({
    keyword: '',
    localisation: '',
    secteur: '',
    contractType: [],
    experience: '',
    education: '',
    level: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  // Fonction pour gérer les changements de cases à cocher
  const handleCheckboxChange = (name, value) => {
    setFilters((prev) => {
      const newValues = prev[name].includes(value)
        ? prev[name].filter((v) => v !== value) // Supprime si déjà sélectionné
        : [...prev[name], value]; 
      return { ...prev, [name]: newValues };
    });
  };

  const handleSearch = () => {
    console.log('Filters applied:', filters);
    setshowModal(false); // Ferme le modal après la recherche
  };

  useEffect(() => {

    dispatch(fetchJobs({location:"", category:"", keyword : "", limit : 10}));
  }, [])
  
  return (
    <div className=''>
      <div className=" h-56 w-full px-6 lg:px-20 xl:px-40 py-8 object-cover" style={{ backgroundImage: `url(${back11})`, objectFit: "cover" }} >
        <div className="flex justify-between mb-10">
        <h5 className='font-semibold text-lg'>Emploi</h5>
        </div>
        <div className="bg-white rounded-lg shadow h-auto p-2">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
            <InputIcon name="keyword" value={filters.keyword} placeholder='Offre, poste, mot clé ...' noBorder={true} onChange={handleInputChange}/>
            <InputIcon className='hidden xl:block' value={filters.localisation} icon={location} name="localisation" placeholder='Localisation' noBorder={true} onChange={handleInputChange}/>
            <SelectIcon
            options={[
              { value: 'category1', label: 'Catégorie 1' },
              { value: 'category2', label: 'Catégorie 2' },
              { value: 'category3', label: 'Catégorie 3' },
            ]}
            value={filters.secteur}
            className="hidden md:block"
            icon={category}
            name="secteur"
            placeholder="Sélectionner un secteur"
            noBorder={true}
            onChange={handleInputChange}
          />
            <div className="flex items-center gap-x-2 gap-y-2">
              <button 
                onClick={() => setshowModal(true)} 
                className=" h-full w-[58px] justify-center border border-[#E8E8E8] bg-[#FBFBFB] rounded-md flex gap-2 items-center">
                <img src={filter} alt="" />
                
              </button>
              <button onClick={()=>dispatch(fetchJobs(filters))} className="p-4 py-3 bg-black rounded-md text-white font-medium w-full">
                Rechercher
              </button>
            </div>
          </div>
        </div>

      </div>
        {/* customise list display */}
        <div className="bg-white  px-6 lg:px-20 xl:px-40 h-16 shadow-sm  mb-6 py-3">
            <div className="flex justify-between items-center ">
              <Select name="period" options={["Récent","Populaire"]} mb='0'/>
              <LayoutSwitcher setListStyle={setListStyle} listStyle={listStyle} />
            </div>
        </div>   
          
      <div className="flex-1 px-6 lg:px-20 xl:px-40 mb-6">
        <div className="  py-3">
          <div className={'grid grid-cols-1 min-[1920px]:grid-cols-3  sm:grid-col-span-1 gap-6 ' + listStyle}>
          {loading ?
            Array.from({ length: 6 }).map((_, index) => (
              <JobCardSkeleton key={index} />
            ))
            :
            jobs.map((item, index)=>{
              return ( 
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -100 }}
                  transition={{ duration: 0.5 + index/10 }}
                  // key={job?._id}
                  >
                <JobCard key={item.id} item={item} />
                </motion.div>
                )
              })
        }
          </div>
       
      </div>
      </div>
      <hr/>
      <Pagination></Pagination>


      <AdvancedSearchModal
      showModal={showModal}
      setshowModal={setshowModal}
      filters={filters}
      handleInputChange={handleInputChange}
      handleCheckboxChange={handleCheckboxChange}
      handleSearch={handleSearch}
    />
    </div>
  )
}

export default FindJob