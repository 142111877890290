import React, {useState, useEffect} from 'react'
// import FindTalentCard from '../../../components/Cards/FindTalentCard'
import RecruiterCard from "../../../components/Cards/RecruiterCard"
import datas from '../../../datas/RecruiterData.json'
import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated'


const TopCompanies = ({items}) => {
    const [jobs, setJobs] = useState(datas)

    useEffect(() => {
      setJobs(datas)  
    }, [])

  return (
    <div className='w-full py-20 px-6 lg:px-20 xl:px-40  bg-cover'  >
    <div className="flex justify-between items-center mb-10">
        <h4 className='text-2xl lg:text-4xl font-bold text-start'>Des entreprises qui recrutent</h4>
        {/* <button className="rounded-full flex border-black whitespace-nowrap items-center gap-2 px-4 py-2 border-2 border-primary-black  font-bold text-primary-black">Voir tout
        <svg className='w-6 rotate-180' viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#3C3D42" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#3C3D42" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>
        </button> */}
        <ButtonHoverAnimated className='flex px-3 md:px-4 py-1.5 md:py-2 border-2 border-primary-black font-semibold text-primary-black rounded-full text-sm md:text-base'>
          Voir tout
        </ButtonHoverAnimated>
    </div>
    <div className='grid xl:grid-cols-2 md:grid-cols-1 sm:grid-col-span-1 gap-6'>
    {jobs.map((item)=>{
      return( 
        <RecruiterCard key={item.id} item={item} />
        )
    })}
  </div>
</div>
  )
}

export default TopCompanies