import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import bookmaker from '../../assets/icons/bookmaker-svgrepo-com.svg'
import next from '../../assets/icons/back-svgrepo-com.svg'
import SaveIcon from "../SaveIcon"


const JobCard = ({key, item}) => {
  const  navigate = useNavigate()
    
  return (
    <div 
    key={key}
    // onClick={()=>navigate("/offre/"+item.id)} 
    // to={"/offre/"+item.id} 
    className='bg-white rounded-lg overflow-hidden border py-6 px-6 transition-all ease-in hover:border-gray-400 cursor-pointer'>
        <div 
        // onClick={()=>navigate("/offre/"+item.id)} 
        className="flex items-center justify-between">
            <div 
            onClick={()=>navigate("/offre/"+item.id)} 
            className=' flex flex-row gap-3 mb-3'>
              <div className='border bg-slate-50 rounded-sm flex items-center justify-center w-14 h-14'>
                  <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
              </div>
              <div>
                <p className='text-level-1 flex-col justify-between col text-start'>
                  <div className='text-lg font-semibold'>{item?.job_title}</div>
                  <span>{item?.organisation?.name}</span>
                </p>
              </div>
            </div>   
            {/* <img src={bookmaker} alt="logo"  className='w-8 h-8'/> */}
            <SaveIcon></SaveIcon>
        </div>
        <div 
        onClick={()=>navigate("/offre/"+item?.id)} 
        className="flex gap-x-2 text-primary-text">
        <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">
          {item?.work_type}
        </div>
        <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">
          {item?.work_time}
        </div>
      </div>
        <div 
        onClick={()=>navigate("/offre/"+item?.id)} 
        className='text-level-2 '>
            <ul className='flex gap-3 mb-2'>
                {/* <li>{item?.dateStart} - {item?.dateFinish} </li> */}
                <li>{item?.address}</li>
            </ul>
            <p className="mb-2 text-start ">{item?.description}</p>
        <div className='flex justify-between items-center'>
        <p className=' gap-2 flex text-sm text-primary-black-300'><span>il y a 2j</span> - <span>{item?.applicants}</span></p>
        <button className='bg-[#4dff4d] border-none rounded-sm text-[#145926] font-semibold text-sm px-4 py-2 flex gap-2 items-center'>Voir l'offre <img src={next} alt="" className='w-4 rotate-180' /></button>
        </div>
        </div>
    </div>
  )
}

export default JobCard