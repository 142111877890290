import React, { useState } from 'react';

const Checkbox = ({
  label,
  name,
  value,
  onChange,
  checked = false,
  disabled = false,
  className = '',
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (event) => {
    setIsChecked(event.target.checked)
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <div className={`flex items-center gap-2 ${className}`} {...props}>
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        value={value}
        
      />
      <label htmlFor={name} className='text-sm font-medium text-gray-900'>
        {label}
      </label>
    </div>
  )
}

export default Checkbox;
