import React from "react";
import { motion, AnimatePresence } from 'framer-motion'
import img from "../../plateform/assets/Illustrations/findintelescope.png"
import Input from "../Form/Input";
import TextAreaEditor from "../Form/TextAreaEditor";
import ButtonBlack from "../Buttons/ButtonBlack";

const SendEmailModal = ({ children, styleContent, styleOverlay, isOpen, onClose, initialOverlay, animateOverlay, exitOverlay, initialContent, animateContent, exitContent, width= "90vw", defaultClassName="relative h-fit bg-white rounded-md  max-md:w-[70vw] max-lg:w-[60vw] z-40 lg:w-[50vw]" }) => {

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && (
                        <motion.div
                            // onClick={onClose}
                            initial={(initialOverlay === undefined) ? { opacity: 0 } : initialOverlay}
                            animate={(animateOverlay === undefined) ? { opacity: 1 } : animateOverlay}
                            exit={(exitOverlay === undefined) ? { opacity: 0 } : exitOverlay}
                            className={` w-screen h-screen overflow-scroll flex justify-center py-10   fixed z-50 top-0 right-0 bg-slate-700 bg-opacity-40 `}
                        >
                            <motion.div
                                initial={(initialContent === undefined) ? { opacity: 0, translateY: -50 } : initialContent}
                                animate={(animateContent === undefined) ? { opacity: 1, translateY: 0 } : animateContent}
                                exit={(exitContent === undefined) ? { opacity: 0, translateY: -50 } : exitContent}
                                className={defaultClassName}
                            >
                            <div className="p-6">
                                <div className="flex justify-between items-start gap-6">
                                    <div className="text-start">
                                        <h3 className="font-semibold text-lg">Envoyer un email</h3>
                                        <p className="text-primary-text ">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda sed, eligendi excepturi.</p>
                                    </div>   
                                    <img src={img} alt="image 10"className="w-28" />
                                    
                                </div>

                                <div className="grid items-center grid-cols-12 gap-x-1 w-full mb-4">
                                    <span className="col-span-1 text-start">De</span>
                                    <Input type="email" classContainer="col-span-11" className="w-full"></Input>
                                </div>
                                <div className="grid items-center grid-cols-12 gap-x-1 w-full">
                                    <span className="col-span-1 text-start">A</span>
                                    <Input type="email" classContainer="col-span-11" className="w-full"></Input>
                                </div>
                                <hr className="my-6" />

                                <TextAreaEditor></TextAreaEditor>

                            </div>
                            <div className="flex rounded-b-md items-center justify-between bg-gray-50 py-3 px-6">
                                <ButtonBlack>Envoyer</ButtonBlack>
                                {/* <ButtonBlack >Fermer</ButtonBlack> */}
                                <button 
                                    onClick={()=>onClose()}
                                    className='px-6 py-2 rounded-md border text-primary-black'>
                                    Annuler
                                </button>
                            </div>

                            </motion.div>

                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    )
}

export default SendEmailModal