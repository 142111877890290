import React from 'react'
import image2 from "../../assets/Illustrations/handshalffive.png"

const TermesAndConditions = () => {
  return (
    <div>
        <div className=" px-20 xl:px-40 flex justify-between p-10  gap-10">

        {/* Scrollable Content */}
        <div className="w-2/3 leading-loose text-justify text-black space-y-6">
        <div>
              <h4 className="text-xl font-bold mb-2">
              01. Terme et Condition
              </h4>
              <p className='text-primary-text'>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
                Labore ut similique quas in officia, sequi neque!
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto est commodi pariatur aspernatur nihil? Nesciunt maiores deserunt porro? Eveniet ipsum animi a velit distinctio, rerum illum. Deleniti dolor sed porro!
                Quae laborum, quo, nisi tempore delectus vel placeat laudantium laboriosam odit rem quia repellat omnis non sed sint sequi? Explicabo quisquam adipisci officia quis sequi repellendus inventore provident quod libero.
                Asperiores, harum incidunt nesciunt ab eveniet enim nobis eligendi. Exercitationem, reprehenderit voluptatum ipsam culpa nihil voluptate quas, accusantium fugit possimus suscipit modi ullam inventore magni aspernatur dicta laboriosam amet. Placeat.
                Excepturi nobis consectetur, repudiandae cumque aliquam totam deleniti tempore ex officia, suscipit id saepe rerum veritatis amet adipisci odit? Quisquam, quo velit reiciendis voluptatibus libero modi totam itaque recusandae nobis!
                Ipsa et quas possimus porro facere nobis non ducimus nihil neque eos, aspernatur accusantium nesciunt fuga, similique quia odit ipsum accusamus quam voluptates beatae quod, aperiam quae. Exercitationem, soluta. Ducimus.
              </p>
            </div>
    
            <div>
              <h4 className="text-xl font-bold mb-2">
              01. Terme et Condition
              </h4>
              <p className='text-primary-text'>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
               <ul className='list-item decoration-solid'>
                <li className='list-item'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
                <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
                <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
                <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</li>
               </ul>
               </p>
            </div>
    
            <div>
              <h4 className="text-xl font-bold mb-2">
              01. Terme et Condition
              </h4>
              <p className='text-primary-text'>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat eius nulla ad quidem illum vitae neque debitis, sint nisi sequi unde et explicabo nam quo corporis commodi. Quis, quo. Voluptate?
                Labore ut similique quas in officia, sequi neque!
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto est commodi pariatur aspernatur nihil? Nesciunt maiores deserunt porro? Eveniet ipsum animi a velit distinctio, rerum illum. Deleniti dolor sed porro!
                Quae laborum, quo, nisi tempore delectus vel placeat laudantium laboriosam odit rem quia repellat omnis non sed sint sequi? Explicabo quisquam adipisci officia quis sequi repellendus inventore provident quod libero.
                Asperiores, harum incidunt nesciunt ab eveniet enim nobis eligendi. Exercitationem, reprehenderit voluptatum ipsam culpa nihil voluptate quas, accusantium fugit possimus suscipit modi ullam inventore magni aspernatur dicta laboriosam amet. Placeat.
                Excepturi nobis consectetur, repudiandae cumque aliquam totam deleniti tempore ex officia, suscipit id saepe rerum veritatis amet adipisci odit? Quisquam, quo velit reiciendis voluptatibus libero modi totam itaque recusandae nobis!
                Ipsa et quas possimus porro facere nobis non ducimus nihil neque eos, aspernatur accusantium nesciunt fuga, similique quia odit ipsum accusamus quam voluptates beatae quod, aperiam quae. Exercitationem, soluta. Ducimus.
              </p>
            </div>
        </div>

        {/* Sticky Box */}
        <div className="w-1/3 h-[600px]   flex flex-col justify-between items-center rounded-2xl sticky top-20">
        <ul className='px-6 space-y-2 text-sm'>
            <li className="px-4 py-2 hover border-l-2 border-l-primary-green text-primary-gray">
                01. Terme et Condition
            </li>
            <li className="px-4 py-2 hover border-l-2 border-l-primary-green text-primary-gray">
                02. Terme et Condition
            </li>
            <li className="px-4 py-2 hover border-l-2 border-l-primary-green text-primary-gray">
                03. Terme et Condition
            </li>
            <li className="px-4 py-2 hover border-l-2 border-l-primary-green text-primary-gray">
                04. Terme et Condition
            </li>
        </ul>
        <img src={image2} alt="image de requête" className='w-[250px]'/>

        </div>

</div>
    </div>
  )
}

export default TermesAndConditions