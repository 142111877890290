import React, {useState} from 'react'
import bookmaker from '../../../../assets/icons/bookmaker-svgrepo-com.svg'
// import { FaPlus } from 'react-icons/fa'
import { BiPlus } from 'react-icons/bi'
// import { BsPencil } from 'react-icons/bs'
import { HiPencil } from 'react-icons/hi'
import Modal from '../../../../../components/Modal'
import TextAreaEditor from '../../../../../components/Form/TextAreaEditor'
import { CgClose } from 'react-icons/cg'
import Input from '../../../../../components/Form/Input'
import Select from '../../../../../components/Form/Select'

const Education = () => {
    const [showAddModal, setshowAddModal] = useState(false)
    // const [showUpdateModal, setshowUpdateModal] = useState(false)

  return (
    <div className="flex-col text-start border p-4 rounded-md">
    <div className="flex justify-between items-center  mb-4">
        <div className=' font-semibold'>Education</div>
        
        <button onClick={()=>setshowAddModal(true)} className="bg-neutral-50 border rounded-sm p-1">
           {/* <FaPlus className='text-gray-400'/>  */}
            <BiPlus className='w-5 h-5 text-neutral-500'></BiPlus>

        </button>
    </div>
    
    {
        ["1","2", "3"].map((item, index)=>{
            return(
                <div className={ (index !== 2)? " border-b py-4 flex gap-4 items-start w-full mb-4":"  border-none pt-4 flex gap-4 items-start w-full mb-4"}>
                    <img src={bookmaker} alt="entreprise" className='w-12 h-12 rounded-full bg-gray-50 border' />
                    <div className='w-full'>
                        <div className="flex justify-between items-end mb-2">                            
                            <div className="text-start flex-col">
                                <h6 className='font-semibold'>Title</h6>
                                <div className="inline-block gap-2 text-sm ">
                                    London . Dribbble . 2018-2019
                                </div>
                            </div>
                            <button className="bg-neutral-50 border rounded-sm p-1">
                                <HiPencil className='w-5 h-5 text-neutral-500'/>
                            </button>  
                        </div>
                        <p className=' text-primary-text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, quam vel cumque quasi officiis voluptates debitis praesentium, inventore architecto quo labore quod!</p>
                    </div>
                </div>   
            )
        })
    }

    <Modal  isOpen={showAddModal} onClose={()=>setshowAddModal(false)}>
        <div className="flex items-center justify-between py-2 px-4">
            <p>Ajouter une expérience</p>
            <button  onClick={()=>setshowAddModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
            <CgClose></CgClose>
            </button>
        </div>

        <hr className='mb-6'/>
       
        <div className="px-6 space-y-4 mb-4">
        <Input label="Ecole"/>
        <Input label="Diplôme"/>
        <Input label="Domaine d'étude"/>
        {/* <div className="grid grid-cols-3 gap-4 items-end">
            <Input label="Domaine d'étude"/>
            <Select label="District"/>
            <Select label="Région"/>
        </div> */}
        <div className="grid grid-cols-4 gap-4 items-end">
            <Select label="Mois de début"/>
            <Select label="Anné de début"/>
            <Select label="Mois de fin"/>
            <Select label="Année de fin"/>
        </div>
            <TextAreaEditor label='Description'/>
        </div>
        <div className="flex items-center justify-end p-6">
            <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
            Enregistrer
            </button>
        </div>
    </Modal>    
</div>
  )
}

export default Education