import React, { useEffect } from 'react'
import Hero from './partials/Hero'
import JobCategories from './partials/JobCategories'
import Offers from './partials/Offers'
import TopCompanies from './partials/TopCompanies'
import Trainings from './partials/Trainings'
import Testimonials from './partials/Testimonials'
import Subscription from './partials/Subscription'
import FAQSection from './partials/FAQSection'
import { useLocation } from 'react-router-dom'
import {useDispatch,useSelector} from "react-redux"
import { fetchJobs } from '../../../redux/jobSlice'
import TrainingCategories from './partials/TrainingCategories'

const Home = () => {
  const dispatch = useDispatch();
  const { jobs, loading, error } = useSelector((state) => state.job);
  const { companies} = useSelector((state) => state.company);
  
  const  {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(fetchJobs({location:"", category:"", keyword : "", limit : 6}));
  }, [dispatch,pathname]);



  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [pathname])

  return (
    <>
      <Hero/>
      <JobCategories/>
      {/* <Offers/> */}
      {loading ? <p>Chargement des offres...</p> : jobs?.length && <Offers jobs={jobs} /> }
      <TopCompanies items={companies}/>
      <TrainingCategories></TrainingCategories>
      <Trainings/>
      <Testimonials/>
      <Subscription/>
      <FAQSection/>
    </>
  )
}

export default Home