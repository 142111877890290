import React from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { HiXMark, HiCheck } from "react-icons/hi2"; // Import des icônes

export default function ConfirmModal({
  isOpen,
  setIsOpen,
  onAccepted,
  textTitle = "Confirmation",
  textContent = "Vous êtes vraiment sûr ?",
  textBtn = 'Confirmer',
  styleContent,
  styleOverlay,
  initialOverlay,
  animateOverlay,
  exitOverlay,
  initialContent,
  animateContent,
  exitContent,
  variant = "success" // Ajout de la propriété variant
}) {

  // Définir les styles en fonction de la variante
  const getVariantStyles = (variant) => {
    switch (variant) {
      case "success":
        return {
          bgColor: "bg-green-100",
          iconColor: "text-green-600",
          buttonBg: "bg-green-500",
          IconComponent: HiCheck // Icône de réussite
        };
      case "danger":
      default:
        return {
          bgColor: "bg-red-100",
          iconColor: "text-red-600",
          buttonBg: "bg-red-500",
          IconComponent: HiXMark // Icône de danger
        };
    }
  };

  const styles = getVariantStyles(variant);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={initialOverlay === undefined ? { opacity: 0 } : initialOverlay}
            animate={animateOverlay === undefined ? { opacity: 1 } : animateOverlay}
            exit={exitOverlay === undefined ? { opacity: 0 } : exitOverlay}
            className={`${styleOverlay} w-screen h-screen bg-gray-700/25 backdrop-filter fixed z-50 top-0 left-0 flex justify-center items-start`}
          >
            <motion.div
              initial={initialContent === undefined ? { opacity: 0, width: 0 } : initialContent}
              animate={animateContent === undefined ? { opacity: 1, width: '30vw' } : animateContent}
              exit={exitContent === undefined ? { opacity: 0, width: 0 } : exitContent}
              className={`${styleContent} bg-white my-12 rounded-md w-[25vw] pt-4`}
            >
              <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${styles.bgColor} mb-3`}>
                <styles.IconComponent className={`h-6 w-6 ${styles.iconColor}`} />
              </div>
              <h1 className="w-full text-center text-xl font-bold">{textTitle}</h1>
              <p className="w-full text-center mb-4">{textContent}</p>
              <div className="w-full rounded-b-md flex gap-x-3 justify-between items-center p-4 bg-neutral-100/60 text-sm">
                <button className="w-1/2 rounded-md border border-gray-200 py-2" onClick={() => setIsOpen(false)}>Annuler</button>
                <button className={`w-1/2 rounded-md ${styles.buttonBg} text-white py-2`} onClick={onAccepted}>
                  {textBtn}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
