import React, {useState, useEffect} from 'react'
import back1 from "../../assets/images/back22.jpg"
import icon1 from "../../assets/jobIcons/icon1.svg"
import datas from '../../datas/Datas.json'
import JobCard from '../../components/Cards/JobCard'
import Modal from "../../../components/Modal"
import ReactQuill from 'react-quill'
import "react-quill/dist/quill.snow.css"
import { CgClose } from 'react-icons/cg'
import axios from "axios"
import {useParams} from "react-router-dom"
import FilePicker from "../../../components/FilePicker"
import Input from '../../../components/Form/Input'
import TextAreaEditor from '../../../components/Form/TextAreaEditor'
import Select from '../../../components/Form/Select'

const JobDetail = () => {
  const [jobs, setJobs] = useState(datas)
  const [showModal, setshowModal] = useState(false)
  const [value, setValue] = useState("")
  const { id } = useParams(); // Récupérer l'id du job à partir de l'URL
  const [jobDetails, setJobDetails] = useState({})

  const mockJobData = {
    title: 'Développeur Web Full Stack',
    company: 'TechCorp Solutions',
    location: 'Paris, France',
    type: 'CDI',
    schedule: 'Temps plein',
    companyDescription: "TechCorp Solutions est une entreprise leader dans le développement d'applications web innovantes.",
    description: `
      Voici quelques points importants :
      - Développer des applications web robustes et scalables.
      - Collaborer avec les équipes UX/UI pour améliorer l'interface utilisateur.
      - Maintenir le code propre et bien documenté.
      - Participer à la revue de code et aux tests pour assurer la qualité du produit.
      - Gérer et résoudre les bugs signalés par les utilisateurs.
    `,
    missions: `
      Les missions et responsabilités incluent :
      - Concevoir et développer des fonctionnalités nouvelles pour l'application.
      - Assurer une haute performance des applications à travers des optimisations continues.
      - Participer aux réunions de planification pour comprendre et définir les besoins.
      - Intégrer des APIs tierces et gérer les bases de données.
      - Déployer les mises à jour sur les serveurs de production en respectant les standards de l'entreprise.
      - Collaborer activement avec les équipes de QA pour corriger les anomalies détectées.
      - Former et encadrer les nouveaux membres de l'équipe technique.
    `,
    avantages: `
      Les avantages du poste incluent :
      - Salaire compétitif avec des primes de performance.
      - Assurance santé complète pour vous et votre famille.
      - Programmes de formation continue et d'amélioration des compétences. 
      - Télétravail possible selon les conditions de l'entreprise.
      - Participation aux événements et séminaires de développement technologique.
      - Ambiance de travail agréable avec une équipe dynamique et innovante.
      - Espace de travail moderne avec toutes les commodités nécessaires.
    `,
    skills: ['JavaScript', 'React', 'Node.js', 'CSS', 'HTML'],
    details: [
      { icon: icon1, title: 'Salaire', label: '50 000 € - 60 000 € par an' },
      { icon: icon1, title: 'Expérience', label: '3+ années' },
      { icon: icon1, title: 'Niveau d\'études', label: 'Bac +5' },
    ],
  }

  const jobDetail = [
    {
      icon:icon1,
      title: "Type d'offre",
      label: "CDI",
    },
    {
      icon:icon1,
      title: "Méthode de travail",
      label: "Local",
    },
    {
      icon:icon1,
      title: "Type d'offre",
      label: "CDI",
    },
    {
      icon:icon1,
      title: "Type d'offre",
      label: "CDI",
    },
    {
      icon:icon1,
      title: "Type d'offre",
      label: "CDI",
    }
  ]

  const fetchJobDetails = async () => {
    try {
      const response = await axios.get(`https://api.example.com/jobs/${id}`); // Remplacer par l'URL de votre API
      setJobDetails(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des détails du job:', error);
    }
  };

  useEffect(() => {
    // Simuler la récupération des données du job
    setJobDetails(mockJobData);
  }, []);
  
  return (
    <>
      <div className="bg-neutral-100 mb-6 rounded-lg h-56 mx-3 md:mx-6  lg:mx-20 mt-10" style={{backgroundImage: "url("+back1+")", objectFit: "cover"}}>
        {/* <img src={back1} alt="" className=" relative w-full  object-cover rounded-md" /> */}
        <div className=" absolute mb-6 mt-[140px] md:mt-[120px] ml-6 md:ml-10 lg:ml-20 bg-gray-50 border-2 rounded-md flex justify-center items-center w-20 md:w-28 h-16 md:h-20 shadow">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_87_6284)">
            <path d="M11.4057 11.4038H0V0H11.4057V11.4038Z" fill="#F1511B"/>
            <path d="M24.0004 11.4038H12.5938V0H23.9994V11.4038H24.0004Z" fill="#80CC28"/>
            <path d="M11.4057 24.0001H0V12.5962H11.4057V24.0001Z" fill="#00ADEF"/>
            <path d="M24.0004 24.0001H12.5938V12.5962H23.9994V24.0001H24.0004Z" fill="#FBBC09"/>
            </g>
            <defs>
            <clipPath id="clip0_87_6284">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
          </svg>

        </div>
      </div> 

      <div className="w-full p-responsive gap-y-2 text-start space-y-2">
        <h4 className=" text-2xl md:text-4xl font-bold">{jobDetails.title}</h4>
        <p className="text-lg md:text-xl">{jobDetails.company}</p>
        <div className="flex gap-1 items-center text-primary-text">
          <p>{jobDetails?.location}</p>
          <p>{jobDetails?.type}</p>
          <p>{jobDetails?.schedule}</p>
        </div>
      </div>


      <div className="p-responsive flex justify-between p-10  gap-10">
       



        {/* Scrollable Content */}
        <div className=" w-full lg:w-2/3 leading-loose text-justify text-black space-y-6">

       {/* A propos de d'entreprise */}
       <div>
          <h4 className="text-xl font-bold mb-2">
            A propos de l'entreprise
          </h4>
          <p className="text-primary-text">{jobDetails.companyDescription}</p>
        </div>

        <div>
          <h4 className="text-xl font-bold mb-2">
            Description
          </h4>
          <p className='text-primary-text'>
            {jobDetails.description}
          </p>
        </div>

        <div>
          <h4 className="text-xl font-bold mb-2">
            Compétences
          </h4>
          <div className="flex flex-wrap gap-4">
            <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
              Javascript
            </div>
            <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
              Javascript
            </div>
            <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
              Javascript
            </div>
            <div className="font-[490] px-6 py-2 bg-gray-100 text-primary-text rounded-lg">
              Javascript
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-xl font-bold mb-2">
            Missions et Responsabilités
          </h4>
          <p className='text-primary-text'>
           {jobDetails.missions}
           </p>
        </div>

        <div>
          <h4 className="text-xl font-bold mb-2">
            Avantages
          </h4>
          <p className='text-primary-text'>
            {jobDetails.avantages}
          </p>
        </div>


       </div>

       {/* Sticky Box */}
        <div className="w-1/3 h-[520px] bg-white  border-2 border-gray-300 lg:block hidden rounded-2xl sticky top-20">
        <h5 className='text-xl font-semibold py-4 text-start px-6'>Information générale</h5>
        {jobDetail.map((item)=>{
          return(
          <div className="border-b flex gap-4 items-center py-4 mx-6">
            <img src={item.icon} alt="..." />
            <div className="flex justify-between flex-col text-start">
              <div className='text-sm text-primary-text'>{item.title}</div>
              <div className='font-semibold'>{item.label}</div>
            </div>
          </div>  
          )
          })}  
          {/* <ButtonHoverAnimated className="px-6 py-2 ">Commencer</ButtonHoverAnimated> */}
          <div className="w-full px-4">
         <button onClick={()=>setshowModal(true)} className=' w-full rounded-md py-4 bg-primary-black text-white font-medium '>Postuler Maintenant</button>
         
          </div>
            </div>
      </div>

      <div className='w-full py-20 p-responsive bg-primary-beige'>
        <div className="flex justify-between items-center mb-10">
            <h4 className='text-4xl font-bold'>Offres similaires</h4>
            <button className="flex border-black rounded-sm items-center gap-2 px-4 py-2 border-2 border-primary-black  font-bold text-primary-black">Voir tout
            <svg className='w-6 rotate-180' viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#3C3D42" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#3C3D42" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>
            </button>
        </div>
        <div className='grid xl:grid-cols-3  min-[1920px]:grid-cols-3 md:grid-cols-1 sm:grid-col-span-1 gap-6'>
      {jobs.map((item)=>{
        return( 
          <JobCard key={item.id} item={item} />
          )
         
      })}</div>
    </div>

    <Modal  isOpen={showModal} onClose={()=>setshowModal(false)}>
        <div className="flex items-center justify-between py-2 px-4">
            <p>Postuler pour une offre</p>
            <button  onClick={()=>setshowModal(false)} className='px-2 py-2 rounded-md border text-primary-black'>
            <CgClose></CgClose>
            </button>
        </div>

        <hr className='mb-6'/>

        {/* <div className="flex gap-4">
          <div className="bg-neutral-100 rounded-full w-10 h-10">

          </div>
          <div className="flex flex-col justify-between items-start">
            <p>Assistant Management</p>
            <p>Fafafangana 309</p>
          </div>
        </div> */}
       
        <div className="px-6 space-y-4 mb-4">

          <div className="flex gap-4">
            <div className="bg-neutral-100 rounded-full w-10 h-10">
            </div>

            <div className="flex flex-col justify-between items-start">
              <p>Assistant Management</p>
              <p>Fafafangana 309</p>
            </div>

          </div>

          <Input label="Lien linkedin"/>
            <Input label="Portfolio"/>
            {/* <Select label="Type d'abonnement" options={["Standard", "Professionnel", "Prémium"]}/> */}
            <TextAreaEditor label='Message'/>
            <FilePicker />
        </div>
        <div className="flex items-center justify-end p-6">
            <button className="py-2 px-6 rounded-sm shadow bg-primary-black text-white">
                Enregistrer
            </button>
        </div>
      </Modal>  

    </>
  )
}

export default JobDetail