import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const DropdownStatus = ({appointementStatus, key}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [status, setStatus] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(true)
  const dropdown = useRef(null)
  const trigger = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
          if (!dropdown.current) return;
          if (
            !showModal ||
            dropdown.current.contains(target) ||
            trigger.current.contains(target)
          )
            return;
            setShowModal(false);
        }
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
      })

  const menuItems = [
    { label: 'Nouveau', value: 'option1' },
    { label: 'Sélectionner', value: 'option1' },
    { label: 'Entretien', value: 'option3' },
    { label: 'Récruter', value: 'option4' },
    { label: 'Réfuser', value: 'option4' },
  ]

  useLayoutEffect(() => {
    setStatus(appointementStatus)
  
  }, [appointementStatus])

  useEffect(() => {
    return () => {
      handleCloseModal();
      handleDeleteCloseModal();
    };
  }, []);
  
  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };
  
  // const handleDeleteCloseModal = () => {
  //   setShowDeleteModal(false);
  // };
  

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleDeleteCloseModal = () => {
    setShowDeleteModal(false);
  };
  const handleDeleteOpenModal = () => {
    setShowDeleteModal(true);
  };
  

  return (
    <div className="relative inline-block text-left text-sm" key={key}>
      <div className="flex items-center">
        {status !== "Confirmé" ?
        <button
          onClick={() => setShowModal(true)}

          className="px-4 py-2 text-primary-text bg-neutral-50 border rounded-l-md focus:outline-none text-sm"
        >
           Sélectionner
        </button>
        :
        <button
          onClick={() => setShowModal(true)}
          className="px-4 py-2  bg-[#F6F8FA]  border text-[#24292F] rounded-l-md hover:bg-neutral-200 focus:outline-none text-sm"

        >
          Réfuser
        </button>
        }
        
        <button
        //   onClick={toggleDropdown}
          ref={trigger}
          onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
          className={ (status !== "Confirmé") ? " text-primary-text bg-neutral-50 border px-3 py-[10px] text-white rounded-r-md focus:outline-none flex items-center border-l ": " bg-[#F6F8FA] px-3 py-[10px] text-gray-500 rounded-r-md focus:outline-none flex items-center border border-l-transparent border-l-gray-300 "}

        >
          <FaChevronDown className={`text-primary-text transition-transform ${isOpen ? 'rotate-180' : ''}`} />
        </button>
      </div>

      {isOpen && (
        <div
        ref={dropdown}
        className="absolute z-30 right-0 mt-2 w-48 bg-white border rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex flex-col">
            {menuItems.map((item, index) => (
              <button
                key={item.value}
                onClick={() => {
                  console.log(item.value)
                  switch (index) {
                    case 0:
                        alert("alerte 1")
                        break
                  
                    case 1:
                      setShowDeleteModal(true)
                        break
                    case 2:
                        // alert("alerte 3")
                        handleDeleteOpenModal()
                        console.log(showDeleteModal+" "+key)
                        break
                  }
                  setIsOpen(false)
                }}
                className={"w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 focus:outline-none  text-sm"+((item.label === "Annuler")?" text-red-600":" text-gray-900")}
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
      )}

      </div>
  )
}

export default DropdownStatus