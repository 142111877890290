import React from 'react';

const ToggleSwitch = ({ enabled, onChange }) => {
  return (
    <div
      onClick={() => onChange(!enabled)}
      className={`w-10 h-5 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${enabled ? 'bg-orange-500' : ''}`}
    >
      <div
        className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${enabled ? 'translate-x-5' : ''}`}
      ></div>
    </div>
  );
};

export default ToggleSwitch
