import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 

const FormationCardSkeleton = () => (
    <div className='bg-white rounded-lg overflow-hidden border p-4 transition-all ease-in hover:scale-[1.01] hover:shadow-md cursor-pointer'>
      <div className='flex flex-row gap-4 text-start'>
        <div className='border bg-slate-50 rounded-sm flex items-center justify-center w-[400px] h-56'>
          <Skeleton width={400} height={224} />
        </div>
  
        <div className='flex w-full flex-col justify-between gap-y-2'>
          <p className='text-level-1 flex-col justify-between col'>
            <div className='font-semibold text-lg'>
              <Skeleton width={200} />
            </div>
            <span>
              <Skeleton width={150} />
            </span>
          </p>
  
          <div className="flex gap-x-2 text-primary-text">
            <Skeleton width={80} height={40} />
            <Skeleton width={80} height={40} />
          </div>
  
          <p className='mb-2'>
            <Skeleton count={3} />
          </p>
  
          <div className="flex justify-between items-center">
        
              <Skeleton width={100} />
            
            <Skeleton width={120} height={40} />
          </div>
        </div>
      </div>
    </div>
  );
  export default FormationCardSkeleton
