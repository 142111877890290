import './App.css';
// import ScrollToTop from './app/components/ScrollToTop';
import  Routers  from './Routers';

function App() {
  return (
    <div className="App">
      {/* <ScrollToTop></ScrollToTop> */}
      <Routers/>
    </div>
  );
}

export default App;
