import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Heading from '../../../components/Heading'
import ButtonBlack from '../../../components/Buttons/ButtonBlack'


const ChooseAccountType = ({setPage,page=1 , data, setData,role, setRole}) => {
  const accountTypes = [
    {
      logo: "logo",
      title: "Je suis un candidat",
      type:"Candidat",
      description: "Vous êtes à la recherche d'un emploi, de stage ou formations.",
    },
    {
      logo: "logo",
      title: "Je suis un recruteur",
      type:"Recruteur",
      description: "Vous représenter une organisation, société, organisation.",
    },
    {
      logo: "logo",
      title: "Je suis un formateur",
      type:"Formateur",
      description: "Vous représentez une école, centre de formation.",
    },
  ]

  // State to track selected option, default is the first one
  const [selectedType, setSelectedType] = useState(0);

  // Handler to set selected account type
  const handleSelect = (index) => {
    setSelectedType(index);
  }

  return (
    <>
    <div className="mt-20  w-full">
      <Heading
        title="Choisissez votre profil !"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit."
        className="mb-6"
      />
      <div className="w-full space-y-4 ">
        {accountTypes.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              handleSelect(index)
              setRole(item.type)
            }} // Set selected option on click
            className={`w-full p-4 space-y-1 sm:p-6 bg-white rounded-lg shadow flex-col flex justify-start text-start cursor-pointer
              ${
                // selectedType === index
                role === item.type
                  ? "ring-4 ring-primary-green/20 border border-primary-green"
                  : "hover:drop-shadow-lg"
              }`}
          >
            <div className="bg-gray-50 border rounded-md flex justify-center items-center  w-14 h-14">
              {/* Placeholder for logo */}
              <svg width="35" height="34" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 21.5C0 17.6013 0.938028 14.0037 2.814 10.707C4.69001 7.41032 7.23803 4.80168 10.458 2.881C13.678 0.960319 17.192 0 21 0C24.808 0 28.322 0.960319 31.542 2.881C34.762 4.80168 37.31 7.41032 39.186 10.707C41.062 14.0037 42 17.6013 42 21.5C42 25.3987 41.062 28.9963 39.186 32.293C37.31 35.5897 34.762 38.1983 31.542 40.119C28.322 42.0397 24.808 43 21 43C17.192 43 13.678 42.0397 10.458 40.119C7.23803 38.1983 4.69001 35.5897 2.814 32.293C0.938028 28.9963 0 25.3987 0 21.5ZM3.486 21.5C3.486 25.972 4.956 29.9137 7.896 33.325C9.24 30.6303 11.368 28.0647 14.28 25.628C17.192 23.1913 20.034 21.6577 22.806 21.027C22.386 20.0237 21.98 19.1207 21.588 18.318C16.772 19.8947 11.564 20.683 5.964 20.683C4.872 20.683 4.06001 20.6687 3.528 20.64C3.528 20.7547 3.52103 20.898 3.507 21.07C3.49301 21.242 3.486 21.3853 3.486 21.5ZM4.032 17.071C4.64801 17.1283 5.55803 17.157 6.762 17.157C11.438 17.157 15.876 16.512 20.076 15.222C17.948 11.352 15.61 8.127 13.062 5.547C10.85 6.69368 8.95301 8.28468 7.371 10.32C5.78903 12.3553 4.67603 14.6057 4.032 17.071ZM10.29 35.647C13.454 38.1697 17.024 39.431 21 39.431C23.072 39.431 25.13 39.0297 27.174 38.227C26.614 33.325 25.522 28.5807 23.898 23.994C21.322 24.5673 18.725 26.015 16.107 28.337C13.489 30.659 11.55 33.0957 10.29 35.647ZM16.716 4.171C19.18 6.77968 21.462 10.0333 23.562 13.932C27.37 12.298 30.24 10.2197 32.172 7.697C28.924 4.945 25.2 3.569 21 3.569C19.572 3.569 18.144 3.76968 16.716 4.171ZM25.074 16.985C25.494 17.9023 25.97 19.0633 26.502 20.468C28.574 20.2673 30.828 20.167 33.264 20.167C35 20.167 36.722 20.21 38.43 20.296C38.206 16.3973 36.834 12.9287 34.314 9.89C32.494 12.6707 29.414 15.0357 25.074 16.985ZM27.552 23.478C28.98 27.7207 29.946 32.078 30.45 36.55C32.662 35.088 34.468 33.2103 35.868 30.917C37.268 28.6237 38.108 26.144 38.388 23.478C36.344 23.3347 34.482 23.263 32.802 23.263C31.262 23.263 29.512 23.3347 27.552 23.478Z" fill="#161616"/>
</svg>
            </div>
            <h4 className='font-semibold'>{item.title}</h4>
            <p>{item.description}</p>
            <input
              type="radio"
              name="accountType"
              value={item.title}
              checked={
                // selectedType === index
                role === item.type
              }
              onChange={() => {
                handleSelect(index)
                setRole(item.type)
              }}
              className="hidden" // Hide the actual radio button
            />
          </div>
        ))}

                <ButtonBlack onClick={()=>setPage(page+1)} DefaultClassName="w-64 py-3 rounded-md">Commencer</ButtonBlack>
                <div className="text-sm font-medium ">
                Déjà un compte? <Link to="login" className="text-gray-950 cursor-pointer text-primary-700 hover:underline dark:text-primary-500 font-semibold">Se connecter</Link>
            </div>
          
        </div>
    </div> 
    </>
  )
}

export default ChooseAccountType