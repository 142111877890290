
// import designAndGraphics from "../assets/CategoryIcon/graphic-and-design.svg"
// import programming from "../assets/CategoryIcon/programming.svg"
// import digitalMarketing from "../assets/CategoryIcon/digital-marketing.svg"
// import health from "../assets/CategoryIcon/health-care.svg"

// export const JobCategoryData = [
//     {
//         logo: designAndGraphics,
//         name:"Design et Graphisme",
//         offerNumber: 124
//     },
//     {
//         logo: programming,
//         name:"Programmation",
//         offerNumber: 124
//     },
//     {
//         logo: digitalMarketing,
//         name:"Marketing",
//         offerNumber: 124
//     },
//     {
//         logo: health,
//         name:"Santé",
//         offerNumber: 124
//     },
//     {
//         logo: digitalMarketing,
//         name:"Marketing",
//         offerNumber: 124
//     },
//     {
//         logo: digitalMarketing,
//         name:"Marketing",
//         offerNumber: 124
//     },
//     {
//         logo: digitalMarketing,
//         name:"Marketing",
//         offerNumber: 124
//     },
//     {
//         logo: digitalMarketing,
//         name:"Marketing",
//         offerNumber: 124
//     }
// ]

import designAndGraphics from "../assets/CategoryIcon/graphic-and-design.svg"
import programming from "../assets/CategoryIcon/programming.svg"
import digitalMarketing from "../assets/CategoryIcon/digital-marketing.svg"
import health from "../assets/CategoryIcon/health-care.svg"
import finance from "../assets/CategoryIcon/health-care.svg"
import education from "../assets/CategoryIcon/health-care.svg"
import engineering from "../assets/CategoryIcon/health-care.svg"
import customerService from "../assets/CategoryIcon/health-care.svg"
// import finance from "../assets/CategoryIcon/finance.svg"
// import education from "../assets/CategoryIcon/education.svg"
// import engineering from "../assets/CategoryIcon/engineering.svg"
// import customerService from "../assets/CategoryIcon/customer-service.svg"

export const JobCategoryData = [
    {
        logo: designAndGraphics,
        name: "Design et Graphisme",
        offerNumber: 124
    },
    {
        logo: programming,
        name: "Programmation",
        offerNumber: 95
    },
    {
        logo: digitalMarketing,
        name: "Marketing Digital",
        offerNumber: 85
    },
    {
        logo: health,
        name: "Santé",
        offerNumber: 72
    },
    {
        logo: finance,
        name: "Finance",
        offerNumber: 68
    },
    {
        logo: education,
        name: "Éducation",
        offerNumber: 103
    },
    {
        logo: engineering,
        name: "Ingénierie",
        offerNumber: 56
    },
    {
        logo: customerService,
        name: "Service Client",
        offerNumber: 44
    }
]
