import React from 'react'
import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated'
import image7 from '../../../assets/Illustrations/onlinetrainging_Image Enhancer.jpg'


const Trainings = () => {
  return (
    <div className='w-full py-10 lg:py-20 px-6 lg:px-20 xl:px-40 bg-[rgb(245,255,210)] grid grid-cols-1 lg:grid-cols-2  gap-10' >
  <div><img src={image7} alt="" className='h-[300px] lg:h-[400px] w-full object-cover rounded-md' /></div>
    <div className=" col-span-1 space-y-6  flex flex-col justify-between items-start">
      <p className='text-lg font-semibold bg-primary-green px-1 text-start'>Se former</p>
      <div className="space-y-6">
        <h2 className='text-2xl lg:text-4xl  font-extrabold text-start leading-normal'>Trouver Les Meilleures Formation Ici</h2>
        <p className=' text-start text-base lg:text-lg'>Nous vous accompagnons dans vos études et formation. Découvrez des professeurs et étudiants.</p>
        <div className='justify-start flex'>
        <ButtonHoverAnimated path='/formation' className=" text-base px-6 py-2 rounded-sm border-2 border-primary-black ">Commencer</ButtonHoverAnimated>
      </div>
        
        </div>

    </div>
</div>
  
  
  )
}

export default Trainings