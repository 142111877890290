
import React from "react"
import "../index.css"

const TrainingCategories =()=>{
    return(
        <div className=" gap-4 px-40 py-10  flex flex-wrap">
        <div className="w-1/2">
            <h2 className="text-2xl font-semibold leading-normal">Helllo from the outside</h2>
        </div>

        <div className="rounded-sm w-1/2">
        <div class="wrapper">
            <div class="itemLeft item1"></div>
            <div class="itemLeft item2"></div>
            <div class="itemLeft item3"></div>
            <div class="itemLeft item4"></div>
            <div class="itemLeft item5"></div>
            <div class="itemLeft item6"></div>
            <div class="itemLeft item7"></div>
            <div class="itemLeft item8"></div>
        </div>
        <div class="wrapper">
            <div class="itemRight item1"></div>
            <div class="itemRight item2"></div>
            <div class="itemRight item3"></div>
            <div class="itemRight item4"></div>
            <div class="itemRight item5"></div>
            <div class="itemRight item6"></div>
            <div class="itemRight item7"></div>
            <div class="itemRight item8"></div>
        </div>
        </div>
        </div>
    )
}

export default TrainingCategories