import React from 'react'
import img from "../../../assets/HomeImages/icons8-team-FcLyt7lW5wg-unsplash-removebg.png"
import img1 from "../../../assets/users/neil-sims.png"
import img2 from "../../../assets/users/leslie-livingston.png"
import { motion } from 'framer-motion'

const Hero = () => {
  return (
    <div>
              <div className="z-0 rounded-lg bg-gray-50 h-[500px] xl:h-[550px]  bg-gradient-to-r from-[#D1FF68] to-[#A3FF78] mx-4 lg:mx-10 xl:mx-20  mt-10 overflow-hidden">
<div className=" flex md:flex-row flex-col  px-12 ">
<div className=" w-full md:w-1/2 space-y-6 justify-start flex flex-col py-20">
    <h2 className='text-4xl lg:text-5xl xl:text-[72px] font-extrabold text-start leading-normal'>Trouver Votre Métier De Rêve</h2>
    <p className=' text-start text-xl'>Découvrez des opportunités qui correspondent à vos compétences et réalisez vos ambitions professionnelles.</p>
    <div className='justify-start flex'>
    <button className='p-4 bg-black rounded-md font-semibold text-white'>Commencer</button>  
    </div>
    
  </div>
<div className='w-full md:w-1/2 hidden md:flex items-end justify-center md:justify-between flex-col'>
<div className="flex justify-between">
  <motion.div 
  // initial={{ right: -40, opacity:0 }}
  animate={{ right: -20, opacity:1 }}
  whileInView={{right: 20}}  
  transition={{ type: "spring", stiffness: 300, damping: 10  }}
  className="absolute invisible lg:visible bg-white w-10 h-10 rounded-full shadow mt-10 mr-28 place-center">
    <div className=" w-7 h-7 bg-emerald-700 rounded-full"></div>
  </motion.div>

  <motion.div
    // initial={{ right: 550, opacity:0 }}
    animate={{ right: 550, opacity:1 }}
    whileInView={{right: 400}}
    transition={{ ease:500, bounce:1, type: "spring", stiffness: 500, damping: 25  }}
    className="absolute invisible lg:visible bg-[#a8ff37] w-20 h-20 rounded-full shadow mt-44  place-center">
    <div className="w-12 h-12 bg-[#ffffff] rounded-full hidden lg:flex"></div>
  </motion.div>

</div>
 <div>
    <img src={img} alt="image1" className=' max-h-[450px] md:max-h-[450px] xl:max-h-[550px] mt-12 xl:mt-0' />
    <div className="bg-white px-2.5 md:px-6 py-2 border shadow rounded-md absolute mr-6  -mt-40"> 
      <div className="flex">
          <div className="flex justify-end mr-2">
              <img className="border-2 border-white  rounded-full h-7 md:h-10 w-7 md:w-10 -mr-2"
                  src={img1} alt=""/>
              <img className="border-2 border-white  rounded-full h-7 md:h-10 w-7 md:w-10 -mr-2"
                  src={img2} alt=""/>
              <img className="border-2 border-white  rounded-full h-7 md:h-10 w-7 md:w-10 -mr-2"
                  src={img1} alt=""/>
              <img className="border-2 border-white  rounded-full h-7 md:h-10 w-7 md:w-10 -mr-2"
                  src="https://randomuser.me/api/portraits/women/32.jpg" alt=""/>
              <img className="border-2 border-white  rounded-full h-7 md:h-10 w-7 md:w-10 -mr-2"
                  src="https://randomuser.me/api/portraits/men/44.jpg" alt=""/>
              <img className="border-2 border-white  rounded-full h-7 md:h-10 w-7 md:w-10 -mr-2"
                  src="https://randomuser.me/api/portraits/women/42.jpg" alt=""/>
              <span
                  className="text-xs md:text-sm flex items-center justify-center bg-white dark:bg-gray-800  text-gray-800 dark:text-white font-semibold border-2 border-gray-200  rounded-full h-7 md:h-10 w-7 md:w-10">
                  +999
              </span>
          </div>
      </div>
    </div>
    
  </div>   
   
   
  </div>


</div>
      </div>
      <div className=" relative bg-white shadow-md rounded-xl -mt-16 border sm:mx-10 lg:mx-60 mb-24 py-2 lg:py-5" >
        <div className="grid  grid-cols-3 ">
          <div className="flex-col text-start items-center border-r px-4 lg:px-12 py-4  md:py-6">
            <p>Candidat</p>
            <h5 className=' text-xl md:text-3xl font-bold'>+100K</h5>
          </div>
          <div className="flex-col text-start items-center px-4 lg:px-12 py-4  md:py-6">
            <p className=''>Offres</p>
            <h5 className='text-xl md:text-3xl font-bold'>+100K</h5>
          </div>
          <div className="flex-col text-start items-center border-l px-4 lg:px-12 py-4  md:py-6">
            <p className=''>Des entreprises</p>
            <h5 className='text-xl md:text-3xl font-bold'>+100K</h5>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero