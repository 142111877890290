import React from 'react'
// import img404 from "../../assets/images/404.png"
import ButtonBlack from '../../../components/Buttons/ButtonBlack'
// import ButtonHoverAnimated from '../../../components/Buttons/ButtonHoverAnimated'
import Input from '../../../components/Form/Input'
import TextAreaEditor from '../../../components/Form/TextAreaEditor'


const ContactUs = () => {
  
  return (
    <div>
        <div className=" mx-auto my-14 lg:my-20 grid grid-cols-1 md:grid-cols-2  gap-6 lg:px-20 px-6 xl:px-40 flex-col-reverse   ">
            <div className=' place-center '>
              <div className='space-y-10  text-start'>
                <p className='text-lg font-bold'>Nous Contacter</p>
                <h1 className='font-bold text-2xl lg:text-4xl'>Nous sommes à <span className="ml-1 px-1 bg-primary-green"> Votre Ecoute</span></h1>
                <p className='text-primary-text text-base lg:text-lg'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum quod beatae, dicta recusandae quasi aperiam cupiditate ipsam, labore aliquam, laboriosam nemo assumenda harum vel. Quo iusto iste repudiandae sit eos.</p>
                <div className="w-full md:block hidden">
                  <div className="bg-white border  rounded-md shadow p-2 w-full max-w-md flex justify-between">
                    <input
                      type="email"
                      className="outline-none p-2 w-full"
                      placeholder="Entrez votre email..."
                    />
                    <button className="bg-primary-black text-white px-4 py-2 rounded-sm">
                      S'abonner
                    </button>
                  </div>
                </div>      
                </div>
                </div>
                <div className=" flex items-center justify-center">
                  <div className="bg-white border p-6 rounded-md drop-shadow-md w-full h-fit">
                    <Input label="Email"/>
                    <Input label="Objet"/>
                    <TextAreaEditor label='Message'/>
                    <div className="flex my-6 justify-end">
                    <ButtonBlack>Envoyer</ButtonBlack>
                    </div>
                  </div>
                </div>
        </div>
    </div>
  )
}

export default ContactUs