
import trainer1 from "../assets/users/pexels-anthonyshkraba-production-8973530.jpg"
// import programming from "../assets/CategoryIcon/programming.svg"
// import trainer1 from "../assets/CategoryIcon/digital-marketing.svg"
// import health from "../assets/CategoryIcon/health-care.svg"

export const JobCategoryData = [
    {
        id: 1,
        image: trainer1,
        name:"Jef Chart",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Programmation",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Marketing",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Santé",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Marketing",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Marketing",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Marketing",
        current_job: "Coach"
    },
    {
        id: 1,
        image: trainer1,
        name:"Marketing",
        current_job: "Coach"
    }
]
