import React from 'react'
import { Link } from 'react-router-dom'
import Input from '../../../../components/Form/Input'
import Select from '../../../../components/Form/Select'

const CreateRecruiterAccount = ({setPage,page , data, setData}) => {
  return (
    <>
        <div className="w-full  p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow mt-20">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                Créer un compte
            </h2>
            <form className="mt-8 space-y-2" action="#">

            <Input label="Nom de l'organisation" name="nom"/>

            <Select label="Type de l'organisation" name="nom" placeholder='Veuillez sélectionner' options={["Une entreprise", "Un cabinet de recrutement", "Un agence d'intérim","Une ESN"]}/>

            <Select label="Taille" name="taille" placeholder='Veuillez sélectionner' options={["1 - 50", "51 - 100", "101 - 250","+250"]}/>


          <div className="grid grid-cols-2 gap-x-4">
            <Select mb='mb-0' label="Région" name="region" />
            <Select  mb='mb-0' label="District" name="district" />
          </div>

            <Input label="Email" type='email' name="email"/>

            <div className="grid grid-cols-2 gap-x-4">
                <Input label="Mot de passe" type='password' name="password"/>
                <Input label="Mot de passe de confirmation" type='password' name="password"/>
            </div>
            <p className='text-start font-medium text-gray-900  text-sm'>Veuiller utiliser un mot de passe avec minimum 8 caractères</p>
            <div className="flex items-start py-2">
                <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" name="remember" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" required/>
                </div>
                <div className="ml-3 text-sm">
                <label for="remember" className="font-medium text-gray-900 ">J'accepte les <Link to="termes-et-conditions" className="text-gray-950 cursor-pointer text-primary-700 hover:underline dark:text-primary-500"> termes et conditions</Link>  d'utilisation</label>
                </div>
            </div>
            <button onClick={()=>setPage(3)} className="w-full px-5 py-3 text-base font-medium text-center bg-gray-400 text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">S'inscrire maintenant</button>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Déjà un compte? <Link to="login" className="text-gray-950 cursor-pointer text-primary-700 hover:underline dark:text-primary-500">Se connecter</Link>
            </div>
        </form>
    </div>
    </>
  )
}

export default CreateRecruiterAccount