import React, {useState, useEffect} from 'react'
import datas from '../../datas/Datas.json'
import JobCard from '../../../components/Cards/JobCard'
import ButtonHoverAnimated from '../../../../components/Buttons/ButtonHoverAnimated'
import back1 from "../../../assets/Illustrations/790f3793-3e73-4e81-b8b0-e91ef4deb252.jpg"

const Offers = ({jobs}) => {
    // const [jobs, setJobs] = useState(datas)

    // useEffect(() => {
    //   setJobs(datas)  
    // }, [])
    
  return (
    <div className='w-full py-10 lg:py-20 px-6 lg:px-20 xl:px-40 bg-primary-black' style={{backgroundImage:"url("+back1+")"}}>
        <div className="flex justify-between items-center mb-10">
            <h4 className='text-2xl lg:text-4xl font-bold text-start text-white shadow-lg '>Les Meilleures Offres</h4>
            {/* <button className="flex border-black items-center rounded-full gap-2 px-4 py-2 border-2 border-primary-black  font-bold text-primary-black">Voir tout
            <svg className='w-6 rotate-180' viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#3C3D42" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#3C3D42" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>
            </button> */}
            <ButtonHoverAnimated className='flex px-3 md:px-4 py-1.5 md:py-2 border-2 border-primary-black font-semibold text-primary-black rounded-full text-sm md:text-base'>
              Voir tout
              {/* <svg className='w-6 rotate-180' viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#3C3D42" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#3C3D42" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg> */}
            </ButtonHoverAnimated>
        </div>
        <div className='grid xl:grid-cols-2   md:grid-cols-1 sm:grid-col-span-1 gap-6'>
      {jobs?.map((item)=>{
        return( 
          <JobCard key={item.id} item={item} />
          )
         
      })}</div>
    </div>
  )
}

export default Offers

// <Link to={"offer/"+item.id} className='bg-white rounded-lg overflow-hidden border py-6 px-6 transition-all ease-in hover:border-gray-400 cursor-pointer'>
// <div className="flex justify-between">
//     <div className=' flex flex-row gap-3 mb-3'>
//         <div className='border bg-slate-50 rounded-sm flex items-center justify-center w-14 h-14'>
//             <img src={bookmaker} alt="logo"  className='w-5 h-5'/>
//         </div>
//         <div>
//             <p className='text-level-1 flex-col justify-between col'>
//                 <div>{item.job_title}</div>
//             <span>{item.organisation.name}</span>
//                 </p>
//         </div>
//     </div>   
//     <img src={bookmaker} alt="logo"  className='w-8 h-8'/>
// </div>
// <div className="flex gap-x-2">
//     <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">En remote</div>
//     <div className="font-medium p-2 rounded-md bg-gray-50 border-slate-100">CDI</div>
// </div>
// <div className='text-level-2 '>
//     <ul className='flex gap-3 mb-2'>
//         <li>{item.dateStart} - {item.dateFinish} </li>
//         <li>{item.address}</li>
//     </ul>
//     <p className='mb-2 text-start'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quos velit officiis voluptatem consequuntur enim doloribus corporis</p>
// <div className='flex justify-between items-center'>
// <p className=' gap-2 flex text-sm text-primary-black-300'><span>il y a 2j</span> - <span>19 candidats</span></p>
// <button className='bg-[#4dff4d] border-none rounded-sm text-[#145926] font-semibold text-sm px-4 py-2 flex gap-2 items-center'>Voir l'offre <img src={next} alt="" className='w-4 rotate-180' /></button>
// {/* 4dff4d */}
// </div>
// </div>
// </Link>