import React, { useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion'
import { HiXMark, HiCheck } from "react-icons/hi2"
import { IoCloseCircle } from "react-icons/io5";


//success notification by default
const Notification = ({ variant= "success", setIsOpen, text, styleContent, styleOverlay, isOpen, initialOverlay, animateOverlay, exitOverlay, initialContent, animateContent, exitContent }) => {
    
    const baseColor = (variant === "success") ? "green": "red"
     const shadowColor = (variant === "success") ? "shadow-green-500/10": "shadow-red-500/5"
    // shadow-'+baseColor+'-500/70
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsOpen(false)
        }, 4000);
        return () => clearTimeout(timeout)
    }, [isOpen])

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && (
                        <motion.div
                            initial={(initialOverlay === undefined) ? { opacity: 0 } : initialOverlay}
                            animate={(animateOverlay === undefined) ? { opacity: 1 } : animateOverlay}
                            exit={(exitOverlay === undefined) ? { opacity: 0 } : exitOverlay}
                            className={`${styleOverlay === undefined ? 'w-screen flex justify-center items-center fixed z-50 top-0 left-0 p-4' : styleOverlay} `}
                        >
                            <motion.div
                                initial={(initialContent === undefined) ? { opacity: 0, translateY: -50 } : initialContent}
                                animate={(animateContent === undefined) ? { opacity: 1, translateY: 0 } : animateContent}
                                exit={(exitContent === undefined) ? { opacity: 0, translateY: -50 } : exitContent}

                                className={`${styleContent === undefined ? 'bg-white border-l-4 border-t-[0.5px] border-t-neutral-100 py-3 flex justify-between border-'+baseColor+'-500 rounded-sm px-4 max-md:w-[90vw]  md:w-[50vw] shadow-md '+shadowColor+'  ' : styleContent} `}

                            >

                                <div className=" flex justify-start items-center gap-3">
                                    
                                    {variant === "success" ?
                                   <div className="p-1 border border-green-500 rounded-full">
                                   <HiCheck  className="text-lg text-green-500" />
                               </div> 
                                :
                                  <div className="p-1 border border-red-500 rounded-full">
                                        <HiXMark className="text-red-600 text-lg"/>
                                    </div> 
                                }
                                    
                                   
                                    
                                    <p>
                                        {
                                            text
                                        }
                                    </p>
                                </div>
                                <div className=" flex justify-end items-center">
                                    <button className="hover:bg-neutral-100  rounded-full p-1">
                                     <HiXMark onClick={()=>setIsOpen(false)} />    
                                    </button>
                                   
                                </div>
                            </motion.div>

                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    )
}
export default Notification