import React from 'react'
import img from '../../../assets/users/helene-engels.png'
import bookmaker from '../../../assets/icons/bookmaker-svgrepo-com.svg'
import { BiPlus } from 'react-icons/bi'
import { CgClose } from 'react-icons/cg'
import Expérience from './partials/Expérience'
import PersonnalInfo from './partials/PersonnalInfo'
import Skills from './partials/Skills'
import Education from './partials/Education'
import Language from './partials/Language'
import Bio from './partials/Bio'
import { GoDownload } from 'react-icons/go'

const Resume = () => {
    const jobDetail = [
        {
          icon:img,
          title: "Email",
          label: "johndoe@gmail.com",
        },
        {
          icon:img,
          title: "Phone",
          label: "+104 55 789 14",
        },
        {
          icon:img,
          title: "Languesz",
          label: "Anglais, Francais, Espagnol",
        },
        {
          icon:img,
          title: "Lieu",
          label: "12 Lot ABN Fianarantsoa",
        },
        {
          icon:img,
          title: "Niveau visé",
          label: "Certification",
        }
      ]


  return (
    <div className=' min-h-full w-full pr-6 lg:pr-6 xl:pr-40 pl-6 text-primary-black py-6 ' >
     <div className="flex justify-between items-center mb-6">
        <h1 className='font-semibold'>Mon CV</h1>
        {/* <button>Menu</button> */}
     </div>
        <div className="grid grid-cols-1  gap-4 text-sm">
            <div className="col-span-4 ">
            <div className="flex justify-between items-center mb-4 border p-4 rounded-md">
                <div className="flex gap-4">
                <img src={img} alt="image" className='w-14 h-14 rounded-full'/>
                    <div className="flex-col flex justify-between text-start">
                        <h4 className='text-xl font-semibold'>Preskovya Dubina</h4>
                        <p className='text-primary-text'>UI UX Designer</p>
                    </div>
                </div>
                <div className="flex gap-x-2">
                <button className="px-6 py-2 border rounded-md flex gap-1 items-center text-black"><GoDownload></GoDownload> Mon CV</button>
                </div>
            </div>
            {/* <div className="flex sticky top-0 border-b w-full my-4 text-sm bg-white pt-2">
                <button className="border-b-gray-950 px-6 py-2 border-b-2 font-bold hover text-primary-black">Bio</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">CV</button>
                <button className="px-6 py-2 text-primary-text hover:text-primary-black">Expérience</button>
                <button className="px-6 py-2  text-primary-text hover:text-primary-black">Education</button> 
            </div> */}
            <div className="space-y-6">

                {/* Bio */}
                <Bio/>

                {/* Information personnel */}
                <PersonnalInfo/>

                {/* Compétence */}
                <Skills/>

                {/* Qualité */}
                <div className="flex-col text-start border p-4 rounded-md">
                <div className="flex justify-between items-center  mb-4">
                        <div className=' font-semibold'>Qualité</div>
                        
                        <button className="bg-neutral-50 border rounded-sm p-1">
                            <BiPlus className='w-5 h-5 text-neutral-500'></BiPlus>

                        </button>
                    </div>
                    <div className=" flex gap-2 flex-wrap">
                        <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
                        <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
                        <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
                        <div className="py-2 pl-4 pr-3.5 text-primary-text bg-gray-50 flex gap-1 items-center border rounded-sm">Java <button className='hover:bg-neutral-400 hover:text-white rounded-full p-0.5'><CgClose/></button> </div>
                    </div>
                </div>

                {/* Expérience */}
                <Expérience/>

                {/* Education */}
                <Education></Education>

                {/* Langues */}
                <Language/>
            </div>
            </div>

                

            </div>
        </div>
  )
}

export default Resume